<template>
    <v-menu rounded>
        <template v-slot:activator="{on}">
            <v-btn icon v-on="on"><v-avatar size="48" color="black"><span class="white--text">{{ user.initials }}</span></v-avatar></v-btn>
        </template>
        <v-card>
            <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                    <v-avatar color="black"><span class="white--text">{{ user.initials }}</span></v-avatar>
                    <h3 class="mt-2 mb-2">{{ user.displayName }}</h3>
                </div>
                <v-divider class="mt-2"></v-divider>
                <v-btn text depressed @click="logout">Odhlásit</v-btn>
            </v-list-item-content>
        </v-card>
    </v-menu>
</template>

<script>
import { authStore } from '../stores/user'
import { storeToRefs } from "pinia";

export default {
    name: 'LoggedUserComponent',
    props: {
    },
    data: () => ({
        user: Object,
        authStore: authStore(),
    }),
    mounted: function() {
        const currentUser = authStore()
//        currentUser.verify()
        this.user = currentUser;
    },
    methods: {
        logout: function() {
            this.authStore.logout();
            
            this.$nextTick( () => {
                this.$router.push({name: "login"})
            })
        }
    }
}
</script>
