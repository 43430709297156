import { defineStore } from "pinia";
import WormEntryService from "../services/worm-entry-service";

export const useWormEntryStore = defineStore("wormEntryStore", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
		},
        pickedAuthor: {},
        publication: {},
    }),
    getters: {
        getItems() {
            return this.items
        },
        /*
        async getDefaults() {
            const defaults = await this.get("-/default", {})
            console.log("defaults loaded")
            return defaults;
        }
        */
    },
    actions: {
        clear() {
            this.items = [];
        },
        async getDefaults(type) {
            let service = new PublicationService();
            const results = await service.get("-/default", {type: type});
            return results.data;
        },
        async get(id, params) {
            let service = new WormEntryService();
            const results = await service.get(id, params);
            console.log(results)
            this.publication = results.data;
        },
        async fetchForDepartment(id, params) {
            let service = new WormEntryService()
            const results = await service.fetchForDepartment(id, params)
            this.items = results.data.data;
        },
        async fetchForAuthor(id, params) {
            let service = new WormEntryService()
            const results = await service.fetchForAuthor(id, params)
            this.items = results.data.data;
        },
    }
})