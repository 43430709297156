<template>
    <div>
        <PublicationTagIcon @click="showPublicationTags" v-for="tag in pubTags" :key="tag.id" :tag="tag"></PublicationTagIcon>
    </div>
</template>

<script>
import { authStore } from '@/stores/user'
import PublicationTagIcon from './PublicationTagIcon.vue';
import emitter from '../../plugins/mitt';

export default {
    name: "PublicationTagIcons",
    props: {
        tags: {},
        publicationId: [Number, String],
    },
    data: function () {
        return {
            color: "",
            icon: "",
            userStore: authStore(),
        };
    },
    computed: {
        pubTags() {
            return this.tags.filter(i => i.code == "checked").map(f => {
                f.own = (f.ownerUic == this.userStore.uic);
                return f;
            })
        },
    },
    mounted: function () {
    },
    methods: {
        tagIcon: function (item) {
            switch (item.code) {
                case "checked":
                    return "mdi-tag-check";
            }
        },
        tagColor: function (item) {
            switch (item.code) {
                case "checked":
                    return "green";
            }
        },
        showPublicationTags: function() {
            emitter.emit( "publication.tags.dialog", {publicationId: this.publicationId, dialogPersistent: false} )
        }
    },
    components: { PublicationTagIcon }
}
</script>