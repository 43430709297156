<template>
	<div>
		<v-row>
			<v-col cols="4">
				<validation-provider vid="publicationSubtypeId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.publicationSubtypeId"
					:items="list.getOptions(7)"
					:label="$t('publications.general.sub-type-result')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="internalId" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.internalId"
					:label="$t('publications.general.internal-code-of-product')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publication.resultLocalisation"
					:label="$t('publications.general.identification-number')"
					hint=""
					></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="ownerName" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.ownerName"
					:label="$t('publications.general.owner-name')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="2">
				<validation-provider vid="ownerId" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.ownerId"
					:label="$t('publications.general.owner-id')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="countryId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.countryId"
					:items="list.country"
					:label="$t('publications.general.owner-state')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="technicalParams" v-slot="{errors}" rules="required">
				<v-textarea v-model="publication.technicalParams"
					:label="$t('publications.general.technical-params')"
					hint=""
					counter
					:error-messages="errors"
					></v-textarea>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="economicalParams" v-slot="{errors}" rules="required"> 
					<v-textarea v-model="publication.economicalParams"
					:label="$t('publications.general.economical-params')"
					hint="" 
					counter
					:error-messages="errors" 
					></v-textarea>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="licenseObligationId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.licenseObligationId"
					:items="list.licenceObligation"
					:label="$t('publications.general.license-obligation')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="licenceFeeId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.licenceFeeId"
					:items="list.licenseFee"
					:label="$t('publications.general.license-fee-obligation')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="resultCategoryId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.resultCategoryId"
					:items="list.resultCategory"
					:label="$t('publications.general.result-category')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'OperationForm',
	props: {
		defaults: {type: Object, default: () => {return {q: '1'}}},
		errors: Array,
	},
	data: () => ({
		formTypes: [],
		publication: {},
		date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		list: listsStore(),
		menu: false
	}),
	mounted: async function() {
		await this.loadData();
		//this.publication = this.defaults
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				this.publication = data
			}
		},
		getData: function() {
			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;