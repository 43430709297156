<template>
    <v-row justify="center">
        <v-dialog
            :value="dialogOpen"
            :persistent="persistent"
            max-width="500px"
            keydown.esc="decline"
            >
            <v-card tile>
                <v-card-title>{{ title }}</v-card-title>
                <v-card-text>
                    {{ message }}
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="decline">{{ $t('btn.no') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="confirm">{{ $t('btn.yes') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    name: 'ConfirmDialog',
    props: {
    },
    data: () => ({
        dialogOpen: false,
        title: String,
        message: String,
        resolve: null,
        reject: null,
        persistent: true,
    }),
    mounted: function() {
        this.dialogOpen = false
    },
    methods: {
        open: function(title, message, options) {
            //this.$refs.searchInput.focus()
            this.dialogOpen = true
            this.title = title
            this.message = message

            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        }, 
        close: function( data ) {
            this.dialogOpen = false
        },
        confirm: function() {
            this.resolve(true);
            this.close();
        },
        decline: function() {
            this.reject(true);
            this.close();
        },
        clear: function() {
            this.dialogOpen = false;
        }
    }
}
</script>
