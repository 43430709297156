import { axiosInst } from "../main";

export default class GrantBudgetService {
    
    async fetchAll( id, params )  {
        let path = (id) => `/grants/${id}/budget`
        const response = await axiosInst.get(path(id), {
            params: params
        })
        return response
    }

    async get( id )  {
        let path = (id) => `/grants/${id}`
        const response = await axiosInst.get( path(id), {})
        return response
    }
}