<template>
    <v-card class="pa-4 ma-4">
        <v-card-title>
            <v-btn icon :to="{name: 'user-publications'}"><v-icon>mdi-arrow-left</v-icon></v-btn>
            {{panelTitle}}
        </v-card-title>
        <v-card-text>
            <v-list>
                <v-list-item-group>
                    <template v-for="item in publicationTypeStore.items">
                        <v-list-item :key="item.id" :to="{name: 'create-new-publication', params: {type: item.id}}">
                            <v-list-item-title v-text="item.name"></v-list-item-title>
                            <v-list-item-subtitle v-text="'Kod ' + item.code"></v-list-item-subtitle>
                        </v-list-item>
                        <v-divider></v-divider>
                    </template>
                </v-list-item-group>

            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import {axiosInst} from '../main'
import { publicationTypeStore } from '../stores/publication-type-store'

export default {
    name: 'NewPublicationTypeSelector',
	props: {
		panelTitle: {
			type: String,
			default: "Nová publikace - výběr typu"
		}
	},
    data: () => ({
        types: [],
        publicationTypeStore: publicationTypeStore()
    }),
    created: function() {
      this.load()
    },
    methods: {
        load: function() {
            this.publicationTypeStore.fetch({})
        }
    }
}
</script>