<template>
	<div>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="eventSubTypeId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.eventSubTypeId"
					:items="eventSubtype"
					:label="$t('publications.general.event-type')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="venue" v-slot="{errors}" rules="required"> 
				<v-text-field v-model="publication.venue" 
					:label="$t('publications.general.venue')" 
					:error-messages="errors" 
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="countryId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.countryId"
					:items="list.country"
					:label="$t('publications.general.country')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dateFrom" offset-y min-width="auto">
					<template v-slot:activator="{on, attrs}">
						<v-text-field
							v-model="dateFrom" :label="$t('publications.event.date-from')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
					</template>
					<v-date-picker v-model="dateFrom" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">{{ $t('btn.close') }}</v-btn>
						<v-btn text color="primary" @click="$refs.menu.save(dateFrom)">{{ $t('btn.insert') }}</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col cols="6">
				<v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" :return-value.sync="dateTo" offset-y min-width="auto">
					<template v-slot:activator="{on, attrs}">
						<v-text-field
							v-model="dateTo" :label="$t('publications.event.date-to')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
					</template>
					<v-date-picker v-model="dateTo" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menuTo = false">{{ $t('btn.close') }}</v-btn>
						<v-btn text color="primary" @click="$refs.menuTo.save(dateTo)">{{ $t('btn.insert') }}</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<validation-provider vid="noOfParticipants" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.noOfParticipants"
					:label="$t('publications.general.no-of-participants')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="noOfForeignParticipants" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.noOfForeignParticipants"
					:label="$t('publications.general.no-of-foreign-participants')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="eventTypeId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.eventTypeId"
					:items="list.eventType"
					:label="$t('publications.event.event-sub-type')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>

		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'ConferenceForm',
	props: {
		defaults: {type: Object, default: () => {return {q: '1'}}},
		errors: Array,
	},
	data: () => ({
		formTypes: [],
		publication: {},
		date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		list: listsStore(),
		eventSubtype: [],
		menu: false,
		menuTo: false
	}),
	mounted: async function() {
		await this.loadData();
		this.eventSubtype = this.list.getOptions(3)
		//this.publication = this.defaults
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				this.publication = data
				this.dateFrom = this.publication.dateStart
				this.dateTo = this.publication.dateEnd
			}
		},
		getData: function() {
			this.publication.dateStart = this.dateFrom;
			this.publication.dateEnd = this.dateTo;
			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;