<template>
    <v-container fluid>
        <div class="h3" v-if="title != ''">{{ title }}</div>
        <v-list three-line>
            <v-list-item  
            v-for="expense in expenses.expenses" :key="expense.id"
            >
                <v-list-item-avatar>
                    <v-icon>mdi-atom</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ expense.label }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <span class="text--primary"><v-icon small>mdi-cash-register</v-icon> {{ expense.price }} CZK</span> 
                            - <v-icon small>mdi-notebook-outline</v-icon> {{ expense.identifier }} 
                            - <v-icon small>mdi-currency-usd</v-icon> {{ expense.originalPrice }} {{ expense.originalCurrency }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-alert v-if="!expenses.hasExpenses" type="info" outlined dense>
            Publikace nemá evidovány žádné výdaje
        </v-alert>
    </v-container>
</template>

<script>
import PublicationService from '../../services/publication-service'

export default {
    name: "PublicationExpensesList",
    props: {
        publication: {
            type: Object,
			required: true,
        },
        title: {
            type: String,
            default: "Výdaje za publikaci",
            required: false,
        }
    },
    data: () => ({
        kwords: [],
        publicationService: new PublicationService(),
        expenses: [],
    }),
	watch: {
		publication: function(nVal, oVal) {
			if(nVal != oVal) {
				this.load();
			}
		}
	},
	mounted: function() {
        this.load()
	},
    methods: {
        load: function() {
            console.log("Loading expenses")
            this.publicationService.fetchExpenses( this.publication.id )
                .then(r => {
                    this.expenses = r.data;
                })
        }
    },
}
</script>