<template>
	<div>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="patternTypeId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.patternTypeId"
					:items="list.patternType"
					:label="$t('publications.general.pattern-type')" 
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="number" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.number"
					:label="$t('publications.pattern.number')"
					hint="" 
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="ownerName" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.ownerName"
					:label="$t('publications.general.owner-name')"
					hint="" 
					:error-messages="errors" 
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="patternPublisherId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.patternPublisherId"
					:items="list.patentPublisher"
					:label="$t('publication.general.publisher')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="3">
				<v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" offset-y min-width="auto">
					<template v-slot:activator="{on, attrs}">
						<validation-provider vid="dateOfAcceptance" v-slot="{errors}" rules="required">
						<v-text-field
							:error-messages="errors"
							v-model="date" :label="$t('publication.pattern.date-of-acceptance')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
						</validation-provider>
					</template>
					<v-date-picker v-model="date" no-title scrollable >
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">Zavřít</v-btn>
						<v-btn text color="primary" @click="$refs.menu.save(date)">Vložit</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="licenseObligationId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.licenseObligationId"
					:items="list.licenceObligation"
					:label="$t('publications.general.license-obligation')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<v-select v-model="publication.licenceFeeId"
					:items="list.licenseFee"
					:label="$t('publications.general.license-fee')"
					required
					></v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="typeOfUseId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.typeOfUseId"
					:items="list.patentUseType"
					:label="$t('publication.pattern.type-of-use')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'PatentForm',
	props: {
		defaults: {type: Object, default: () => {return {q: '1'}}},
		errors: Array,
	},
	data: () => ({
		formTypes: [],
		publication: {},
		date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		list: listsStore(),
		menu: false
	}),
	mounted: async function() {
		await this.loadData();
		//this.publication = this.defaults
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				this.publication = data
				this.date = this.publication.dateOfAcceptance
			}
		},
		getData: function() {
			this.publication.dateOfAcceptance = this.date;
			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;