import { axiosInst } from "../main";
import BaseService from "./base-service";

export default class PersonService extends BaseService {
    
    async fetchAll( params, loadingCallback )  {
        const response = await axiosInst.get('/authors', {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async get(id, params) {
        const response = await axiosInst.get(`/authors/${id}`, {
            params: params
        })
        
        return response
    }

    async update(id, params, data) {
        const response = await axiosInst.put(`/authors/${id}`, data, {
            params: params
        })
        
        return response
    }

    async fetchDepartments( uic, params, loadingCallback )  {
        let path = (id) => `/authors/${id}/departments`
        const response = await axiosInst.get( path(uic), {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }
}