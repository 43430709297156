var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('journal-picker',{attrs:{"value":_vm.journal.id,"readonly":_vm.editDisabled,"value-text":_vm.journal.title,"errors":_vm.errors},on:{"input":_vm.journalPicked}}),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"articleSubtypeId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"readonly":_vm.editDisabled,"items":_vm.list.articleSubtype,"label":_vm.$t('publications.article.sub-type'),"item-value":"value","item-text":"text","error-messages":errors,"required":""},model:{value:(_vm.publication.articleSubtypeId),callback:function ($$v) {_vm.$set(_vm.publication, "articleSubtypeId", $$v)},expression:"publication.articleSubtypeId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"volume","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.editDisabled,"label":_vm.$t('publications.article.volume'),"disabled":!_vm.hasJournal,"required":"","error-messages":errors,"hint":_vm.$t('help.hints.volume')},model:{value:(_vm.publication.volume),callback:function ($$v) {_vm.$set(_vm.publication, "volume", $$v)},expression:"publication.volume"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"issue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.editDisabled,"label":_vm.$t('publications.article.issue'),"disabled":!_vm.hasJournal,"required":"","error-messages":errors,"hint":_vm.$t('help.hints.issue')},model:{value:(_vm.publication.issue),callback:function ($$v) {_vm.$set(_vm.publication, "issue", $$v)},expression:"publication.issue"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"pageFrom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.editDisabled,"label":_vm.$t('publications.general.page-from'),"disabled":!_vm.hasJournal,"error-messages":errors,"hint":_vm.$t('help.hints.no-of-copies')},model:{value:(_vm.publication.pageFrom),callback:function ($$v) {_vm.$set(_vm.publication, "pageFrom", $$v)},expression:"publication.pageFrom"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"pageTo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.editDisabled,"label":_vm.$t('publications.general.page-to'),"disabled":!_vm.hasJournal,"error-messages":errors,"hint":_vm.$t('help.hint.page-to')},model:{value:(_vm.publication.pageTo),callback:function ($$v) {_vm.$set(_vm.publication, "pageTo", $$v)},expression:"publication.pageTo"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"noOfPages","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.editDisabled,"label":_vm.$t('publications.general.no-of-pages'),"disabled":!_vm.hasJournal,"error-messages":errors,"hint":_vm.$t('help.hints.no-of-pages')},model:{value:(_vm.publication.noOfPages),callback:function ($$v) {_vm.$set(_vm.publication, "noOfPages", $$v)},expression:"publication.noOfPages"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"eISSN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.editDisabled,"label":_vm.$t('publications.eissn'),"disabled":!_vm.hasJournal,"maxlength":"15","error-messages":errors,"hint":_vm.$t('help.hints.eissn')},model:{value:(_vm.publication.eissn),callback:function ($$v) {_vm.$set(_vm.publication, "eissn", $$v)},expression:"publication.eissn"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"kodWOS"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.editDisabled,"label":_vm.$t('publications.general.wos'),"disabled":!_vm.hasJournal,"error-messages":errors},model:{value:(_vm.publication.kodWOS),callback:function ($$v) {_vm.$set(_vm.publication, "kodWOS", $$v)},expression:"publication.kodWOS"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"eid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.editDisabled,"label":_vm.$t('publications.general.eid'),"disabled":!_vm.hasJournal,"error-messages":errors},model:{value:(_vm.publication.eid),callback:function ($$v) {_vm.$set(_vm.publication, "eid", $$v)},expression:"publication.eid"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }