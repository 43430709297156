<template>
	<div>
		<v-row>
			<v-col cols="8">
				<v-text-field v-model="publicationSubtype.levelOfSecrecy" readonly label="Důvěrnost údajů"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.researchReportType" readonly label="Druh výzkumné zprávy"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="2">
				<v-text-field v-model="publicationSubtype.isbn" readonly label="Kód ISBN nebo ISMN"></v-text-field>
			</v-col>
			<v-col cols="2">
				<v-text-field v-model="publicationSubtype.noOfPages" readonly label="Počet stran"></v-text-field>
			</v-col>
			<v-col cols="3"> 
				<v-text-field v-model="publicationSubtype.placeOfIssue" readonly label="Místo vydání"></v-text-field>
			</v-col>
			<v-col cols="2"> 
				<v-text-field v-model="publicationSubtype.editionName" readonly label="Verze výzkumné zprávy"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.publisherName" readonly label="Název objednatele"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';

export default {
	name: 'ResearchDetail',
	props: {
		publication: Object,
	},
    components: {
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		publicationSubtype: {},
		levelOfSecrecy: {},
		researchReport: {},
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.publicationSubtype = this.publication.research

			this.journal = {
				title: this.article.journal,
				id: this.article.journalId,
			}

			this.levelOfSecrecyInfo = this.list.getListValue("levelOfSecrecy", this.subtype.levelOfSecrecyId)
			this.researchReport = this.list.getListValue("researchReport", this.subtype.researchReportId)
		}
	},
}

</script>;