<template>
	<v-card class="pa-4 ma-4">
		<v-card-title>Schvalování publikací</v-card-title>
		<v-toolbar dense flat>
			<DepartmentPicker @input="unitPicked" :value="baseUnit.id" :value-text="baseUnit.name"  class="mx-2"></DepartmentPicker>
			<v-select class="mx-2" v-model="query.publicationStateId"
					:items="listsStore.publicationStateList"
					label="Stav publikace"
					required
					></v-select>
			<v-select  class="mx-2" v-model="query.year"
					:items="listsStore.yearList"
					label="Rok publikace"
					required
					></v-select>
			<v-btn @click="search()" class="mx-2">Zobrazit</v-btn>
			<v-spacer></v-spacer>
			<reload-button-component class="mx-2" v-on:reload="search()" />
		</v-toolbar>
		<v-card-text>
			<v-data-table class="mt-3" :headers="headers" :items="approvalStore.items" 
				:items-per-page="15" show-select v-model="selectedPublications"
				:expanded.sync="expanded" show-expand>
				<template v-slot:top>
					<v-btn class="mr-2" :disabled="!(selectedPublications.length > 0)" @click="approveSelectedAs(2)">Hromadně schválit vybrané jako vedoucí katedry</v-btn>
					<v-btn class="mr-2" :disabled="!(selectedPublications.length > 0)" @click="approveSelectedAs(3)">Hromadně schválit vybrané jako proděkan</v-btn>
				</template>
				<template v-slot:expanded-item="{headers, item}">
					<td :colspan="headers.length">
						<PublicationView :publication="item" @changeState="detailChangeState" ></PublicationView>
					</td>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script> 
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { authStore } from '../stores/user'
import DepartmentPicker from './parts/DepartmentPicker.vue'
import { listsStore } from '../stores/lists-store'
import { useApprovalStore } from '../stores/approval-store'
import { useNotificationStore } from '../stores/notification-store'
import PublicationView from './PublicationView.vue'

export default {
	components: { ReloadButtonComponent, DepartmentPicker, PublicationView },
	name: 'ApprovalManager',
	props: {
		
	},
	data: () => ({
		uic: 0,
		headers: [
			{text: "#", value: 'id'},
			{text: "Název", value: 'name'},
			{text: "Typ", value: 'publicationType'},
			{text: "Stav", value: 'publicationState'},
			{text: "Rok", value: 'yearOfPublication'},
		],
		expanded: [],
		selectedPublications: [],
		publications: [],
		approvalStore: useApprovalStore(),
		query: useApprovalStore().searchQuery,
		listsStore: listsStore(),
		userStore: authStore(),
		notificationStore: useNotificationStore(),
		baseUnit: {},
	}),
	mounted: async function() {
		await this.listsStore.fetch();

		if( this.approvalStore.pickedBaseUnit ) {
			this.baseUnit = this.approvalStore.pickedBaseUnit;
		}
	},
	methods: {
		load: function() {
			//this.publicationStore.fetch( {uic: this.userStore.uic} )
		},
		search: function() {
			this.approvalStore.clear();
			this.approvalStore.fetch( this.approvalStore.searchQuery );
		},
		personPicked: function(data) {
			if(data) {
				this.pickedPerson = data;
				this.query.pickedPerson = data;
				this.query.uic = data.uic;
			}
		},
		unitPicked: function(data) {
			if(data) {
				this.baseUnit = data;
				this.query.baseUnitId = data.id;

				this.approvalStore.pickedBaseUnit = this.baseUnit
			}
		},
		approveSelectedAs: async function(type) {
			console.log("approving", this.selectedPublications.length)

			if(this.selectedPublications.length > 0) {
				console.log("approving")
				let totalToApprove = this.selectedPublications.length;
				let approved = 0;
				for (const [i,p] of this.selectedPublications.entries()) {
					await this.approvalStore.changeState( p.id, type, "", {}, () => {
						this.notificationStore.addSuccess({message: "Publikace schválena"})
						approved++;
					})
				}
				/*
				this.notificationStore.addSuccess({message: `Schváleno ${approved} publikací z celkem ${totalToApprove}`})
				*/
				this.selectedPublications = [];
			}
		},
		detailChangeState: function(ev) {
			console.log(ev)
			let [data, type] = ev
			console.log(ev, data, type)
			if(data) {
				this.approvalStore.changeState(data.id, type, "", {}, () => {
					this.notificationStore.addSuccess({message: "Publikace schválena"})
					
					let index = this.expanded.filter( t => t.id = data.id )
					this.expanded.splice(index, 1)
					
					//let pubIndex = this.approvalStore.items.filter( t => t.id = data.id )
					//this.approvalStore.items.splice(pubIndex, 1)
					this.search()
				} )
			}
		}
	},
}

</script>;