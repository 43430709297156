import { defineStore } from "pinia";
import ListsService from "../services/lists-service";

export const listsStore = defineStore("listsStore", {
    state: () => ({
        isLoaded: false,
        items: [],
        searchQuery: {
			name: null,
		},
        fordList: [],
        cepList: [],
        publicationTypeList: [],
        languageList: [],
        confidentialityList: [],
        openAccessList: [],
        yearList: [],
        publicationStateList: [],
        formOfPublication: [],
        eventType: [],
        licenceObligation:[],
        licenseFee: [],
        patentUseType: [],
        patentPublisher: [],
        country: [],
        resultCategory: [],
        publicationSubtype: [],
        scopeOfUse: [],

        artCategory: [],
        artObligation: [],
        artScope: [],
        artContext: [],
        patternType: [],

        researchReport: [],
        levelOfSecrecy: [],
        accessType: [],
        articleSubtype: [],
        optionsList: [],
        faculties: [],

        grantProvider: [],
    }),
    getters: {
        getOptions: (state) => {
            return (option) => state.optionsList.filter( f => f.optionId == option )
        },
        getListValue: (state) => {
            return (list, id) => state[list].find( f => f.value == id )
        }
    },
    actions: {
        async fetch(params) {
            if(!this.isLoaded) {
                let service = new ListsService();
                const results = await service.fetch(params);
                this.fordList = results.data.fordList.data
                this.cepList = results.data.cepList.data
                this.publicationTypeList =results.data.publicationTypeList.data
                this.languageList = results.data.languageList.data
                this.confidentialityList = results.data.confidentialityList.data
                this.openAccessList =results.data.openAccessList.data
                this.yearList = results.data.yearList.data
                this.publicationStateList = results.data.publicationState.data

                this.formOfPublication = results.data.formOfPublication.data;
                this.eventType = results.data.eventType.data

                this.publicationSubtype = results.data.publicationTypeList.data

                this.licenseFee = results.data.licenseFee.data;
                this.patentPublisher = results.data.patentPublisher.data;
                this.licenceObligation = results.data.licenceObligation.data;
                this.patentUseType = results.data.patentUseType.data;
                this.patentPublisher = results.data.patentPublisher.data;
                this.patternType = results.data.patternType.data;

                this.country  = results.data.country.data;
                this.resultCategory  = results.data.resultCategory.data;

                this.publicationSubtype = results.data.publicationSubtype.data;
                this.scopeOfUse = results.data.scopeOfUse.data;

                this.artScope  = results.data.artScope.data;
                this.artObligation  = results.data.artObligation.data;
                this.artContext  = results.data.artContext.data;
                this.artCategory  = results.data.artCategory.data;

                this.researchReport  = results.data.researchReport.data;
                this.levelOfSecrecy  = results.data.levelOfSecrecy.data;
                this.accessType  = results.data.accessType.data;
                this.articleSubtype = results.data.articleSubtype.data;
                this.optionsList = results.data.optionsList.data;
                
                this.faculties = results.data.faculties.data;
                this.typeOfUse = results.data.typeOfUse.data;

                this.grantProvider = results.data.grantProvider.data;

                this.isLoaded = true
            }
        }
    }
})