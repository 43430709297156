<template>
    <v-combobox :label="title" append-icon="" chips multiple v-model="kwords" readonly>
        <template v-slot:selection="data">
            <v-chip class="ml-2"
                small label
                :key="data.item"
                >
                {{ data.item }}
            </v-chip>
        </template>
    </v-combobox>
</template>

<script>

export default {
    name: "KeywordsChipper",
    props: {
        delimiter: {
            type: String,
            required: false,
            default: ";"
        },
        title: {
            type: String,
            required: false,
            default: "Klíčová slova"
        },
        keywords: String,
    },
    data: () => ({
        kwords: []
    }),
    watch: {
        keywords: function(n, o) {
            console.log("kword changed", n,o)
            this.parseKwords(this.keywords, this.delimiter)
        }
    },
	mounted: function() {
        if(this.keywords && this.keywords != "") {
            this.parseKwords(this.keywords, this.delimiter)
        }
	},
    methods: {
        parseKwords: function(k, delimiter) {
            console.log("parsing", k, "with", delimiter)
            this.kwords = (""+k).split(delimiter)
        }
    },
}
</script>