<template>
  <v-app>
      <v-navigation-drawer 
        app 
        :mini-variant.sync="navDrawerMini"
        :expand-on-hover="navDrawerHover"
        v-if="userStore.authorized"
        >
        <v-container>
          <v-btn
            icon
            @click.stop="navDrawerMini = !navDrawerMini"
          >
            <v-icon>{{ navDrawerMini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          </v-btn>
          <v-btn icon 
            v-if="false"
            @click.stop="navDrawerHover = !navDrawerHover">
            <v-icon>{{ navDrawerHover ? 'mdi-pin' : 'mdi-pin-off' }}</v-icon>
          </v-btn>
        </v-container>

        <v-divider></v-divider>
        <v-list dense >
          <v-list-item 
            v-for="menuItem in menuItems" :key="menuItem.icon"
            :to="menuItem.to"
            v-if="menuItem.roles.length == 0 || userStore.hasRole(menuItem.roles)"
            link>
            <v-list-item-icon><v-icon>{{ menuItem.icon }}</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
			<v-app-bar elevation="0" app>
        <v-app-bar-nav-icon @click.stop="navDrawerMini = !navDrawerMini"></v-app-bar-nav-icon>
				<v-app-bar-title>CV</v-app-bar-title>
          <!--
					<v-btn class="ml-5" v-on:click="simulateLoading()" >trigger loading</v-btn>
          -->
          <v-spacer></v-spacer>
          <v-btn icon @click="openSearch"><v-icon>mdi-magnify</v-icon></v-btn>
          <logged-user-component v-if="userStore.isAuthorized" />
          <v-progress-linear
            :active="applicationLoading"
            :indeterminate="applicationStore.loading"
            absolute
            bottom
            color="deep-purple accent-4"
            ></v-progress-linear>
			</v-app-bar>
			<v-main fluid>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
			</v-main>
      <alert-manager />
      <global-loading-overlay></global-loading-overlay>

      <PublicationTagDialog></PublicationTagDialog>
      <PublicationLogDialog></PublicationLogDialog>
      <GlobalSearchModal :enabled="globalSearchEnabled"></GlobalSearchModal>
		</v-app> 
</template>

<script>
import AlertManager from '@/components/AlertManager.vue';
import GlobalLoadingOverlay from '@/components/GlobalLoadingOverlay.vue';
import LoggedUserComponent from '@/components/LoggedUserComponent.vue';
import { useApplicationStore } from '@/stores/application';
import { listsStore } from '@/stores/lists-store';
import {authStore} from '@/stores/user';
import i18n from './i18n';
import PublicationTagDialog from './components/dialogs/PublicationTagDialog.vue';
import PublicationLogDialog from './components/dialogs/PublicationLogDialog.vue';
import GlobalSearchModal from './components/dialogs/GlobalSearchModal.vue';
import emitter from './plugins/mitt';
//import PublicationList from './components/PublicationList.vue'

export default {
  components: { AlertManager, LoggedUserComponent, GlobalLoadingOverlay, PublicationTagDialog, PublicationLogDialog, GlobalSearchModal },
  name: 'App',

  data: () => ({
    currentUIC: Number,
    applicationLoading: false,
    currentUser: Object,
    userStore: authStore(),
    applicationStore: useApplicationStore(),
    listStore: listsStore(),

    navDrawerVisible: true,
    navDrawer: true,
    navDrawerMini: true,
    navDrawerHover: false,

    globalSearchEnabled: authStore().isAuthorized,

    menuItems: [
      {title: i18n.t("views.home"), icon: "mdi-home-account", roles: [], to: {name: "dashboard"}},
      {title: i18n.t("views.myPublications"), icon: "mdi-book-account", roles: [], to: {name: "user-publications"}},
      {title: i18n.t("views.publicationManagement"), icon: "mdi-book-search", roles: ["ADMINISTRATOR", "PUBLICATIONS_MANAGER"], to: {name: "publications"}},
      {title: i18n.t("views.departments"), icon: "mdi-home-city", roles: ["ADMINISTRATOR", "DEPARTMENT_MANAGER"], to: {name: "departments"}},
      {title: i18n.t("views.faculties"), icon: "mdi-town-hall", roles: ["ADMINISTRATOR", "FACULTY_MANAGER", "DEPARTMENT_MANAGER"], to: {name: "faculties"}},
      {title: i18n.t("views.publicationApproval2"), icon: "mdi-check-decagram", roles: ["ADMINISTRATOR", "PUBLICATION_APPROVAL"], to: {name: "approval-new"}},
      //{title: i18n.t("views.publicationApproval"), icon: "mdi-check-decagram", roles: ["ADMINISTRATOR", "PUBLICATION_APPROVAL"], to: {name: "approval"}},
      {title: i18n.t("views.applicationManagement"), icon: "mdi-cogs", roles: ["ADMINISTRATOR"], to: {name: "settings"}},
    ]
  }),

  methods: {
    applicationLoadingEvent(state) {
      console.log("loading event captured")
      this.applicationLoading = state
    },

    simulateLoading() {
      this.$root.$emit("applicationLoading", true);
      console.log("simulating loading")
      
      this.$emit("alert", "my-alert")
      setTimeout( this.simulateLoaded, 1000);
    },
    simulateLoaded() {
      console.log("application data loaded")
      this.$root.$emit("applicationLoading", false);
    },
    openSearch: function() {
      emitter.emit("application.globalSearch.open", {});
    }
  },

  mounted: async function() {
    this.applicationLoading = false
    this.$root.$on("applicationLoading", this.applicationLoadingEvent )

    const currentUserStore = authStore();
    //currentUserStore.verify();
    this.currentUser = currentUserStore

    if(!this.applicationStore.isInitialized ) {
      this.applicationStore.overlayLoading("Loading settings")
      await this.listStore.fetch({})
      this.applicationStore.hideOverlayLoading()
    }
  },
};
</script>