<template>
	<div>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="patentNo" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.patentNo"
					:label="$t('publications.patent.number')"
					hint="" 
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="patentPublisherId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.patentPublisherId"
					:items="list.patentPublisher"
					:label="$t('publications.patent.publisher')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="ownerName" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.ownerName"
					:label="$t('publications.general.owner-name')"
					hint="" 
					:error-messages="errors" 
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="3">
				<v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" offset-y min-width="auto">
					<template v-slot:activator="{on, attrs}">
						<v-text-field
							v-model="date" label="$t('publications.patent.date-assigned')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
					</template>
					<v-date-picker v-model="date" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">{{ $t('btn.close') }}</v-btn>
						<v-btn text color="primary" @click="$refs.menu.save(date)">{{ $t('btn.insert') }}</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col cols="3">
				<v-checkbox v-model="publication.realized" 
					:label="$t('publications.patent.is-realized')"></v-checkbox>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="licenseObligationId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.licenseObligationId"
					:items="list.licenceObligation"
					:label="$t('publications.general.license-obligation')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<v-select v-model="publication.licenceFeeId"
					:items="list.licenseFee"
					:label="$t('publications.general.license-fee')"
					required
					></v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="typeOfUseId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.typeOfUseId"
					:items="list.patentUseType"
					:label="$t('publications.patent.type-of-use')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="useByOthersId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.useByOthersId"
					:items="list.getOptions(5)"
					:label="$t('publications.patent.type-of-use-by-others')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>

		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'PatentForm',
	props: {
		defaults: {type: Object, default: () => {return {q: '1'}}},
		errors: Array,
	},
	data: () => ({
		formTypes: [],
		publication: {},
		date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		list: listsStore(),
		menu: false
	}),
	mounted: async function() {
		await this.loadData();
		//this.publication = this.defaults
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				this.publication = data
				this.date = this.publication.dateOfAcceptance
			}
		},
		getData: function() {
			this.publication.dateOfAcceptance = this.date;
			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;