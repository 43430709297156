<template>
	<div>
        <v-row>
            <v-col cols="8">
                <v-text-field label="Název publikace - originál" v-model="publication.nameEn" readonly></v-text-field>
                <v-text-field :label="$t('publications.name')" v-model="publication.name" readonly></v-text-field>
                <v-textarea :label="$t('publications.annotation')" v-model="publication.annotation" readonly></v-textarea>
                <v-textarea :label="$t('publications.annotation-original')" v-model="publication.annotationEn" readonly></v-textarea>
                <v-text-field :label="$t('publications.keywords')" v-model="publication.keywords" readonly></v-text-field>
            </v-col>
            <v-col>
                <v-text-field :label="$t('publications.year')" v-model="publication.yearOfPublication" readonly></v-text-field>
                <v-text-field label="Typ publikace" v-model="publication.publicationType" readonly></v-text-field>
                <v-text-field :label="$t('publications.ford')" v-model="publication.ford" readonly></v-text-field>
                <v-text-field :label="$t('publications.open-access')" v-model="publication.openAccessType" readonly></v-text-field>
                <v-text-field label="Utajení" v-model="publication.levelOfSecrecy" readonly></v-text-field>
                <v-text-field :label="$t('publications.language')" v-model="publication.language" readonly></v-text-field>
            </v-col>
        </v-row>
        <v-divider></v-divider>

        <!-- details-->
        <v-card-subtitle>$t('view.publication-specific-data')</v-card-subtitle>
        <journal-article-detail v-if="publication.publicationTypeId==1" :publication="publication"></journal-article-detail>
        <book-detail v-if="publication.publicationTypeId==2" :publication="publication"></book-detail>
        <book-chapter-detail v-if="publication.publicationTypeId==3" :publication="publication"></book-chapter-detail>
        <proceedings-detail v-if="publication.publicationTypeId==4" :publication="publication"></proceedings-detail>
        <patent-detail v-if="publication.publicationTypeId==5" :publication="publication"></patent-detail>
        <operation-detail  v-if="publication.publicationTypeId==6" :publication="publication"></operation-detail>
        <pattern-detail v-if="publication.publicationTypeId==7" :publication="publication"></pattern-detail>
        <prototype-detail v-if="publication.publicationTypeId==8" :publication="publication"></prototype-detail>
        <methodology-detail v-if="publication.publicationTypeId==9" :publication="publication"></methodology-detail>
        <software-detail v-if="publication.publicationTypeId==10" :publication="publication"></software-detail>
        
        <research-detail v-if="publication.publicationTypeId==11" :publication="publication"></research-detail>
        
        <presentation-detail v-if="publication.publicationTypeId==12" :publication="publication"></presentation-detail>
        <other-detail v-if="publication.publicationTypeId==14" :publication="publication"></other-detail>
        <conference-detail v-if="publication.publicationTypeId==13" :publication="publication"></conference-detail>

        <art-detail v-if="publication.publicationTypeId==15" :publication="publication"></art-detail>
        <law-result-detail  v-if="publication.publicationTypeId==16" :publication="publication"></law-result-detail>
        <public-database-detail  v-if="publication.publicationTypeId==17" :publication="publication"></public-database-detail>
        <exhibition-detail v-if="publication.publicationTypeId==18" :publication="publication"></exhibition-detail>
            
        <v-divider></v-divider>

        <v-card-subtitle>{{ $t('view.authors') }}</v-card-subtitle>
        <v-alert v-if="(publication && publication.authors && publication.authors.length == 0)" type="info" outlined dense>{{ $t('messages.no-authors-assigned') }}</v-alert>
        <v-simple-table v-if="(publication.authors && publication.authors.length > 0 )">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>{{ $t('general.order') }}</th>
                        <th>{{ $t('labels.author') }}</th>
                        <th></th>
                        <th>{{ $t('persons.type') }}</th>
                        <th>{{ $t('authors.reprint') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="author in publication.authors" :key="author.id">
                        <td>{{(author.priority+1)}}</td>
                        <td>{{author.displayName}}</td>
                        <td>
                            <AuthorDepartment :author="author" :editable="false"></AuthorDepartment>
                        </td>
                        <td>
                            <v-chip v-if="(author.type == 1)">{{ $t('publications.authors.internal') }}</v-chip>
                            <v-chip v-if="(author.type == 2)">{{ $t('publications.authors.external') }}</v-chip>
                        </td>
                        <td>
                            <v-icon v-if="author.correspondenceAuthor">mdi-check</v-icon>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-divider></v-divider>
        <v-card-subtitle>Granty</v-card-subtitle>
        <v-alert v-if="(publication && publication.grants && publication.grants.length == 0)" type="info" outlined dense>{{ $t('messages.no-grants-assigned') }}</v-alert>
        <v-simple-table v-if="(publication && publication.grants && publication.grants.length > 0)">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>{{ $t('grants.name') }}</th>
                        <th>{{ $t('grants.internal-number') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="grant in publication.grants">
                        <td>{{grant.name}}</td>
                        <td>{{grant.internalNumber}}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import ResearchDetail from '@/components/publicationDetails/ResearchDetail.vue';
import ProceedingsDetail from '@/components/publicationDetails/ProceedingsDetail.vue';
import ArtDetail from '@/components/publicationDetails/ArtDetail.vue';
import BookDetail from '@/components/publicationDetails/BookDetail.vue';
import BookChapterDetail from '@/components/publicationDetails/BookChapterDetail.vue';
import MethodologyDetail from '@/components/publicationDetails/MethodologyDetail.vue';
import OperationDetail from '@/components/publicationDetails/OperationDetail.vue';
import OtherDetail from '@/components/publicationDetails/OtherDetail.vue';
import PatentDetail from '@/components/publicationDetails/PatentDetail.vue';
import PresentationDetail from '@/components/publicationDetails/PresentationDetail.vue';
import PrototypeDetail from '@/components/publicationDetails/PrototypeDetail.vue';
import SoftwareDetail from '@/components/publicationDetails/SoftwareDetail.vue';
import JournalArticleDetail from '@/components/publicationDetails/JournalArticleDetail.vue';
import AuthorDepartment from '@/components/parts/AuthorDepartment.vue'; 
import PatternDetail from '@/components/publicationDetails/PatternDetail.vue'; 
import LawResultDetail from '@/components/publicationDetails/LawResultDetail.vue';
import ExhibitionDetail from '@/components/publicationDetails/ExhibitionDetail.vue';
import ConferenceDetail from '@/components/publicationDetails/ConferenceDetail.vue';
import PublicDatabaseDetail from '@/components/publicationDetails/PublicDatabaseDetail.vue';

export default {
    name: "PublicationDetailFull",
    props: {
		publication: {
			type: Object,
			required: true,
		}
    },
    data: () => ({
    }),
    mounted: function () {
		
    },
    methods: {

    },
    components: {
    ResearchDetail,
    ProceedingsDetail,
    ArtDetail,
    BookDetail,
    BookChapterDetail,
    MethodologyDetail,
    OperationDetail,
    OtherDetail,
    PatentDetail,
    PresentationDetail,
    PrototypeDetail,
    SoftwareDetail,
    JournalArticleDetail,
    AuthorDepartment,
    PatternDetail,
    LawResultDetail,
    ExhibitionDetail,
    ConferenceDetail,
    PublicDatabaseDetail
}
}
</script>
