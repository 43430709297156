<template>
	<v-card class="pa-4 ma-4">
		<v-card-title>
			Ford
		</v-card-title>
		<v-toolbar flat>
			<v-btn class="mx-2 primary" disabled v-on:click="openDialog()">Přidat</v-btn>
			<reload-button-component v-on:reload="load()" />
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="Search"
				single-line
				hide-details
			></v-text-field>
		</v-toolbar>
		<v-card-text>
			<v-data-table :headers="headers" 
				:items="fordStore.items" 
				:items-per-page="15"
				:search="search">
			
				<template v-slot:item.locked="{item}">
					<span class="group">
						<v-icon v-if="item.locked">mdi-lock</v-icon>
						<v-icon v-if="!item.locked">mdi-lock-open</v-icon>
						<v-icon v-if="item.visible">mdi-eye</v-icon>
						<v-icon v-if="!item.visible">mdi-eye-off</v-icon>
					</span>
				</template>
				
				<template v-slot:item.actions="{item}">
					<v-btn icon v-bind:disabled="true"><v-icon class="mr-2">mdi-delete</v-icon></v-btn>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script> 
import { axiosInst } from '../main'
import {storeToRefs} from 'pinia'
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import {fordStore} from '../stores/ford-store'

export default {
	components: {ReloadButtonComponent},
	name: 'FordSettings',
	props: {
		panelTitle: String,
	},
	data: () => ({
		headers: [
			{text: "#", value: 'value'},
			{text: "", value: 'locked', sortable: false},
			{text: "Název", value: 'text'},
			{text: "Akce", value: 'actions', sortable: false},
		],
		items: [],
		search: '',
		fordStore: fordStore()
	}),
	mounted: function() {
		this.panelTitle = "Ford"
		this.load()
	},
	methods: {
		load: async function() {
			this.fordStore.items = []
			this.fordStore.fetch({})
			//this.items = storeToRefs(this.fordStore)
		}
	}
}

</script>