<template>
	<v-card flat class="ma-4">
		<v-card-title>
			Poskytovatelé grantu
		</v-card-title>
		<v-toolbar flat>
			<v-btn class="mx-2 primary" disabled v-on:click="openDialog()">Přidat poskytovatele</v-btn>
			<reload-button-component v-on:reload="load()" />
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="Search"
				single-line
				hide-details
			></v-text-field>
		</v-toolbar>
		<v-card-text>
			<v-data-table :headers="headers" 
				:items="providersStore.items" 
				:items-per-page="15"
				:search="search">
			
			<template v-slot:item.actions="{item}">
				<v-btn icon v-bind:disabled="true"><v-icon class="mr-2">mdi-delete</v-icon></v-btn>
			</template>
			</v-data-table>
		</v-card-text>
		<grant-publisher-modal-form 
			:dialogOpen="dialogOpened"
			@close-dialog="dialogOpened = false"
		/>
	</v-card>
</template>

<script> 
import { axiosInst } from '../main'
import GrantPublisherModalForm from '@/components/dialogs/GrantPublisherModalForm.vue'
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { grantPublisherStore } from '../stores/grant-publisher-store'

export default {
  components: { GrantPublisherModalForm, ReloadButtonComponent },
	name: 'GrantPublisherSettings',
	props: {
		panelTitle: String,
	},
	data: () => ({
		headers: [
			{text: "#", value: 'value'},
			{text: "Název", value: 'name'},
			{text: "Zkratka", value: 'abbr'},
			{text: "Kód", value: 'code'},
			{text: "Akce", value: 'actions', sortable: false},
		],
		items: [],
		search: '',
		dialogOpened: false,
		providersModel: null,
		providersStore: grantPublisherStore()
	}),
	mounted: function() {
		this.load()
	},
	methods: {
		load: function() {
			this.providersStore.fetch({})
		},
		openDialog: function() {
			this.dialogOpened = true
		},
	}
}

</script>