var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"formOfPublicationId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.formOfPublication,"label":_vm.$t('publications.general.form-of-publication'),"error-messages":errors,"required":""},model:{value:(_vm.publication.formOfPublicationId),callback:function ($$v) {_vm.$set(_vm.publication, "formOfPublicationId", $$v)},expression:"publication.formOfPublicationId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"ISBN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.isbn'),"error-messages":errors},model:{value:(_vm.publication.isbn),callback:function ($$v) {_vm.$set(_vm.publication, "isbn", $$v)},expression:"publication.isbn"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"ISSN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.issn'),"hint":"","error-messages":errors},model:{value:(_vm.publication.issn),callback:function ($$v) {_vm.$set(_vm.publication, "issn", $$v)},expression:"publication.issn"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('validation-provider',{attrs:{"vid":"proceedingsName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.proceedings.name'),"hint":"Číslo svazku, resp. pořadové číslo vydání, není-li číslo svazku zavedeno. Není-li na svazku ani pořadové číslo vydání nebo je-li na periodiku uveden pouze rok vydání, vyplňte prosím neuvedeno","error-messages":errors},model:{value:(_vm.publication.proceedingsName),callback:function ($$v) {_vm.$set(_vm.publication, "proceedingsName", $$v)},expression:"publication.proceedingsName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"eventTypeId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.eventType,"label":_vm.$t('publications.general.event-type'),"required":"","error-messages":errors},model:{value:(_vm.publication.eventTypeId),callback:function ($$v) {_vm.$set(_vm.publication, "eventTypeId", $$v)},expression:"publication.eventTypeId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"vid":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('publications.event.date-from'),"error-messages":errors,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(_vm._s(_vm.$t('btn.close')))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(_vm._s(_vm.$t('btn.insert')))])],1)],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"pageFrom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.page-from'),"hint":_vm.$t('help.hints.page-from'),"error-messages":errors},model:{value:(_vm.publication.pageFrom),callback:function ($$v) {_vm.$set(_vm.publication, "pageFrom", $$v)},expression:"publication.pageFrom"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"pageTo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.page-to'),"hint":_vm.$t('help.hint.page-to'),"error-messages":errors},model:{value:(_vm.publication.pageTo),callback:function ($$v) {_vm.$set(_vm.publication, "pageTo", $$v)},expression:"publication.pageTo"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"noOfPages","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.no-of-pages'),"hint":_vm.$t('help.hints.no-of-pages'),"error-messages":errors},model:{value:(_vm.publication.noOfPages),callback:function ($$v) {_vm.$set(_vm.publication, "noOfPages", $$v)},expression:"publication.noOfPages"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"noOfCopies","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.genera.no-of-copies'),"error-messages":errors},model:{value:(_vm.publication.noOfCopies),callback:function ($$v) {_vm.$set(_vm.publication, "noOfCopies", $$v)},expression:"publication.noOfCopies"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"placeOfIssue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.place-of-issue'),"hint":"","error-messages":errors},model:{value:(_vm.publication.placeOfIssue),callback:function ($$v) {_vm.$set(_vm.publication, "placeOfIssue", $$v)},expression:"publication.placeOfIssue"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"publisher","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.publisher'),"hint":"","error-messages":errors},model:{value:(_vm.publication.publisher),callback:function ($$v) {_vm.$set(_vm.publication, "publisher", $$v)},expression:"publication.publisher"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"venue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.venue'),"hint":"","error-messages":errors},model:{value:(_vm.publication.venue),callback:function ($$v) {_vm.$set(_vm.publication, "venue", $$v)},expression:"publication.venue"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('publicatons.general.wos'),"hint":" "},model:{value:(_vm.publication.wos),callback:function ($$v) {_vm.$set(_vm.publication, "wos", $$v)},expression:"publication.wos"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.eid'),"hint":""},model:{value:(_vm.publication.eid),callback:function ($$v) {_vm.$set(_vm.publication, "eid", $$v)},expression:"publication.eid"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('publications.eissn'),"hint":" "},model:{value:(_vm.publication.eissn),callback:function ($$v) {_vm.$set(_vm.publication, "eissn", $$v)},expression:"publication.eissn"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }