<template>
	<div>
		<v-row>
			<v-col cols="5">
				<v-text-field v-model="publicationSubtype.publicationSubtype" readonly label="Poddruh výsledku"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.scopeOfUse" readonly label="Způsob využití"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.internalId" readonly label="Interní identifikátor"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="5">
				<v-text-field v-model="publicationSubtype.resultLocalisation" readonly label="Číslo předpisu pro poddruhy A, B, C, E, F"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.identification" readonly label="Číselná identifikace pro poddruh D"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.certificationDate" readonly label="Datum certifikace/Schválení"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-textarea v-model="publicationSubtype.technicalParams" readonly label="Technické parametry"></v-textarea>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-textarea v-model="publicationSubtype.economicalParams" readonly label="Ekonomické parametry"></v-textarea>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-text-field v-model="publicationSubtype.certifiedBy" readonly label="Certifikační / schvalovací orgán"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';

export default {
	name: 'MethodologyDetail',
	props: {
		publication: Object,
	},
    components: {
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		publicationSubtype: {},
		levelOfSecrecy: {},
		researchReport: {},
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.publicationSubtype = this.publication.methodology

		}
	},
}

</script>;