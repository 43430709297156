<template>
	<div>
        <v-row v-if="userStore.hasRole(['ADMINISTRATOR'])">
            <v-col cols="12">
                <PublicationApprovalBar :publication="publication"></PublicationApprovalBar>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <v-text-field label="Název publikace - originál" v-model="publication.nameEn" readonly></v-text-field>
                <v-textarea label="Anotace - originál" v-model="publication.annotation" readonly></v-textarea>
                
                <!-- details-->
                <journal-article-detail v-if="publication.publicationTypeId==1" :publication="publication"></journal-article-detail>
                <book-detail v-if="publication.publicationTypeId==2" :publication="publication"></book-detail>
                <book-chapter-detail v-if="publication.publicationTypeId==3" :publication="publication"></book-chapter-detail>
                <proceedings-detail v-if="publication.publicationTypeId==4" :publication="publication"></proceedings-detail>
                <patent-detail v-if="publication.publicationTypeId==5" :publication="publication"></patent-detail>
                <operation-detail  v-if="publication.publicationTypeId==6" :publication="publication"></operation-detail>
                <pattern-detail  v-if="publication.publicationTypeId==7" :publication="publication"></pattern-detail>
                <prototype-detail v-if="publication.publicationTypeId==8" :publication="publication"></prototype-detail>
                <methodology-detail v-if="publication.publicationTypeId==9" :publication="publication"></methodology-detail>
                <software-detail v-if="publication.publicationTypeId==10" :publication="publication"></software-detail>
                
                <research-detail v-if="publication.publicationTypeId==11" :publication="publication"></research-detail>
                
                <presentation-detail v-if="publication.publicationTypeId==12" :publication="publication"></presentation-detail>
                <conference-detail v-if="publication.publicationTypeId==13" :publication="publication"></conference-detail>
                
                <other-detail  v-if="publication.publicationTypeId==14" :publication="publication"></other-detail>
                <art-detail v-if="publication.publicationTypeId==15" :publication="publication"></art-detail>
                <law-result-detail v-if="publication.publicationTypeId==16" :publication="publication"></law-result-detail>
                <public-database-detail  v-if="publication.publicationTypeId==17" :publication="publication"></public-database-detail>
                <exhibition-detail v-if="publication.publicationTypeId==18" :publication="publication"></exhibition-detail>

                <publication-grant-list :grants="publication.grants"></publication-grant-list>
                <publication-expenses-list :publication="publication"></publication-expenses-list>
            </v-col>
            <v-col>
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="Rok" v-model="publication.yearOfPublication" readonly></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field label="Jazyk publikace" v-model="publication.language" readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-text-field label="Typ publikace" v-model="publication.publicationType" readonly></v-text-field>
                <v-text-field label="Ford" v-model="publication.ford" readonly></v-text-field>
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="Open Access" v-model="publication.openAccessType" readonly></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field label="Útajení" v-model="publication.levelOfSecrecy" readonly></v-text-field>
                    </v-col>
                </v-row>
                <keywords-chipper :keywords="publication.keywords"></keywords-chipper>
                <publication-authors-chips title="Autoři" :authors="publication.authors"></publication-authors-chips>
                <v-combobox label="Značky publikace" append-icon="" chips multiple v-model="tags" readonly>
                    <template v-slot:selection="data">
                        <v-chip :key="data.item.id" small>
                            <v-icon v-if="data.item.locked" class="mr-1" small>mdi-lock</v-icon>
                            <v-icon v-if="data.item.system" class="mr-1" small>mdi-wrench</v-icon>
                            {{ data.item.name }}
                        </v-chip>
                    </template>
                </v-combobox>
                <v-row>
                    <v-col cols="12">
                        <v-textarea label="Citace záznamu" v-model="publication.citation" readonly></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import JournalArticleDetail from '@/components/publicationDetails/JournalArticleDetail.vue';
import KeywordsChipper from './KeywordsChipper';
import PublicationAuthorsChips from './PublicationAuthorsChips';
import PublicationGrantList from './PublicationGrantList';
import ResearchDetail from '@/components/publicationDetails/ResearchDetail.vue';
import ProceedingsDetail from '@/components/publicationDetails/ProceedingsDetail.vue';
import ArtDetail from '@/components/publicationDetails/ArtDetail.vue';
import BookDetail from '@/components/publicationDetails/BookDetail.vue';
import BookChapterDetail from '@/components/publicationDetails/BookChapterDetail.vue';
import MethodologyDetail from '@/components/publicationDetails/MethodologyDetail.vue';
import OperationDetail from '@/components/publicationDetails/OperationDetail.vue';
import OtherDetail from '@/components/publicationDetails/OtherDetail.vue';
import PatentDetail from '@/components/publicationDetails/PatentDetail.vue';
import PresentationDetail from '@/components/publicationDetails/PresentationDetail.vue';
import PrototypeDetail from '@/components/publicationDetails/PrototypeDetail.vue';
import SoftwareDetail from '@/components/publicationDetails/SoftwareDetail.vue';
import PatternDetail from '@/components/publicationDetails/PatternDetail.vue';
import LawResultDetail from '@/components/publicationDetails/LawResultDetail.vue';
import ExhibitionDetail from '@/components/publicationDetails/ExhibitionDetail.vue';
import ConferenceDetail from '@/components/publicationDetails/ConferenceDetail.vue';
import PublicDatabaseDetail from '@/components/publicationDetails/PublicDatabaseDetail.vue';
import PublicationApprovalBar from '@/components/parts/PublicationApprovalBar.vue';
import { authStore } from '../../stores/user';
import PublicationExpensesList from './PublicationExpensesList.vue';

export default {
    name: "PublicationDetailShort",
    props: {
		publication: {
			type: Object,
			required: true,
		}
    },
    data: () => ({
        tags: [],
        userStore: authStore(),
    }),
    mounted: function () {
    },
    watch: {
        publication: function(n, o) {
            this.extractTags()
        }
    },
    methods: {
        extractTags: function() {
            this.tags = [];
            console.log("tags", this.publication.tags)
            if( this.publication.tags && this.publication.tags.length > 0 ) {
                this.publication.tags.forEach( (i) => {
                    
                    this.tags.push( i )
                } )
            }

        }
    },
    components: {
    KeywordsChipper,
    PublicationAuthorsChips,
    PublicationGrantList,
    JournalArticleDetail,
    ResearchDetail,
    ProceedingsDetail,
    ArtDetail,
    BookDetail,
    BookChapterDetail,
    MethodologyDetail,
    OperationDetail,
    OtherDetail,
    PatentDetail,
    PresentationDetail,
    PrototypeDetail,
    SoftwareDetail,
    PatternDetail,
    LawResultDetail,
    ExhibitionDetail,
    ConferenceDetail,
    PublicDatabaseDetail,
    PublicationApprovalBar,
    PublicationExpensesList
}
}
</script>
