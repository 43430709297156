<template>
	<div>
		<v-row>
			<v-col cols="4">
				<validation-provider vid="formOfPublicationId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.formOfPublicationId"
					:items="list.formOfPublication"
					:label="$t('publications.general.form-of-publication')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="ISBN" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.isbn"
					:label="$t('publications.general.isbn')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="ISSN" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.issn"
					:label="$t('publications.general.issn')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="7">
				<validation-provider vid="proceedingsName" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.proceedingsName"
					:label="$t('publications.proceedings.name')"
					hint="Číslo svazku, resp. pořadové číslo vydání, není-li číslo svazku zavedeno. Není-li na svazku ani pořadové číslo vydání nebo je-li na periodiku uveden pouze rok vydání, vyplňte prosím neuvedeno"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="3">
				<validation-provider vid="eventTypeId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.eventTypeId"
					:items="list.eventType"
					:label="$t('publications.general.event-type')"
					required
					:error-messages="errors"
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="2">
				<validation-provider vid="date" v-slot="{errors}" rules="required">
				<v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" offset-y min-width="auto">
					<template v-slot:activator="{on, attrs}">
						<v-text-field
							v-model="date" :label="$t('publications.event.date-from')" :error-messages="errors" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
					</template>
					<v-date-picker v-model="date" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">{{ $t('btn.close') }}</v-btn>
						<v-btn text color="primary" @click="$refs.menu.save(date)">{{ $t('btn.insert') }}</v-btn>
					</v-date-picker>
				</v-menu>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3">
				<validation-provider vid="pageFrom" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.pageFrom"
					:label="$t('publications.general.page-from')"
					:hint="$t('help.hints.page-from')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="3">
				<validation-provider vid="pageTo" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.pageTo"
					:label="$t('publications.general.page-to')"
					:hint="$t('help.hint.page-to')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="3">
				<validation-provider vid="noOfPages" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.noOfPages"
					:label="$t('publications.general.no-of-pages')"
					:hint="$t('help.hints.no-of-pages')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="3">
				<validation-provider vid="noOfCopies" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.noOfCopies"
					:label="$t('publications.genera.no-of-copies')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<validation-provider vid="placeOfIssue" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.placeOfIssue"
					:label="$t('publications.general.place-of-issue')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="publisher" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.publisher"
					:label="$t('publications.general.publisher')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="venue" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.venue"
					:label="$t('publications.general.venue')"
					hint=""
					:error-messages="errors" 
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<v-text-field v-model="publication.wos"
					:label="$t('publicatons.general.wos')"
					hint=" "
					></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publication.eid"
					:label="$t('publications.general.eid')"
					hint=""
					></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publication.eissn"
					:label="$t('publications.eissn')"
					hint=" "
					></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import {axiosInst} from '../../main'
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'ProceedingsForm',
	props: {
		defaults: {type: Object, default: () => {return {q: '1'}}},
		errors: Array,
	},
	data: () => ({
		formTypes: [],
		publication: {},
		date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		menu: false,
		list: listsStore()
	}),
	mounted: async function() {
		await this.loadData();
//		this.publication = this.defaults
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				this.publication = data
				this.date = this.publication.startDate
			}
		},
		getData: function() {
			this.publication.startDate = this.date;
			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;