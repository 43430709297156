import { defineStore } from "pinia";

export const useHelpStore = defineStore("helpStore", {
    state: () => ({
        isLoaded: false,
        help: [
            {field: "name", text: "Uveďte přesný a úplný (nezkrácený) název výsledku v původním jazyce, ve kterém byl výsledek publikován, zveřejněn nebo realizován."},
            {field: "nameEn", text: "Toto pole není nutné vyplňovat, pokud je původní jazyk výsledku angličtina. Uveďte přesný a úplný (nezkrácený) název výsledku v anglickém jazyce."},
            {field: "annotation", text: "Napište souhrn nebo anotaci nebo abstrakt v původním jazyce, ze kterého je patrné co výsledek řeší nebo čeho bylo dosaženo. Minimální délka textu je 64 znaků. V popisku výsledku lze používat pouze českou alfanumerickou klávesnici bez speciálních znaků"},
            {field: "annotationEn", text: "Toto pole není nutné vyplňovat, pokud je původní jazyk výsledku angličtina. Napište souhrn nebo anotaci nebo abstrakt v anglickém jazyce, ze kterého je patrné co výsledek řeší nebo čeho bylo dosaženo. Minimální délka textu je 64 znaků."},
            {field: "keywords", text: "Vypište klíčová slova, jak se běžně uvádějí v renomovaných informačních zdrojích. Slova lze oddělovat středníkem, jako klíčové slovo je možné použít i sousloví (několik slov oddělených mezerami)."},
            {field: "yearOfPublication", text: "Rok publikace výsledku"},
            {field: "publicationTypeId", text: "Typ výsledku (výsledek je možné změnit později)"},
            {field: "languageId", text: "Vyberte ze seznamu původní jazyk, ve kterém byl výsledek publikován, zveřejněn nebo realizován."},
            {field: "fordId", text: "Kód z číselníku oborů platného pro CEP a RIV zveřejněného na www.rvvi.cz (podle oborových skupin OECD - Frascati Manual 2015). Uvedení kódu je povinné."},
            {field: "levelOfSecrecyId", text: ""},
            {field: "doi", text: "DOI (Digital Object Identifier1), který identifikuje výsledek (článek, knihu, kapitolu apod.). Vyplňuje se, pokud údaj existuje a je znám."},
            {field: "web", text: "Pro publikační výsledky se jedná o odkaz na webovou stránku, ze které je dostupný plný text výsledku. Je-li plný text výsledku archivován ve veřejně přístupném institucionálním repozitáři předkladatele, uvede se odkaz do tohoto repozitáře."},
            {field: "openAccessTypeId", text: "Typ OpenAccess výsledku, povinné pro výsledky J."},
        ],
    }),
    getters: {
        getHelp: (state) => {
            return (help) => state.help.find( f => f.field == help )
        }
    },
    actions: {
        async fetch(params) {
            if(!this.isLoaded) {
          
                this.isLoaded = true
            }
        }
    }
})