var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"mt-2 mb-2 elevation-0",attrs:{"dense":""}},[_c('PersonPicker',{staticClass:"mx-2",attrs:{"value":_vm.publications.pickedAuthor.uic,"value-text":_vm.publications.pickedAuthor.displayName},on:{"input":_vm.personPicked}}),_c('v-select',{staticClass:"mx-2",attrs:{"items":_vm.listsStore.yearList,"label":"Rok publikace","required":""},model:{value:(_vm.query.year),callback:function ($$v) {_vm.$set(_vm.query, "year", $$v)},expression:"query.year"}}),_c('v-btn',{staticClass:"mx-2",on:{"click":function($event){return _vm.search()}}},[_vm._v("Hledat")]),_c('v-spacer'),_c('reload-button-component',{staticClass:"mx-2",on:{"reload":function($event){return _vm.load()}}})],1),_c('v-divider'),({})?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.publications.items,"items-per-page":15},scopedSlots:_vm._u([{key:"item.creator",fn:function(ref){
var item = ref.item;
return undefined}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.wos)?_c('v-chip',{attrs:{"small":"","color":_vm.getChipColor(item.wosStatusId),"dark":""}},[_c('v-icon',[_vm._v("mdi-alpha-w")]),(item.wosStatusId==1)?_c('v-progress-circular',{staticClass:"ml-1",attrs:{"indeterminate":"","size":"15","width":"1"}}):_vm._e(),(item.wosStatusId==2)?_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()],1):_vm._e(),(item.scopus)?_c('v-chip',{staticClass:"ml-2",attrs:{"color":_vm.getChipColor(item.scopusStatusId),"small":"","dark":""}},[_c('v-icon',[_vm._v("mdi-alpha-s")]),(item.scopusStatusId==1)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"15","width":"1"}}):_vm._e(),(item.scopusStatusId==2)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()],1):_vm._e()],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" "),(item.earlyAccess)?_c('v-chip',{attrs:{"x-small":"","color":"green","dark":""}},[_c('v-icon',[_vm._v("mdi-clock-outline")]),_vm._v(" Early access")],1):_vm._e()]}},{key:"item.imported",fn:function(ref){
var item = ref.item;
return [(item.stateId==3 )?_c('v-icon',[_vm._v("mdi-check-circle")]):_vm._e(),(item.stateId==2 )?_c('v-icon',[_vm._v("mdi-progress-helper")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.stateId!=3 && !_vm.isImportable(item))?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(item.stateId!=3 && _vm.isImportable(item))?_c('v-btn',{attrs:{"icon":"","to":{name: 'import-publication', params: {id: item.id, backTo: {name: 'publications'}}}}},[_c('v-icon',[_vm._v("mdi-import")])],1):_vm._e(),(item.stateId==3 && item.publicationId > 0)?_c('v-btn',{attrs:{"icon":"","to":{name: 'publication-detail', params: {id: item.publicationId}}}},[_c('v-icon',[_vm._v("mdi-book-open-blank-variant")])],1):_vm._e()]}}],null,false,540552411)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }