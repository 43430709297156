import { defineStore } from "pinia";
import OpenAccessService from "../services/open-access-service";

export const openAccessStore = defineStore("openAccessStore", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
		},
    }),
    getters: {
        getItems() {
            return this.items
        }
    },
    actions: {
        async fetch(params) {
            let service = new OpenAccessService();
            const results = await service.fetchAll(params);
            this.items = results.data.data;
        }
    }
})