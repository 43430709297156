import { axiosInst } from "../main";

export default class CepService {
    
    async fetchAll( params )  {
        const response = await axiosInst.get('/lists/ceps', {
            params: params
        })
        return response
    }
}