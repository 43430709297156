<template>
	<div>
		<v-alert dense text type="info">$t('messages.other-type-info')</v-alert>
		<v-row>
			<v-col cols="12">
				<v-textarea v-model="publication.appendix"
					:label="$t('publications.general.other')"
					hint=""
					></v-textarea>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'OtherForm',
	props: {
		defaults: {type: Object},
		errors: Array,
	},
	data: () => ({
		formTypes: [],
		publication: {},
		list: listsStore(),
	}),
	mounted: async function() {
		await this.loadData();
		//this.publication = this.defaults
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				this.publication = data
			}
		},
		getData: function() {
			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;