import { defineStore } from "pinia";
import FacultyService from "../services/faculty-service";

export const useFacultyStore = defineStore("facultyStore", {
    state: () => ({
        faculties: [],
        searchQuery: {
			q: null,
		},
        authorDepartments: {},
        facultyStats: {},
    }),
    getters: {
        getItems() {
            return this.items
        },
        getDepartments: (state) => {
            return (uic) => state.authorDepartments[uic]
        }
    },
    actions: {
        clearList() {
            this.departments = [];
        },
        async get(id, params, loadingCallBack) {
            let service = new FacultyService();
            const result = await service.get(id, params, loadingCallBack)

            return result.data;
        },
        async fetchAll(params, loadingCallBack) {
            let service = new FacultyService();
            const result = await service.fetchAll(params, loadingCallBack)
            this.faculties = result.data.data;

            return this.faculties;
        },
        async fetchPubStats(id, params, loadingCallBack ) {
            let service = new FacultyService();
            const results = await service.fetchPublicationStatistic(id, params, loadingCallBack);
            return results.data.data;
        },
        async fetchDepartmentStats(id, params, loadingCallBack ) {
            let service = new FacultyService();
            const results = await service.fetchDepartmentStatistic(id, params, loadingCallBack);
            return results.data.data;
        },
        async fetchAuthorStats(id, params, loadingCallBack ) {
            let service = new FacultyService();
            const results = await service.fetchAuthorStatistic(id, params, loadingCallBack);
            return results.data.data;
        },
        async downloadAuthorStats(id, params, loadingCallBack ) {
            let service = new FacultyService();
            const results = await service.downloadAuthorStats(id, params, loadingCallBack);
        },
    }
})