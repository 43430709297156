<template>
	<div>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="levelOfSecrecyId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.levelOfSecrecyId"
					:items="list.levelOfSecrecy"
					:label="$t('publications.general.level-of-secrecy')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="researchReportTypeId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.researchReportTypeId"
					:items="list.researchReport"
					:label="$t('publications.research.type')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-text-field v-model="publication.isbn"
					:label="$t('publications.research.isbn-or-ismn')"
					hint=""
					></v-text-field>
			</v-col>
			<v-col cols="2">
				<validation-provider vid="noOfPages" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.noOfPages"
					:label="$t('publications.general.no-of-pages')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<validation-provider vid="placeOfIssue" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.placeOfIssue"
					:label="$t('publications.general.place-of-issue')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publication.editionName"
					:label="$t('publications.research.version')"
					hint=""
					></v-text-field>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="publisherName" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.publisherName"
					:label="$t('publications.research.customer')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'ResearchForm',
	props: {
		defaults: {type: Object, default: () => {return {q: '1'}}},
		errors: Array,
	},
	data: () => ({
		formTypes: [],
		publication: {},
		list: listsStore(),
		menu: false
	}),
	mounted: async function() {
		await this.loadData();
		//this.publication = this.defaults
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				this.publication = data
			}
		},
		getData: function() {
			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;