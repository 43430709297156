import { axiosInst } from "../main";
import BaseService from "./base-service";

export default class ExternalPersonService extends BaseService {
    
    async fetchAll( params, loadingCallback )  {
        const response = await axiosInst.get('/external-authors', {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    
    async create( params, data )  {
        let path = `/external-authors`
        try {
            const response = await axiosInst.post( path, data, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }
}