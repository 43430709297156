<template>
	<v-card class="pa-4 ma-4">
		<v-card-title>
			Časopisy
		</v-card-title>

		<v-toolbar dense class="elevation-0 mt-2">
			<v-row>
				<v-col>
					<v-text-field
						v-model="query.q"
						append-icon="mdi-magnify"
						label="Název/ISSN/ISBN"
					></v-text-field>
				</v-col>
				<v-col>
					<v-select
						v-model="query.year"
						:items="years"
						label="Rok"
					></v-select>
				</v-col>
				<v-col>
					<v-select
						:items="journalPublishers.getItemsForSelect"
						v-model="query.publisher"
						clearable 
						label="Vydavatel"
					></v-select>
				</v-col>
				<v-col>
					<v-btn small v-on:click="load()">Vyhledat</v-btn>
				</v-col>
				<v-col>
					<v-btn small v-on:click="reset()">Reset</v-btn>
				</v-col>
			</v-row>
		</v-toolbar>

		<v-card-text>
			<v-data-table :headers="headers" :items="journals.items" :items-per-page="15">
			
				<template v-slot:item.actions="{item}">
					<v-btn icon :to="{name: 'journal-detail', params: {id: item.id}}" ><v-icon>mdi-pencil</v-icon></v-btn>
				</template>
				<template v-slot:item.title="{item}">
					{{item.title}}

					<v-chip v-if="item.wosSource" small>WOS</v-chip>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script> 
import { axiosInst } from '../main'
import { useJournalStore } from '../stores/journal-store'
import { publishersStore } from '../stores/journal-publisher-store'

export default {
	name: 'JournalSettings',
	props: {
		panelTitle: String,
	},
	data: () => ({
		headers: [
			{text: "#", value: 'id'},
			{text: "Název", value: 'title'},
			{text: "ISBN", value: 'isbn'},
			{text: "Vydavatel", value: 'publisherName'},
			{text: "Akce", value: 'actions', sortable: false},
		],
		journals: useJournalStore(),
		journalPublishers: publishersStore(),
		years: [ 2019, 2020, 2021 ],
		search: '',
		query: useJournalStore().searchQuery,
	}),
	mounted: function() {
		this.query.year = 2021
	//	this.load()
	},
	methods: {
		load: function() {
			this.journals.fetch(this.query)
		},
		reset: function() {
			this.journals.searchQuery.q = ""
			this.journals.searchQuery.publisher = 0
			this.journals.searchQuery.year = 2021
		}
	}
}

</script>