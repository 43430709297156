<template>
	<div>
		<journal-picker @input="journalPicked" :value="journal.id" 
			:readonly="editDisabled"
			:value-text="journal.title" :errors="errors"
			></journal-picker>
		<v-row>
			<v-col cols="4">
				<validation-provider vid="articleSubtypeId" v-slot="{errors}" rules="required">
					<v-select v-model="publication.articleSubtypeId"
						:readonly="editDisabled"
						:items="list.articleSubtype"
						:label="$t('publications.article.sub-type')"
						item-value="value"
						item-text="text"
						:error-messages="errors"
						required
						></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="volume" v-slot="{errors}" rules="required">
					<v-text-field v-model="publication.volume"
						:readonly="editDisabled"
						:label="$t('publications.article.volume')"
						:disabled="!hasJournal"
						required
						:error-messages="errors"
						:hint="$t('help.hints.volume')"
						></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4"> 
				<validation-provider vid="issue" v-slot="{errors}" rules="required">
					<v-text-field v-model="publication.issue"
						:readonly="editDisabled"
						:label="$t('publications.article.issue')"
						:disabled="!hasJournal"
						required
						:error-messages="errors"
						:hint="$t('help.hints.issue')"
						></v-text-field>
					</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<validation-provider vid="pageFrom" v-slot="{errors}" rules="required">
					<v-text-field v-model="publication.pageFrom"
						:readonly="editDisabled"
						:label="$t('publications.general.page-from')"
						:disabled="!hasJournal"
						:error-messages="errors"
						:hint="$t('help.hints.no-of-copies')"
						></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="pageTo" v-slot="{errors}" rules="required">
					<v-text-field v-model="publication.pageTo"
						:readonly="editDisabled"
						:label="$t('publications.general.page-to')"
						:disabled="!hasJournal"
						:error-messages="errors"
						:hint="$t('help.hint.page-to')"
						></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="noOfPages" v-slot="{errors}" rules="required">
					<v-text-field v-model="publication.noOfPages"
						:readonly="editDisabled"
						:label="$t('publications.general.no-of-pages')"
						:disabled="!hasJournal"
						:error-messages="errors"
						:hint="$t('help.hints.no-of-pages')"
						></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<validation-provider vid="eISSN" v-slot="{errors}" rules="required">
					<v-text-field v-model="publication.eissn" 
						:readonly="editDisabled"
						:label="$t('publications.eissn')"
						:disabled="!hasJournal"
						maxlength="15"
						:error-messages="errors"
						:hint="$t('help.hints.eissn')"
						></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="kodWOS" v-slot="{errors}">
					<v-text-field v-model="publication.kodWOS"
						:readonly="editDisabled"
						:label="$t('publications.general.wos')"
						:disabled="!hasJournal"
						:error-messages="errors"
						></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="eid" v-slot="{errors}" >
				<v-text-field v-model="publication.eid"
					:readonly="editDisabled"
					:label="$t('publications.general.eid')"
					:disabled="!hasJournal"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';
import JournalPicker from '../parts/JournalPicker.vue';

export default {
	name: 'JournalArticleForm',
	props: {
//		defaults: {type: Object}},
		errors: Array,
		errorMessages: Array,
	},
    components: {
    	JournalPicker
	},
	data: () => ({
		publication: {
			journalId: -1,
			articleSubtypeId: 0,
			eissn: ""
		},
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
	}),
	mounted: async function() {
		await this.loadData();
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				console.log("sub", data)
				this.publication = data
				if(this.publication.journalId > 0) {
					this.hasJournal = true;
					this.journal = {
						title: this.publication.journal,
						id: this.publication.journalId,
					}
				}
				this.editDisabled = (this.publication.publicationStateId && this.publication.publicationStateId != 1)
			} 
		},
		getData: function() {
			return this.publication;
		},
		isValid: function() {
			return false;
		},
		journalPicked: function(data) {
			if(data) {
				console.log(data)
				this.publication.journalId = data.id
				this.journal = data
				this.hasJournal = true
				this.editDisabled = false

				this.$nextTick( () => {
					if( this.journal.issn != "") {
						this.publication.eissn = this.journal.issn
					}
					
					//@fixme
					if(this.publication.articleSubtypeId==3) {

					} else {
						if(this.journal.wosSource) {
							console.log("setting issn", this.journal.issn, this.publication)
							this.publication.articleSubtypeId = 1;
							console.log("setting issn", this.journal.issn, this.publication)
						} else if(this.journal.scopusSource) {
							this.publication.articleSubtypeId = 2;
						}
					}
				})
			}
		}
	},
}

</script>;