import { axiosInst } from "../main";
import BaseService from "./base-service";

export default class GrantService extends BaseService {
    
    async fetchAll( params, callback )  {
        const response = await axiosInst.get('/grants', {
            params: params
        })

        this.handleCallback(callback)
        return response
    }

    async get( id )  {
        let path = (id) => `/grants/${id}`
        const response = await axiosInst.get( path(id), {})
        return response
    }
}