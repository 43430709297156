var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"patentNo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.patent.number'),"hint":"","error-messages":errors},model:{value:(_vm.publication.patentNo),callback:function ($$v) {_vm.$set(_vm.publication, "patentNo", $$v)},expression:"publication.patentNo"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"patentPublisherId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.patentPublisher,"label":_vm.$t('publications.patent.publisher'),"error-messages":errors,"required":""},model:{value:(_vm.publication.patentPublisherId),callback:function ($$v) {_vm.$set(_vm.publication, "patentPublisherId", $$v)},expression:"publication.patentPublisherId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"ownerName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.owner-name'),"hint":"","error-messages":errors},model:{value:(_vm.publication.ownerName),callback:function ($$v) {_vm.$set(_vm.publication, "ownerName", $$v)},expression:"publication.ownerName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"$t('publications.patent.date-assigned')","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(_vm._s(_vm.$t('btn.close')))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(_vm._s(_vm.$t('btn.insert')))])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('publications.patent.is-realized')},model:{value:(_vm.publication.realized),callback:function ($$v) {_vm.$set(_vm.publication, "realized", $$v)},expression:"publication.realized"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"licenseObligationId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.licenceObligation,"label":_vm.$t('publications.general.license-obligation'),"error-messages":errors,"required":""},model:{value:(_vm.publication.licenseObligationId),callback:function ($$v) {_vm.$set(_vm.publication, "licenseObligationId", $$v)},expression:"publication.licenseObligationId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.list.licenseFee,"label":_vm.$t('publications.general.license-fee'),"required":""},model:{value:(_vm.publication.licenceFeeId),callback:function ($$v) {_vm.$set(_vm.publication, "licenceFeeId", $$v)},expression:"publication.licenceFeeId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"typeOfUseId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.patentUseType,"label":_vm.$t('publications.patent.type-of-use'),"error-messages":errors,"required":""},model:{value:(_vm.publication.typeOfUseId),callback:function ($$v) {_vm.$set(_vm.publication, "typeOfUseId", $$v)},expression:"publication.typeOfUseId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"useByOthersId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.getOptions(5),"label":_vm.$t('publications.patent.type-of-use-by-others'),"error-messages":errors,"required":""},model:{value:(_vm.publication.useByOthersId),callback:function ($$v) {_vm.$set(_vm.publication, "useByOthersId", $$v)},expression:"publication.useByOthersId"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }