<template>
	<div>
		<v-row>
			<v-col cols="5">
				<v-text-field v-model="publicationSubtype.internalId" readonly label="Interní identifikátor"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.resultLocalisation" readonly label="Číselná identifikace"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="5">
				<v-text-field v-model="publicationSubtype.ownerName" readonly label="Název vlastníka"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.ownerId" readonly label="IČ vlastníka"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.country" readonly label="Stát vlastníka"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-textarea v-model="publicationSubtype.technicalParams" readonly label="Technické parametry"></v-textarea>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-textarea v-model="publicationSubtype.economicalParams" readonly label="Ekonomické parametry"></v-textarea>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.licenseObligation" readonly label="Povinnost získání licence"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.licenceFee" readonly label="Licenční poplatek"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.typeOfUse" readonly label="Druh možnosti využití výsledku"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';

export default {
	name: 'SoftwareDetail',
	props: {
		publication: Object,
	},
    components: {
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		publicationSubtype: {},
		levelOfSecrecy: {},
		researchReport: {},
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.publicationSubtype = this.publication.software

		}
	},
}

</script>;