<template>
	<v-container fluid>
		<v-card-title>{{panelTitle}}</v-card-title>
		<v-toolbar dense flat>
			<v-spacer></v-spacer>
			<reload-button-component class="mx-2" v-on:reload="load()" />
		</v-toolbar>

		<v-divider></v-divider>

		<v-data-table :headers="headers" :items="publicationStore.publicationDrafts" :items-per-page="15">
			<template v-slot:item.actions="{item}">
				<v-btn icon @click="removeDraft(item)"><v-icon class="mr-2">mdi-trash-can</v-icon></v-btn>
				<v-btn icon :to="{name: 'create-new-publication', params: {isDraft: true, draftEntry: item.token}}"><v-icon class="mr-2">mdi-arrow-right</v-icon></v-btn>
			</template>
			<template v-slot:item.state="{item}">
				<v-chip small>Draft</v-chip>
			</template>
			<template v-slot:item.type="{item}">
				<v-chip small>{{ item.type }}</v-chip>
			</template>
		</v-data-table>
		<confirm-dialog ref="confirm"></confirm-dialog>
	</v-container>
</template>

<script> 
import {axiosInst} from '../main'
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import PublicationState from '@/components/parts/PublicationState.vue'
import { publicationStore } from '../stores/publication-store'
import { authStore } from '../stores/user'
import { listsStore } from '../stores/lists-store'
import ConfirmDialog from './dialogs/ConfirmDialog.vue'
import { useNotificationStore } from '../stores/notification-store'

export default {
	components: { ReloadButtonComponent, PublicationState, ConfirmDialog },
	name: 'PublicationDraftList',
	props: {
		panelTitle: {
			type: String,
			default: "Rozpracované publikace"
		}
	},
	data: () => ({
		uic: 0,
		headers: [
			{text: "#", value: 'id'},
			{text: "Název", value: 'title'},
			{text: "Stav", value: 'state'},
			{text: "Typ", value: 'type'},
			{text: "Akce", value: 'actions', width: "120px", sortable: false},
		],
		query: {
			year: 2023,
			publicationStateId: -1
		},
		publications: [],
		publicationStore: publicationStore(),
		userStore: authStore(),
		listsStore: listsStore(),
		queryKey: "user-publication-drafts",
		linkBackTo: {name: "publications"},
		notificationManager: useNotificationStore()
	}),
	mounted: async function() {
		await this.listsStore.fetch();

		this.uic = this.$root.getCurrentUser()

		this.$nextTick( () => {
			this.search();
		});
	},
	methods: {
		load: function() {
			this.publicationStore.fetchDrafts( {uic: this.userStore.uic} )
		},
		search: function() {
			this.publicationStore.fetchDrafts({})
		},
		removeDraft: function(item) {
			this.$refs.confirm.open("Potvrzení smazání pracovní verze publikace", `Opravdu chcete smazat pracovní verzi publikace: ${item.title}?`, {})
			.then( (c) => {
				this.publicationStore.removeDraft(item.token)
				.then( (r) => {
					this.load();
					this.notificationManager.addSuccess({message: `Pracovní verze publikace odebrána.`})
				})
			} );
		}
	},
}

</script>;