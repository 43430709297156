<template>
    <v-dialog
        :value="dialogOpen"
        fullscreen
        scrollable
        hide-overlay
        transition="dialog-bottom-transition"
        >
        <v-card tile>
            <v-toolbar dark flat color="primary" class="flex-grow-0">
                <v-toolbar-title>{{panelTitle}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form @submit.prevent="search()">
                <v-row class="mt-2">
                    <v-col cols="8">
                        <v-text-field v-model="name" label="Název časopisu/ISSN/ISBN/Publisher" 
                        @click:clear="clear()"
                        ref="searchInput"
                        autofocus
			            clearable 
                        :disabled="isLoading"
                        :loading="isLoading"
                        required></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn icon @click="search()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </v-col>
                </v-row>
                </v-form>

                <v-expand-transition  v-if="journalStore.items.length > 0">
                    <div>
                    <v-divider></v-divider>
                    <v-simple-table fixed-header>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>{{ $t('journals.name') }}</th>
                                    <th>{{ $t('journals.publisher') }}</th>
                                    <th>{{ $t('journals.issn') }}</th>
                                    <th>{{ $t('journals.isbn') }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in journalStore.items">
                                    <td>{{item.title}}</td>
                                    <td>{{item.publisherName}}</td>
                                    <td>{{item.issn}}</td>
                                    <td>{{item.isbn}}</td>
                                    <td>
                                        <v-btn @click="pick(item)" small icon><v-icon>mdi-arrow-right</v-icon></v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    </div>
                </v-expand-transition>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="close">{{ $t('btn.close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { useJournalStore } from '../../stores/journal-store'


export default {
    name: 'JournalSearchModal',
    props: {
        panelTitle: {
            type: String,
            default: "Vyhledat časopis",
        },
        preselectedUIC: Number,
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        journalStore: useJournalStore(),
        isLoading: false
    }),
    mounted: function() {
    },
    methods: {
        search: function() {
            console.log("search initiaited");
            let search = this.name

            if(search.length >= 3) {
                this.isLoading = true
                this.journalStore.fetch({q: search}, () => {this.isLoading=false})
            }
        },
        open: function(props) {
            this.dialogOpen = true

            if(!props) {return;}

            if(props.search != "") {
                this.name = props.search
            }

            if(props.autoSearch) {
                this.$nextTick( () => {this.search()})
            }
        }, 
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
        pick: function(item) {
            this.close(item)
        },
        clear: function() {
            this.name = "";
            this.journalStore.clearList()
        }
    }

}
</script>
