import { defineStore } from "pinia";
import ExternalPersonService from "../services/external-person-service";

export const useExternalPersonStore = defineStore("externalPersonStore", {
    state: () => ({
        persons: [],
        searchQuery: {
			q: null,
		},
        favorites: [],
        recent: []
    }),
    getters: {
        getItems() {
            return this.items
        }
    },
    actions: {
        clearList() {
            this.persons = [];
        },
        async create(params, data) {
            let service = new ExternalPersonService();
            try {
                const results = await service.create(params, data)
                this.items = results.data.data;
                return Promise.resolve(results)
            } catch( err ) {
                throw err
            }
        },
        async fetch(params, loadingCallBack ) {
            let service = new ExternalPersonService();
            const results = await service.fetchAll(params, loadingCallBack);
            this.persons = results.data.data;
        }
    }
})