<template>
	<div>
		<v-row>
			<v-col cols="8">
				<v-text-field v-model="journal.title" readonly label="Časopis"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="articleSubtype" readonly label="Poddruh výsledku druhu"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3">
				<v-text-field v-model="article.volume" readonly label="Svazek periodika"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="article.issue" readonly label="Číslo periodika"></v-text-field>
			</v-col>
			<v-col cols="2"> 
				<v-text-field v-model="article.pageFrom" readonly label="Strana od"></v-text-field>
			</v-col>
			<v-col cols="2"> 
				<v-text-field v-model="article.pageTo" readonly label="Strana do"></v-text-field>
			</v-col>
			<v-col cols="2">
				<v-text-field v-model="article.noOfPages" readonly label="Počet stran"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<v-text-field v-model="article.eissn" readonly label="eISSN"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="article.kodWOS" readonly label="Kód WOS"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="article.eid" readonly label="EID Vysledku"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';
import JournalPicker from '@/components/parts/JournalPicker.vue';

export default {
	name: 'JournalArticleDetail',
	props: {
		publication: Object,
	},
    components: {
		JournalPicker
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		article: {},
		articleSubtype: "",
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.article = this.publication.article

			this.journal = {
				title: this.article.journalLabel,
				id: this.article.journalId,
			}

			let subtype = this.list.getListValue("articleSubtype", this.article.articleSubtypeId)
			if(subtype) {
				console.log(subtype, this.article.articleSubtypeId)
				this.articleSubtype = subtype.text
			}
		}
	},
}

</script>;