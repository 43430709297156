var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('author-picker',{model:{value:(_vm.pickerData),callback:function ($$v) {_vm.pickerData=$$v},expression:"pickerData"}})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.publicationStore.publicationDrafts,"items-per-page":15,"show-expand":"","single-expand":""},on:{"item-expanded":_vm.itemExpanded},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v("Draft")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.type))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('publication-view',{attrs:{"approval-enabled":_vm.viewApprovalEnabled,"publication":_vm.publicationDraftView}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }