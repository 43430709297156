<template>
	<div>
        <v-chip class="mt-1" small v-for="aff in author.affiliations" :key="aff.id">{{ getName(aff) }}</v-chip>
    </div>
</template>

<script>
import { useDepartmentStore } from '../../stores/department-store';

export default {
    name: "WormAuthorAffiliation",
    props: {
        author: Object
    },
    data: function () {
        return {
            color: "",
            icon: "",
        };
    },
	mounted: function() {
        //this.label = label;
	},
    methods: {
        getName: function(aff) {
            return aff.name;
        }
    }
}
</script>