import { axiosInst } from "../main";

export default class AuthService {

    async authenticate(username, password) {
        console.log("Authenticating")
        let payload = {username: username, password: password}

        return await axiosInst.post('/auth/login', payload, {
                validateStatus: function(status) {
                    return status == 200;
                }
            })
            .then( (response) => {
                console.log("auth response", response.data)
                return response;
            } )
            .catch( (err) => {
                console.log("auth error", err.response)
                return false;
            })
    }

    async verify(token)  {

        let config = {}
        if(token != null) {
            config = {
                headers: {
                    "Authorization": "Bearer " + token
                }
            }
        }

        return await axiosInst.get('/auth/verify', config)
            .then( (response) => {
                console.log("Verify response", response)
                return response.data;
            } )
            .catch( (err) => {
                console.log("Verify error", err.response)
                return false;
            })
    }
}