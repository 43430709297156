<template>
	<v-card class="ma-4">
		<v-toolbar flat color="primary" dark>
			<v-btn icon :to="backTo"><v-icon>mdi-arrow-left</v-icon></v-btn>
			<v-toolbar-title>{{ $t('views.publication-detail') }}: {{ publication.name }}</v-toolbar-title>
			<v-spacer></v-spacer>

			<v-btn icon @click="reload(publication)"><v-icon class="mr-2">mdi-reload</v-icon></v-btn>
			<v-btn icon v-if="editEnabled" :to="{name: 'edit-publication', params: {id: publication.id, backTo: {name: 'publication-detail', params: {id: publication.id}}}}">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
			<v-btn icon v-if="editEnabled" @click="removePublication(publication)"><v-icon class="mr-2">mdi-trash-can</v-icon></v-btn>
			<v-menu bottom left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
				</template>
				<v-list>
					<v-list-item link @click="downloadCitation('text')">
						<v-list-item-title>{{ $t('labels.citation-in-txt') }}</v-list-item-title>
					</v-list-item>
					<v-list-item link @click="downloadCitation('docx')">
						<v-list-item-title>{{ $t('label.citation-in-word') }}</v-list-item-title>
					</v-list-item>
					<v-list-item link @click="viewRivXML()">
						<v-list-item-title>RIV XML</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

			<template v-slot:extension>
				<v-tabs v-model="tabs" align-with-title>
					<v-tabs-slider color="yellow"></v-tabs-slider>
					<v-tab>{{ $t('tabs.overview') }}</v-tab>
					<v-tab>{{ $t('tabs.detail') }}</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>
		<v-card-text>
			<v-tabs-items v-model="tabs">
				<v-tab-item>
					<publication-detail-short :publication="publication"></publication-detail-short>
				</v-tab-item>
				<v-tab-item>
					<publication-detail-full :publication="publication"></publication-detail-full>
				</v-tab-item>
			</v-tabs-items>
		</v-card-text>
		<RivXMLViewModal ref="rivxml"></RivXMLViewModal>
		<confirm-dialog ref="confirm"></confirm-dialog>
	</v-card>
</template>

<script>
import { publicationStore } from '../stores/publication-store';
import AuthorDepartment from './parts/AuthorDepartment.vue';
import PublicationDetailShort from './parts/PublicationDetailShort.vue';
import PublicationDetailFull from './parts/PublicationDetailFull.vue';
import PublicationService from '../services/publication-service';
import { authStore } from '../stores/user';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import emitter from '@/plugins/mitt';
import RivXMLViewModal from '@/components/dialogs/RivXMLViewModal.vue'; 

export default {
    name: "PublicationDetail",
    props: {
        publicationId: [Number, String],
		backTo: {
			type: Object,
			required: false,
			default: () => {return {name: "user-publications"}}
		}
    },
    data: () => ({
        publicationStore: publicationStore(),
		publicationService: new PublicationService(),
		userStore: authStore(),
		publication: {},
		editEnabled: true,
		tabs: null,
    }),
    mounted: function () {
		this.loadPublication();

		emitter.on("publication.state.changed", f => {
            console.log("publication state change event received", f);
            if(this.publicationId == f.publicationId) {
				this.loadPublication();
			}
        })
    },
	watch: {
		publicationId: function(nVal, oVal) {
			if(nVal != oVal) {
				this.loadPublication();
			}
		}
	},
    methods: {
        approveAs: function (type) {
            let ev = [this.publication, type];
            this.$emit("changeState", ev);
        },
		reload: function() {
			this.loadPublication();
		},
		loadPublication: function() {
			let get = this.publicationStore.get(this.publicationId, {})
			get.then( (r) => {
				console.log("ret", r.data)
				this.publication = r.data
				this.editEnabled = (this.publication.flags.editable || this.userStore.hasRole(['ADMINISTRATOR']) );
			})
			console.log("loaded pub", this.publication)
		},
		downloadCitation: function(format) {
			this.publicationService.downloadCitation(this.publication.id, format);
		},
		viewRivXML: function() {
			this.$refs.rivxml.open( this.publication.id );
		},
		removePublication: function(item) {
			this.$refs.confirm.open(this.$t('view.delete-confirmation'), this.$t('messages.publication-delete-confirmation', [item.name]), {})
			.then( (c) => {
				this.publicationService.remove(item.id, {})
				.then( (r) => {
					this.load();
					this.notificationManager.addSuccess({message: this.$t('messages.publication-deleted', [item.name])})
				})
			} );
		}
    },
    components: { AuthorDepartment, PublicationDetailShort, PublicationDetailFull, ConfirmDialog, RivXMLViewModal }
}
</script>