<template>
	<v-card flat class="pa-2 ma-2">
		<v-card-title>
			{{ $t('view.publication-detail') }}
			<v-spacer></v-spacer>
			<v-btn class="mr-2" v-if="approvalEnabled && !hasTag(1)" color="orange" @click="setTag('checked')">Označit jako zkontrolovaný záznam</v-btn>
			<v-btn class="mr-2" v-if="approvalEnabled && publication.publicationStateId <= 1" color="primary" @click="approveAs(2)">Schválit jako vedoucí katedry</v-btn>
			<v-btn class="mr-2" v-if="approvalEnabled && authStore.hasRole(['ADMINISTRATOR']) && publication.publicationStateId <= 2" color="primary" @click="approveAs(3)">Schválit jako proděkan</v-btn>
			<v-btn class="mr-2" v-if="approvalEnabled && publication.publicationStateId > 1" color="warning" @click="revokeApproval(1)">Odschválit publikaci</v-btn>
			<v-btn class="mr-2" v-if="approvalEnabled && publication.publicationStateId < 3" color="error" @click="approveAs(4)">Odmítnout publikaci</v-btn>
			<v-btn class="mr-2" v-if="approvalEnabled" color="primary" target="_blank" :to="{name: 'publication-detail', params: {id: publication.id}}">Upravit publikaci</v-btn>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="8">
					<v-text-field :label="$t('publications.name-original')" v-model="publication.nameEn" readonly></v-text-field>
					<v-text-field :label="$t('publications.name')" v-model="publication.name" readonly></v-text-field>
					<v-textarea :label="$t('publications.annotation')" v-model="publication.annotation" readonly rows="2" row-height="20"></v-textarea>
					<v-textarea :label="$t('publications.annotation')" v-model="publication.annotationEn" readonly rows="2" row-height="20"></v-textarea>
					<v-text-field :label="$t('publications.keywords')" v-model="publication.keywords" readonly></v-text-field>
					<v-text-field :label="$t('publications.web')" v-model="publication.web" readonly append-outer-icon="mdi-web" @click:append-outer="openLink(publication.web)"></v-text-field>
				</v-col>
				<v-col>
					<v-text-field :label="$t('publications.year')" v-model="publication.yearOfPublication" readonly></v-text-field>
					<v-text-field :label="$t('publications.type')" v-model="publication.publicationType" readonly></v-text-field>
					<v-text-field :label="$t('publications.ford')" v-model="publication.ford" readonly></v-text-field>
					<v-text-field :label="$t('publications.open-access-type')" v-model="publication.openAccessType" readonly></v-text-field>
					<v-text-field :label="$t('publications.level-of-secrecy')" v-model="publication.levelOfSecrecy" readonly></v-text-field>
					<v-text-field :label="$t('publications.language')" v-model="publication.language" readonly></v-text-field>
					<v-text-field :label="$t('publications.doi')" v-model="publication.doi" readonly></v-text-field>
				</v-col>
			</v-row>
			<v-divider></v-divider>

			<!-- details-->
			<v-card-subtitle>{{ $t('view.publication-specific-data') }}</v-card-subtitle>
			<journal-article-detail v-if="publication.publicationTypeId==1" :publication="publication"></journal-article-detail>
			<book-detail v-if="publication.publicationTypeId==2" :publication="publication"></book-detail>
			<book-chapter-detail v-if="publication.publicationTypeId==3" :publication="publication"></book-chapter-detail>
			<proceedings-detail v-if="publication.publicationTypeId==4" :publication="publication"></proceedings-detail>
			<patent-detail v-if="publication.publicationTypeId==5" :publication="publication"></patent-detail>
			<operation-detail  v-if="publication.publicationTypeId==6" :publication="publication"></operation-detail>

			<prototype-detail v-if="publication.publicationTypeId==8" :publication="publication"></prototype-detail>
			<methodology-detail v-if="publication.publicationTypeId==9" :publication="publication"></methodology-detail>
			<software-detail v-if="publication.publicationTypeId==10" :publication="publication"></software-detail>
			<research-detail v-if="publication.publicationTypeId==11" :publication="publication"></research-detail>
			<presentation-detail v-if="publication.publicationTypeId==12" :publication="publication"></presentation-detail>

			<art-detail v-if="publication.publicationTypeId==15" :publication="publication"></art-detail>
			<other-detail  v-if="publication.publicationTypeId==14" :publication="publication"></other-detail>

			<v-divider></v-divider>
			<v-card-subtitle>{{ $t('publication.authors.label') }}</v-card-subtitle>
			<v-alert v-if="(publication.authors && publication.authors.length == 0)" type="info" outlined dense>Nejsou přiřazeny žádní autoři</v-alert>
			<v-simple-table v-if="(publication.authors && publication.authors.length > 0 )">
				<template v-slot:default>
					<thead>
						<tr>
							<th>{{ $t('general.order') }}</th>
							<th>{{ $t('general.author') }}</th>
							<th></th>
							<th>{{ $t('publications.type') }}</th>
							<th>{{ $t('publication.authors.reprint') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="author in publication.authors" :key="author.id">
							<td>{{(author.priority+1)}}</td>
							<td>{{author.displayName}}</td>
							<td>
								<v-chip v-if="author.type==1">{{ author.baseUnitAbbr }}</v-chip>
							</td>
							<td>
								<v-chip v-if="(author.type == 1)">{{ $t('publications.authors.internal') }}</v-chip>
								<v-chip v-if="(author.type == 2)">{{ $t('publications.authors.external') }}</v-chip>
							</td>
							<td>
								<v-icon v-if="author.correspondenceAuthor">mdi-check</v-icon>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
			<v-divider></v-divider>
			<v-card-subtitle>{{ $t('publications.grants') }}</v-card-subtitle>
			<v-alert v-if="(publication.grants && publication.grants.length == 0)" type="info" outlined dense>{{ $t('messages.no-grants-assigned') }}</v-alert>
			<v-simple-table v-if="(publication.grants && publication.grants.length > 0)">
				<template v-slot:default>
					<thead>
						<tr>
							<th>{{ $t('grants.name') }}</th>
							<th>{{ $t('grants.internal-number') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="grant in publication.grants">
							<td>{{grant.name}}</td>
							<td>{{grant.internalNumber}}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
		<v-card-title>
			<v-btn class="mr-2" v-if="approvalEnabled && !hasTag(1)" color="orange" @click="setTag('checked')">Označit jako zkontrolovaný záznam</v-btn>
			<v-btn class="mr-2" v-if="approvalEnabled && publication.publicationStateId <= 1" color="primary" @click="approveAs(2)">Schválit jako vedoucí katedry</v-btn>
			<v-btn class="mr-2" v-if="approvalEnabled && authStore.hasRole(['ADMINISTRATOR']) && publication.publicationStateId <= 2" color="primary" @click="approveAs(3)">Schválit jako proděkan</v-btn>
			<v-btn class="mr-2" v-if="approvalEnabled && publication.publicationStateId > 1" color="warning" @click="revokeApproval(1)">Odschválit publikaci</v-btn>
			<v-btn class="mr-2" v-if="approvalEnabled && publication.publicationStateId < 3" color="error" @click="approveAs(4)">Odmítnout publikaci</v-btn>
			<v-btn class="mr-2" v-if="approvalEnabled" color="primary" target="_blank" :to="{name: 'publication-detail', params: {id: publication.id}}">Upravit publikaci</v-btn>
		</v-card-title>
		<PublicationEditModal rel="pubForm"></PublicationEditModal>
		<confirm-dialog ref="confirm"></confirm-dialog>
	</v-card>
</template>

<script>
import { publicationStore } from '@/stores/publication-store';
import AuthorDepartment from './parts/AuthorDepartment.vue';

import JournalArticleDetail from '@/components/publicationDetails/JournalArticleDetail.vue';
import ResearchDetail from '@/components/publicationDetails/ResearchDetail.vue';
import ProceedingsDetail from '@/components/publicationDetails/ProceedingsDetail.vue';
import ArtDetail from '@/components/publicationDetails/ArtDetail.vue';
import BookDetail from '@/components/publicationDetails/BookDetail.vue';
import BookChapterDetail from '@/components/publicationDetails/BookChapterDetail.vue';
import MethodologyDetail from '@/components/publicationDetails/MethodologyDetail.vue';
import OperationDetail from '@/components/publicationDetails/OperationDetail.vue';
import OtherDetail from '@/components/publicationDetails/OtherDetail.vue';
import PatentDetail from '@/components/publicationDetails/PatentDetail.vue';
import PresentationDetail from '@/components/publicationDetails/PresentationDetail.vue';
import PrototypeDetail from '@/components/publicationDetails/PrototypeDetail.vue';
import SoftwareDetail from '@/components/publicationDetails/SoftwareDetail.vue';
import PublicationEditModal from './dialogs/PublicationEditModal.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import { authStore } from '../stores/user';

export default {
    name: "PublicationView",
    props: {
        publication: {
			type: Object,
			required: true,
		},
		approvalEnabled: {
			type: Boolean,
			required: false,
			default: true,
		}
    },
    data: () => ({
        publicationStore: publicationStore(),
		authStore: authStore(),
    }),
    mounted: function () {
    },
    methods: {
        approveAs: function (type) {
            let ev = [this.publication, type];
            this.$emit("changeState", ev);
        },
		revokeApproval: function(type) {
			this.$refs.confirm.open("Potvrzení odschválení publikace", "Opravdu zrušit schválení u vybrané publikace?", {})
			.then( (c) => {
				let ev = [this.publication, type];
            	this.$emit("changeState", ev);
			} );
		},
		setTag: function(tag) {
			this.publicationStore.assignTag( this.publication.id, tag )
		},
		unsetTag: function(tag) {
			this.publicationStore.unassignTag( this.publication.id, tag )
		},
		hasTag: function(code) {
			if( this.publication.tags && this.publication.tags.length > 0 ) {
				let q = this.publication.tags.find( f =>  f.tagId == code  )
				console.log("foooound", q)
				if(q) {
					return true;
				}
			}

			return false;
		},
		openEdit: function() {
			this.$refs.pubForm.open()
		},
		openLink: function(link) {
			window.open(link, "_blank");
		}

    },
    components: { 
		AuthorDepartment,
		ResearchDetail,
        ProceedingsDetail,
        ArtDetail,
        BookDetail,
        BookChapterDetail,
        MethodologyDetail,
        OperationDetail,
        OtherDetail,
        PatentDetail,
        PresentationDetail,
        PrototypeDetail,
        SoftwareDetail, 
		JournalArticleDetail,
		PublicationEditModal,
		ConfirmDialog
	}
}
</script>
