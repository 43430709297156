var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"publicationSubtypeId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.getOptions(8),"label":_vm.$t('publications.general.sub-type-result'),"error-messages":errors,"required":""},model:{value:(_vm.formData.publicationSubtypeId),callback:function ($$v) {_vm.$set(_vm.formData, "publicationSubtypeId", $$v)},expression:"formData.publicationSubtypeId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"scopeOfUseId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.scopeOfUse,"label":_vm.$t('publications.general.scope-of-use'),"required":"","error-messages":errors},model:{value:(_vm.formData.scopeOfUseId),callback:function ($$v) {_vm.$set(_vm.formData, "scopeOfUseId", $$v)},expression:"formData.scopeOfUseId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"internalId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publication.general.internal-identifikator'),"hint":"","error-messages":errors},model:{value:(_vm.formData.internalId),callback:function ($$v) {_vm.$set(_vm.formData, "internalId", $$v)},expression:"formData.internalId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"resultLocalisation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.methodology.result-number'),"hint":"","error-messages":errors},model:{value:(_vm.formData.resultLocalisation),callback:function ($$v) {_vm.$set(_vm.formData, "resultLocalisation", $$v)},expression:"formData.resultLocalisation"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"identification","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.methodology.identification'),"hint":"","error-messages":errors},model:{value:(_vm.formData.identification),callback:function ($$v) {_vm.$set(_vm.formData, "identification", $$v)},expression:"formData.identification"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"technicalParams","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('publications.general.technical-params'),"hint":"","error-messages":errors,"counter":"3000"},model:{value:(_vm.formData.technicalParams),callback:function ($$v) {_vm.$set(_vm.formData, "technicalParams", $$v)},expression:"formData.technicalParams"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"economicalParams","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('publications.general.economical-params'),"hint":"","counter":"1000","error-messages":errors},model:{value:(_vm.formData.economicalParams),callback:function ($$v) {_vm.$set(_vm.formData, "economicalParams", $$v)},expression:"formData.economicalParams"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"certifiedBy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.methodology.certified-by'),"hint":"","error-messages":errors},model:{value:(_vm.formData.certifiedBy),callback:function ($$v) {_vm.$set(_vm.formData, "certifiedBy", $$v)},expression:"formData.certifiedBy"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('publications.methodology.certified-on'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(_vm._s(_vm.$t('btn.close')))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(_vm._s(_vm.$t('btn.insert')))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }