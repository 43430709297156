import { defineStore } from "pinia";
import ArticleSubTypeService from "../services/article-subtype-service";

export const articleSubtypeStore = defineStore("articleSubtypeStore", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
		},
    }),
    getters: {
        getItems() {
            return this.items
        }
    },
    actions: {
        async fetch(params) {
            let service = new ArticleSubTypeService();
            const results = await service.fetchAll(params);
            this.items = results.data.data;
        }
    }
})