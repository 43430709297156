<template>
	<div>
		<v-row>
			<v-col cols="6">
				<v-text-field v-model="publicationSubtype.internalId" readonly label="Číselná identifikace"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.approvalAuthority" readonly label="Označení certifikačního / schvalujícího orgánu"></v-text-field>
			</v-col>
			<v-col cols="2">
				<v-text-field v-model="publicationSubtype.approvalDate" readonly label="Datum certifikace / schválení výsledku"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';

export default {
	name: 'PublicDatabaseDetail',
	props: {
		publication: Object,
	},
    components: {
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		publicationSubtype: {},
		levelOfSecrecy: {},
		researchReport: {},
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.publicationSubtype = this.publication.publicDatabase
		}
	},
}

</script>;