<template>
	<v-chip
		:close="editable"
		close-icon="mdi-pencil"
		@click:close="fieldClicked"
		>
		{{unitChipLabel}}
		<PersonDepartmentModal ref="departmentModal" :uic="author.uic" @close-dialog="modalClosed"></PersonDepartmentModal>
	</v-chip>
</template>

<script>
import { useDepartmentStore } from '../../stores/department-store';
import JournalSearchModal from '../dialogs/JournalSearchModal.vue';
import PersonDepartmentModal from '../dialogs/PersonDepartmentModal.vue';

export default {
    name: "AuthorDepartment",
    props: {
        errorMessages: Array,
        author: {},
		editable: Boolean,
    },
    data: function () {
        return {
            timeoutId: null,
            search: "",
            isLoading: false,
			unitChipLabel: "",
			departmentStore: useDepartmentStore(),
        };
    },
	mounted: async function() {
		this.init();
	},
	watch: {
		author: function(o,n) {
			this.init();
		}
	},
    methods: {
		init: function() {
			if(this.editable) {
				if(!this.author.baseUnitId || this.author.baseUnitId == 0) {
					this.$nextTick( () => {
						this.loadPrimary();
					});
				} else {
					this.$nextTick( () => {
						this.unitChipLabel = this.author.baseUnitAbbr;
					});
				}
			}
		},
        clear: function () {
            this.hasValue = false;
        },
		modalOpen: function() {
			let props = {baseUnit: {id: this.author.baseUnitId}}
			this.$refs.departmentModal.open(props)
		},
		modalClosed: function(response) {
			if(response && response.id && response.id > 0) {
				this.author.baseUnitAbbr = response.abbr;
				this.author.baseUnitId = response.id;
				this.author.baseUnit = response.name;
				this.unitChipLabel = this.author.baseUnitAbbr;
				this.updateValue()
			}
		},
        updateValue: function () {
            this.$emit("input", this.foundItem);
        },
		fieldClicked: function() {
			console.log(this.valueText)
			if(!this.valueText || this.valueText=="" ) {
				this.modalOpen()
			}
		},
		loadPrimary: async function() {
			let departments = await this.departmentStore.fetchForAuthor(this.author.uic, {})
			if(departments.length > 0) {
				let primary = departments[0];
				this.author.baseUnitAbbr = primary.abbr;
				this.author.baseUnitId = primary.id;
				this.author.baseUnit = primary.name;
				this.unitChipLabel = this.author.baseUnitAbbr;
				//this.author = author;
			}
		}
    },
    components: { JournalSearchModal, PersonDepartmentModal }
}
</script>