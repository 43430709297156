<template>
	<v-card>
        <v-toolbar flat color="primary" dark>
            <v-btn icon :to="{name: 'user-publications', tab: 2}"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ $t('views.import-publication') }}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-progress-linear :active="isLoading" indeterminate />
		
		<v-stepper v-model="stepperState" :vertical="false" flat>
			<v-stepper-header>
				<v-stepper-step :complete="stepperState > 1" step="1">{{ $t('views.general-informations') }}</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="stepperState > 2" step="2">{{ $t('views.annotation-and-keywords') }}</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="stepperState > 3" step="3">{{ $t('view.authors') }}</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="(stepperState > 4)" step="4">{{ $t('view.publication-data') }}</v-stepper-step>
				<!--
                <v-divider></v-divider>
				<v-stepper-step :complete="stepperState > 4" step="4">Doplňková data</v-stepper-step>
                -->
			</v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <validation-observer ref="observer" v-slot="{invalid}">
                        <v-card flat>
                            <v-toolbar flat>
                                <v-spacer></v-spacer>
                                <v-btn :disabled="invalid" @click="stepperState = 2" color="primary">{{ $t('btn.next-step') }}</v-btn>
                            </v-toolbar>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="6">
                                        <validation-provider name="Rok publikace" v-slot="{errors}" rules="required">    
                                            <v-select v-model="publication.yearOfPublication"
                                                :items="listStore.yearList"
                                                :label="$t('publications.year')"
                                                :error-messages="errors"
                                                required
                                                ></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6">
                                        <validation-provider name="Typ publikace" v-slot="{errors}" rules="required">    
                                            <v-select v-model="publication.publicationTypeId"
                                                :items="listStore.publicationTypeList"
                                                item-text="name"
                                                item-value="id"
                                                readonly
                                                :label="$t('publications.type')"
                                                :error-messages="errors"
                                                required
                                                ></v-select>
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                                <validation-provider name="Název publikace v originále" v-slot="{errors}" rules="required|min:10">
                                    <v-text-field v-model="publication.name"
                                        :label="$t('publications.name')"
                                        required
                                        hint="Uveďte přesný a úplný (nezkrácený) název výsledku v původním jazyce, ve kterém byl výsledek publikován, zveřejněn nebo realizován."
                                        :error-messages="errors"
                                        ></v-text-field>			
                                </validation-provider>

                                <validation-provider name="Název publikace - anglicky" v-slot="{errors}" rules="required|min:10">
                                    <v-text-field v-model="publication.nameEn"
                                        :label="$t('publications.name-original')"
                                        hint="Toto pole není nutné vyplňovat, pokud je původní jazyk výsledku angličtina. Uveďte přesný a úplný (nezkrácený) název výsledku v anglickém jazyce."
                                        :error-messages="errors"
                                        required
                                        ></v-text-field>	
                                </validation-provider>

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="publication.doi"
                                            label="DOI"
                                            hint="DOI (Digital Object Identifier1), který identifikuje výsledek (článek, knihu, kapitolu apod.). Vyplňuje se, pokud údaj existuje a je znám."
                                            required
                                            ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="publication.web"
                                            :label="$t('publications.web')"
                                            hint="Pro publikační výsledky se jedná o odkaz na webovou stránku, ze které je dostupný plný text výsledku. Je-li plný text výsledku archivován ve veřejně přístupném institucionálním repozitáři předkladatele, uvede se odkaz do tohoto repozitáře."
                                            required
                                            ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <validation-provider name="Jazyk výsledku" v-slot="{errors}" rules="required">    
                                            <v-select v-model="publication.languageId"
                                                :items="listStore.languageList"
                                                :label="$t('publications.language')"
                                                hint="Vyberte ze seznamu původní jazyk, ve kterém byl výsledek publikován, zveřejněn nebo realizován."
                                                required
                                                :error-messages="errors"
                                                ></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select v-model="publication.openAccessTypeId"
                                            :items="listStore.openAccessList"
                                            :label="$t('publications.open-access-type')"
                                            hint="Typ OpenAccess výsledku, povinné pro výsledky J."
                                            required
                                            ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-select v-model="publication.fordId"
                                            :items="listStore.fordList"
                                            :label="$t('publications.ford')"
                                            hint="Kód z číselníku oborů platného pro CEP a RIV zveřejněného na www.rvvi.cz (podle oborových skupin OECD - Frascati Manual 2015). Uvedení kódu je povinné."
                                            required
                                            ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select v-model="publication.levelOfSecrecyId"
                                            :items="listStore.confidentialityList"
                                            :label="$t('publications.level-of-secrecy')"
                                            ></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-toolbar flat>
                                <v-spacer></v-spacer>
                                <v-btn :disabled="invalid" @click="stepperState = 2" color="primary">{{ $t('btn.next-step') }}</v-btn>
                            </v-toolbar>
                        </v-card>
                    </validation-observer>
                </v-stepper-content>
                <v-stepper-content step="2">
                <validation-observer ref="observer-step-2">
                    <v-card flat>
                        <v-toolbar flat>
                            <v-btn @click="stepperState = 1">{{ $t('btn.back') }}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="stepperState = 3" color="primary">{{ $t('btn.next-step') }}</v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <validation-provider name="Klíčová slova - anglicky" v-slot="{errors}" rules="required">    
                                <v-text-field v-model="publication.keywords"
                                    :label="$t('publications.keywords')"
                                    :error-messages="errors"
                                    hint="Vypište klíčová slova, jak se běžně uvádějí v renomovaných informačních zdrojích. Slova lze oddělovat středníkem, jako klíčové slovo je možné použít i sousloví (několik slov oddělených mezerami)."
                                    ></v-text-field>	
                            </validation-provider>
                            <validation-provider name="Anotace v originále" v-slot="{errors}" rules="required|min:62">    
                                <v-textarea v-model="publication.annotation"
                                    :label="$t('publications.annotation')"
                                    :error-messages="errors"
                                    counter
                                    hint="Napište souhrn nebo anotaci nebo abstrakt v původním jazyce, ze kterého je patrné co výsledek řeší nebo čeho bylo dosaženo. Minimální délka textu je 64 znaků. V popisku výsledku lze používat pouze českou alfanumerickou klávesnici bez speciálních znaků"
                                    ></v-textarea>			
                            </validation-provider>
                            <validation-provider name="Anotace - anglicky" v-slot="{errors}" rules="required|min:62">    
                                <v-textarea v-model="publication.annotationEn"
                                    :label="$t('publications.annotation-original')"
                                    :error-messages="errors"
                                    counter
                                        hint="Toto pole není nutné vyplňovat, pokud je původní jazyk výsledku angličtina. Napište souhrn nebo anotaci nebo abstrakt v anglickém jazyce, ze kterého je patrné co výsledek řeší nebo čeho bylo dosaženo. Minimální délka textu je 64 znaků."
                                    ></v-textarea>			
                            </validation-provider>
                        </v-card-text>
                        <v-toolbar flat>
                            <v-btn @click="stepperState = 1">{{ $t('btn.back') }}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="stepperState = 3" color="primary">{{ $t('btn.next-step') }}</v-btn>
                        </v-toolbar>
                    </v-card>
                </validation-observer>
                </v-stepper-content>
                <v-stepper-content step="3">
                    <v-card flat>
                        <v-toolbar flat>
                            <v-btn @click="(stepperState = 2)">{{ $t('btn.back') }}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="confirmAuthorsNextStep" color="primary">{{ $t('btn.next-step') }}</v-btn>
                        </v-toolbar>
                        <ImportAuthorsManager ref="authorsManager" :authors="authors"></ImportAuthorsManager>
                        <v-toolbar flat>
                            <v-btn @click="(stepperState = 2)">{{ $t('btn.back') }}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="confirmAuthorsNextStep" color="primary">{{ $t('btn.next-step') }}</v-btn>
                        </v-toolbar>
                    </v-card>
                </v-stepper-content>
                <v-stepper-content step="4">
                    <v-card flat>
                        <v-toolbar flat>
                            <v-btn @click="stepperState = 3">{{ $t('btn.back') }}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="savePublication()" color="success">{{ $t('btn.import-publication') }}</v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <journal-article-form v-if="publicationType==1" ref="articleJournal"></journal-article-form>
                            <book-form v-if="publicationType==2" ref="subformBook"></book-form>
                            <book-chapter-form v-if="publicationType==3" ref="subformBookChapter"></book-chapter-form>
                        </v-card-text>
                        <v-toolbar flat>
                            <v-btn @click="stepperState = 3">{{ $t('btn.back') }}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="savePublication()" color="success">{{ $t('btn.import-publication') }}</v-btn>
                        </v-toolbar>
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
		</v-stepper>
		<confirm-dialog ref="confirm"></confirm-dialog>
	</v-card>
</template>


<script> 
import {axiosInst} from '../main'
import JournalArticleForm from './forms/JournalArticleForm.vue'
import BookForm from './forms/BookForm.vue'
import BookChapterForm from './forms/BookChapterForm.vue'
//import { required, digits, email, max, min, regex } from 'vee-validate/dist/rules'
import { required, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver } from 'vee-validate'
import { publicationStore } from '../stores/publication-store'
import { listsStore } from '../stores/lists-store'
import { useWormEntryStore } from '../stores/worm-entry-store'
import ImportAuthorsManager from './parts/ImportAuthorsManager.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import { useExternalPersonStore } from '../stores/external-person-store'

extend('required', {
    ...required,
    message: 'Položka {_field_} nesmí být prázdná.',
})

extend('min', {
    ...min,
    message: 'Položka {_field_} je příliš krátká. Musí obsahovat alespoň {length} znaků.',
})

export default {
	name: 'JournalArticlePublication',
    props: {
        type: [Number,String],       //publication type
        publicationId: Number,
        uic: Number,
        isNewEntry: Boolean,
        isImporting: Boolean,
        importEntry: [String,Number]
    },
    components: {
    JournalArticleForm,
    BookForm,
    BookChapterForm,
    ValidationObserver,
    ImportAuthorsManager,
    ConfirmDialog
},
    data: function() {
		return {
            store: publicationStore(),
            listStore: listsStore(),
            isLoading: false,
            publicationType: 1,
            publication: {},
            publicationSubtype: {},
            stepperState: 1,
            yearList: {},
            journalIsLoading: false,
            journalFoundEntries: [],
            search: "",
            timeoutId: null,
            publicationTypeList: [],
            languageList: [],
            cepList: [],
            confidentialityList: [],
            openAccessList: [],
            fordList: [],
            authors: [],
            extAuthorStore: useExternalPersonStore(),
            rules: {
                titleRules: [
                    v => !!v || 'Toto pole je povinné'
                ]
            },
            wormStore: useWormEntryStore(),
        }
    },
    mounted: async function() {
        await this.loadLists()

        await this.requestImport()
    },
    methods: {
        loadLists: function() {
            this.listStore.fetch({})
        },
        confirmAuthorsNextStep: function() {
            
            let unmappedAuthors = 0;
            this.authors.forEach( f => {
                if(!f.locked) {
                    unmappedAuthors++
                }
            } )

            if(unmappedAuthors) {
                this.confirmAutoCreateUnmappedAuthors(unmappedAuthors);
            } else {
                this.stepperState = 4;
            }
        },
        savePublication: function() {

           // var data = this.getSubformByType().getData()
           // this.publication.subType = data

            /*
            axiosInst.post('/publications', this.publication)
            .then(response => {
                this.$router.push('/user/publications')
            })
            */
           this.wormStore.publication = this.publication;
           this.wormStore.publication.authors = this.$refs.authorsManager.getResolvedAuthors();
           this.$router.push({name: "create-new-publication", params: {isImporting: true, isNewEntry: true, isDraft: false}})

        },
        setSubformDefaults: function() {
            (this.getSubformByType()).setData(this.publication);
        },
        setSubformByType: function() {
            let subform = this.getSubformByType();

            switch(this.publication.publicationTypeId * 1) {
                case 1:
                    console.log("article data", this.publication.article)
                    return subform.setData(this.publication.article)
                case 2:
                    return subform.setData(this.publication.book)
                case 3:
                    return subform.setData(this.publication.bookChapter)
                default:
                    console.log("type not found" + this.publication.publicationTypeId)
            }
        },
        getSubformByType: function() {
            switch(this.publication.publicationTypeId * 1) {
                case 1:
                    return this.$refs.articleJournal
                case 2:
                    return this.$refs.subformBook
                case 3:
                    return this.$refs.subformBookChapter
                default:
                    console.log("type not found" + this.publication.publicationTypeId)
            }
        },
        requestImport: async function() {

            await this.wormStore.get(this.importEntry);
            this.publication = this.wormStore.publication;
            console.log("pt", this.publication.publicationTypeId)

            this.authors = this.wormStore.publication.authors;
            this.publicationType = this.publication.publicationTypeId;
            
            this.$nextTick( () => {this.setSubformByType()} )
            return;
        },
        confirmAutoCreateUnmappedAuthors: async function(noOfUnmapper) {
			this.$refs.confirm.open( "Potvrzení", "Všichni autoři importované publikace nebyli namapováni. Chcete tyto autory založit jako externí?")
			.then( (c) => {
                this.createUnmappedAuthors()
			} ).catch( (c) => {
                this.stepperState = 4;
            } )
		},
        createUnmappedAuthors: async function() {
            let unmappedAuthors = this.publication.authors.filter( f => !f.locked );
            console.log("creating authors", unmappedAuthors);

            for(const a of unmappedAuthors) {
                await this.createExtAuthor(a);
            }
            this.stepperState = 4;
        },
        createExtAuthor: async function(pubAuthor) {

            let author = {
                name: pubAuthor.wormAuthor.name,
                surname: pubAuthor.wormAuthor.surname,
                displayName: pubAuthor.wormAuthor.displayName,
                orcid:  pubAuthor.wormAuthor?.orcid,
                scopusId:  pubAuthor.wormAuthor?.scopusId,
                researcherId:  pubAuthor.wormAuthor?.researcherId,
            }

            return await this.extAuthorStore.create({}, author).
                then( p => {
                    console.log(pubAuthor, p.data)

                    let inAuthor = this.publication.authors.find( f => f.id == pubAuthor.id )
                    inAuthor.type = 2;
                    inAuthor.uic = p.data.id;
                    inAuthor.externalAuthorId = p.data.id;
                    inAuthor.author = p.data;
                    inAuthor.author.type = 2;
                    inAuthor.author.externalAuthorId = p.data.id;
                    inAuthor.wormAuthorId = pubAuthor.wormAuthor.id;
                } )
        }

    }

}
</script>