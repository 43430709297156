import { axiosInst } from "../main";

export default class GrantPublisherService {
    
    async fetchAll( params )  {
        const response = await axiosInst.get('/lists/grant-providers', {
            params: params
        })
        return response
    }
}