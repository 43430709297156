<template>
	<v-row>
		<v-col cols="12">
			<v-text-field
			v-model="labelValue"
			prepend-icon="mdi-database-search"
			label="Osoba"
			:error-messages="errorMessages"
			readonly
			clearable
			required
			@click:clear="clear()"
			@click="fieldClicked"
			:rules="rules.select"
			>
			<template v-slot:append-outer>
				<v-btn depressed @click="modalOpen">{{ $t('btn.search-person') }}<v-icon right>mdi-magnify</v-icon></v-btn>
			</template>

			</v-text-field>
		</v-col>
		
		<person-search-modal ref="personSearch" @close-dialog="modalClosed"></person-search-modal>
	</v-row>
</template>

<script>
import PersonSearchModal from '../dialogs/PersonSearchModal.vue';

export default {
    name: "PersonPicker",
    props: {
        errorMessages: Array,
		valueText: String,
        value: Number,
    },
    data: function () {
        return {
			labelValue: "",
            timeoutId: null,
            search: "",
            isLoading: false,
            hasValue: false,
			foundItem: {},
			rules: {
				select: [
					v => !!v || 'Zadejte hledanou hodnotu',
				]
			}
        };
    },
	watch: {
		valueText: function(nV, oV) {
			console.log("watcher - value updated", nV, oV)
			this.labelValue = nV;
		}
	},
    methods: {
        clear: function () {
            this.hasValue = false;
			this.foundItem = null;
			this.updateValue();
        },
		modalOpen: function() {
			this.$refs.personSearch.open()
		},
		modalClosed: function(response) {
			this.foundItem = response;
			this.updateValue()
		},
        updateValue: function () {
            this.$emit("input", this.foundItem);
        },
		fieldClicked: function() {
			console.log(this.valueText)
			if(!this.valueText || this.valueText=="" ) {
				this.modalOpen()
			}
		}
    },
    components: { PersonSearchModal }
}
</script>