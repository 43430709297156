<template>
	<v-card class="ma-4">
		<v-toolbar flat color="primary" dark>
			<v-toolbar-title>Správa publikací</v-toolbar-title>

			<template v-slot:extension>
				<v-tabs v-model="tabs" align-with-title>
					<v-tabs-slider color="yellow"></v-tabs-slider>
					<v-tab>Schvalování publikací</v-tab>
					<v-tab>Rozpracované publikace</v-tab>
					<v-tab>Importované záznamy</v-tab>
					<v-tab v-if="false">Změny v publikacích</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>
		<v-card-text>
			<v-tabs-items v-model="tabs">
				<v-tab-item>
					<ApprovalManagerSuper></ApprovalManagerSuper>
				</v-tab-item>
				<v-tab-item>
					<DraftManager></DraftManager>
				</v-tab-item>
				<v-tab-item>
					<ImportManager></ImportManager>
				</v-tab-item>
			</v-tabs-items>
		</v-card-text>
	</v-card>
</template>

<script>
import ApprovalManagerSuper from './ApprovalManagerSuper.vue';
import DraftManager from './DraftManager.vue';
import ImportManager from './ImportManager.vue';

export default {
	components: { ApprovalManagerSuper, DraftManager, ImportManager },
	name: 'ApprovalManagerFrame',
	props: {
		
	},
	data: () => ({
		tabs: 0,
	}),
}

</script>;