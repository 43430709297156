var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"formOfPublicationId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.formTypes.data,"label":_vm.$t('publications.general.form-of-publication'),"error-messages":errors,"required":""},model:{value:(_vm.publication.formOfPublicationId),callback:function ($$v) {_vm.$set(_vm.publication, "formOfPublicationId", $$v)},expression:"publication.formOfPublicationId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"ISBN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.isbn'),"error-messages":errors},model:{value:(_vm.publication.isbn),callback:function ($$v) {_vm.$set(_vm.publication, "isbn", $$v)},expression:"publication.isbn"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"bookName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.book.name'),"error-messages":errors},model:{value:(_vm.publication.bookName),callback:function ($$v) {_vm.$set(_vm.publication, "bookName", $$v)},expression:"publication.bookName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"editionName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.edition-name'),"error-messages":errors},model:{value:(_vm.publication.editionName),callback:function ($$v) {_vm.$set(_vm.publication, "editionName", $$v)},expression:"publication.editionName"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"pageFrom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.page-from'),"error-messages":errors,"hint":"Pokud jde o článek v nestránkovaném periodiku, uveďte údaj 0."},model:{value:(_vm.publication.pageFrom),callback:function ($$v) {_vm.$set(_vm.publication, "pageFrom", $$v)},expression:"publication.pageFrom"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"pageTo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.page-to'),"error-messages":errors,"hint":"Pokud jde o článek v nestránkovaném periodiku, uveďte údaj 0"},model:{value:(_vm.publication.pageTo),callback:function ($$v) {_vm.$set(_vm.publication, "pageTo", $$v)},expression:"publication.pageTo"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"noOfPagesInBook","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.no-of-pages'),"error-messages":errors,"hint":_vm.$t('help.hints.no-of-pages')},model:{value:(_vm.publication.noOfPagesInBook),callback:function ($$v) {_vm.$set(_vm.publication, "noOfPagesInBook", $$v)},expression:"publication.noOfPagesInBook"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"noOfCopies","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.genera.no-of-copies'),"error-messages":errors},model:{value:(_vm.publication.noOfCopies),callback:function ($$v) {_vm.$set(_vm.publication, "noOfCopies", $$v)},expression:"publication.noOfCopies"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"placeOfIssue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"$t('publications.general.place-of-issue')","error-messages":errors},model:{value:(_vm.publication.placeOfIssue),callback:function ($$v) {_vm.$set(_vm.publication, "placeOfIssue", $$v)},expression:"publication.placeOfIssue"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"publisher","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.publisher'),"error-messages":errors},model:{value:(_vm.publication.publisher),callback:function ($$v) {_vm.$set(_vm.publication, "publisher", $$v)},expression:"publication.publisher"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.eid')},model:{value:(_vm.publication.eid),callback:function ($$v) {_vm.$set(_vm.publication, "eid", $$v)},expression:"publication.eid"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }