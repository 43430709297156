import { defineStore } from "pinia";
import JournalPublisherService from "../services/journal-publisher-service";

export const publishersStore = defineStore("publishersStore", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
		},
    }),
    getters: {
        getItems() {
            return this.items
        },
        getItemsForSelect() {
            if(this.items.length == 0) {
                this.fetch({})
            }
            return this.items.map(row => {
                return {value: row.value, text: row.text}
            })
        }
    },
    actions: {
        async fetch(params) {
            let service = new JournalPublisherService();
            const results = await service.fetchAll(params);
            this.items = results.data.data;
        }
    }
})