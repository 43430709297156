import { defineStore } from "pinia";
import JournalService from "../services/journal-service";
import JournalImpactService from "../services/journal-impact-service";

export const useJournalStore = defineStore("journalStore", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
		},
        journal: {},
        journalWorm: {},
        journalImpacts: []
    }),
    getters: {
        getItems() {
            return this.items
        }
    },
    actions: {
        clearList() {
            this.items = []
        },
        async get(id, params) {
            let service = new JournalService();
            const results = await service.get(id, params);
            this.journal = results.data;
            return results;
        },
        async fetch(params, callback) {
            let service = new JournalService();
            const results = await service.fetchAll(params, callback);
            this.items = results.data.data;
        },
        async fetchImpacts(id, params) {
            let service = new JournalImpactService();
            const results = await service.fetchForJournal(id, params);
            this.journalImpacts = results.data.data;
        },

        async getWorm(id, params) {
            let service = new JournalService();
            const results = await service.getWorm(id, params);
            this.journalWorm = results.data;
        },
    }
})