<template>
	<div v-if="userStore.hasRole(['ADMINISTRATOR'])">
		<v-alert v-if="publication.publicationStateId == 2 || publication.publicationStateId == 3" type="success">
			<v-row align="center">
				<v-col class="grow">
					Publikace je schválena na úrověň: {{ publication.publicationState }}
				</v-col>
				<v-spacer></v-spacer>
				<v-col>
					<v-row>
						<v-btn v-if="publication.publicationStateId == 2"  @click="approveAs(3)" color="primary">Schválit jako proděkan</v-btn>
						<v-btn class="ml-2" color="warning" @click="revokeApproval(1)">Odschválit publikaci</v-btn>
					</v-row>
				</v-col>
			</v-row>
		</v-alert>
		<v-alert v-if="publication.publicationStateId == 1" type="info">
			<v-row align="center">
				<v-col class="grow">
					Publikace je ve stavu: {{ publication.publicationState }}
				</v-col>
				<v-spacer></v-spacer>
				<v-col>
					<v-row>
						<v-btn v-if="publication.publicationStateId == 1"  @click="approveAs(2)" color="primary">Schválit jako vedoucí katedry</v-btn>
						<v-btn v-if="publication.publicationStateId == 1 || publication.publicationStateId == 2"  @click="approveAs(3)" color="primary">Schválit jako proděkan</v-btn>
					</v-row>
				</v-col>
			</v-row>
		</v-alert>
		<confirm-dialog ref="confirm"></confirm-dialog>
	</div>
</template>

<script>
import { authStore } from '@/stores/user';
import { publicationStore } from '@/stores/publication-store';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import emitter from '@/plugins/mitt';
import { useApprovalStore } from '../../stores/approval-store';
import { useNotificationStore } from '../../stores/notification-store';

export default {
    name: "PublicationApprovalBar",
    props: {
		publication: {
			type: Object,
			required: true,
		}
    },
    data: function () {
        return {
			userStore: authStore(),
			publicationStore: publicationStore(),
			approvalStore: useApprovalStore(),
			notificationStore: useNotificationStore(),
        };
    },
	mounted: function() {
	},
    methods: {
        approveAs: function (type) {
			this.approvalStore.changeState(this.publication.id, type, "", {}, () => {
				this.notificationStore.addSuccess({message: "Publikace schválena"})
				emitter.emit( "publication.state.changed", {publicationId: this.publication.id} )
			})
        },
		revokeApproval: function(type) {
			this.$refs.confirm.open("Potvrzení odschválení publikace", "Opravdu zrušit schválení u vybrané publikace?", {})
			.then( (c) => {
				this.approvalStore.changeState(this.publication.id, 1, "", {}, () => {
					this.notificationStore.addSuccess({message: "Publikace odschválena"})
					emitter.emit( "publication.state.changed", {publicationId: this.publication.id} )
				})
			} );
		},
    },
    components: { ConfirmDialog }
}
</script>