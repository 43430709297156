<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialogOpen"
            :persistent="persistent"
            max-width="600px"
            @keydown.esc="dialogOpen = false"
            >
            <v-card tile>
                <v-card-title>Nastavené štítky záznamu</v-card-title>
                <v-card-text>
                    <PublicationTagList :publicationId="publicationId"></PublicationTagList>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="close">{{ $t('btn.close') }}</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import emitter from '../../plugins/mitt';
import PublicationTagList from '../parts/PublicationTagList.vue'

export default {
    name: 'PublicationTagDialog',
    props: {},
    data: () => ({
        dialogOpen: false,
        persistent: true,
        publicationId: {},
    }),
    mounted: function () {
        this.dialogOpen = false;

        emitter.on("publication.tags.dialog", f => {
            console.log("event received", f);
            this.open( f )
        })
    },
    methods: {
        open: function(props) {
            this.dialogOpen = true
            
            if(!props) {return;}

            if("dialogPersistent" in props) {
                this.persistent = props.dialogPersistent;
            }

            this.publicationId = props.publicationId
        }, 
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
    },
    components: { PublicationTagList }
}
</script>
