<template>
	<div>
		<v-row>
			<v-col cols="4">
				<validation-provider vid="publicationSubtypeId" v-slot="{errors}" rules="required">
				<v-select v-model="formData.publicationSubtypeId"
					:items="list.getOptions(8)"
					:label="$t('publications.general.sub-type-result')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="scopeOfUseId" v-slot="{errors}" rules="required">
				<v-select v-model="formData.scopeOfUseId"
					:items="list.scopeOfUse"
					:label="$t('publications.general.scope-of-use')"
					required
					:error-messages="errors"
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="internalId" v-slot="{errors}" rules="required">
				<v-text-field v-model="formData.internalId"
					:label="$t('publication.general.internal-identifikator')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="resultLocalisation" v-slot="{errors}" rules="required">
				<v-text-field v-model="formData.resultLocalisation"
					:label="$t('publications.methodology.result-number')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="identification" v-slot="{errors}" rules="required">
				<v-text-field v-model="formData.identification"
					:label="$t('publications.methodology.identification')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="technicalParams" v-slot="{errors}" rules="required">
				<v-textarea v-model="formData.technicalParams"
					:label="$t('publications.general.technical-params')"
					hint=""
					:error-messages="errors"
					counter="3000"
					></v-textarea>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="economicalParams" v-slot="{errors}" rules="required">
				<v-textarea v-model="formData.economicalParams"
					:label="$t('publications.general.economical-params')"
					hint=""
					counter="1000"
					:error-messages="errors"
					></v-textarea>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="certifiedBy" v-slot="{errors}" rules="required">
				<v-text-field v-model="formData.certifiedBy"
					:label="$t('publications.methodology.certified-by')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" offset-y min-width="auto">
					<template v-slot:activator="{on, attrs}">
						<v-text-field
							v-model="date" :label="$t('publications.methodology.certified-on')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
					</template>
					<v-date-picker v-model="date" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">{{ $t('btn.close') }}</v-btn>
						<v-btn text color="primary" @click="$refs.menu.save(date)">{{ $t('btn.insert') }}</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>

		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'MethodologyForm',
	props: {
		defaults: {type: Object, default: () => {return {q: '1'}}},
		errors: Array,
		publication: Object
	},
	data: () => ({
		formTypes: [],
		formData: {},
		date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		list: listsStore(),
		menu: false
	}),
	mounted: async function() {
		await this.loadData();
		//this.publication = this.defaults
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				this.publication = data
				this.formData = data
				this.date = this.formData.certificationDate;
			}	
		},
		getData: function() {
			this.formData.certificationDate = this.date;
			return this.formData;
//			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;