<template>
    <v-chip>
        <v-icon left>mdi-account-circle</v-icon>
        {{ author.displayName }}
    </v-chip>
</template>

<script>
import { usePersonStore } from '../../stores/person-store';


export default {
    name: "AuthorAvatar",
    props: {
        uic: [Number,String],
    },
    data: () => ({
        author: "",
        personStore: usePersonStore(),
    }),
	mounted: function() {
        this.load();
	},
    methods: {
        load: function() { 
            if(this.uic == -1) {
                this.author = {
                    displayName: "System",
                }
                return;
            }
            if(this.uic > 0) {
                console.log("psss", this.personStore);

                this.personStore.getPerson(67400)
                    .then( r => {
                        this.author = r.data
                    } );
            }
        }
    },
}
</script>