import { axiosInst } from "../main";

export default class ArticleSubTypeService {
    
    async fetchAll( params )  {
        const response = await axiosInst.get('/lists/article-subtypes', {
            params: params
        })
        return response
    }
}