import { defineStore } from "pinia";
import CepService from "../services/cep-service";

export const cepStore = defineStore("cepStore", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
		},
    }),
    getters: {
        getItems() {
            return this.items
        }
    },
    actions: {
        async fetch(params) {
            let service = new CepService();
            const results = await service.fetchAll(params);
            this.items = results.data.data;
        }
    }
})