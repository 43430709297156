<template>
		<v-autocomplete 
			v-model="pickedValue"
			label="Útvar"
			required
			:disabled="disabled"
			prompt="Název útvaru, zkratka ..."
			:items="items"
			:loading="isLoading"
			:search-input.sync="search"
			item-value="id"
			:item-text="depName"
			@change="emitChange"
			@click:clear="clear()"
			clearable
			prepend-icon="mdi-database-search"
			:readonly="!searchEnabled"
			return-object
			>
			</v-autocomplete>    
</template>

<script>
import {axiosInst} from '@/main'

export default {
    name: 'DepartmentSearch',
	props: {
		errorMessages: Array,
		value: [Object],
		disabled: {
			required: false,
			default: false,
			type: Boolean
		}
	},
	data: function() {
		return {
			pickedValue: {},
			timeoutId: null,
			items: [],
			search: "",
			isLoading: false,
			hasValue: false,
			searchEnabled: false,
			rules: {
				select: [
					v => !!v || 'Zadejte hledanou hodnotu',
                    //v => v > 0  || console.log(v) || 'oke',
					v => v > 0 || 'Nebyl vybrán útvar.'
				]
			}
		};
	},
	mounted: function() {
		console.log("picker value", this.value)
		if(this.value) {
			
			this.$nextTick(() => { 
				this.pickedValue = this.value;
				console.log("-----------------", this.items)
				this.items.push(this.value);
				console.log("B-----------------", this.items)
				this.searchEnabled = false;
			 });

			console.log(this.items);
		} else {
			this.searchEnabled = true;
		}
	},
	watch: {
		value: function( nV, oV) {
			console.log("-------val change ----", nV, oV)

			this.pickedValue = nV;

			this.items.push(nV);

			this.searchEnabled = (nV == null);
		},
		search: function(val) {
			if (val == null || val == "") return;
			if (val.length <= 2) return;
			//if (this.isLoading) return;
			console.log("search ", val)
			
			if(!this.searchEnabled) return;

			this.isLoading = true;
			
			if(this.timeoutId != null) clearTimeout(this.timeoutId);			
			
			this.timeoutId = setTimeout( () => {this.searchJournal(val)}, 1000)
		},
	},
	computed: {
	},
	methods: {
		depName: function(item) {
			return `${item.name} (${item.abbr})`;
		},
		clear: function() {
			this.hasValue = false
			this.searchEnabled = true;
		},
		searchJournal: function(q) {
			console.log("search input", q)
			this.isLoading = true;
			axiosInst.get('/departments/?q='+q)
			.then( response => {
					this.items = response.data.data 
				})
				.finally( () => {
					this.isLoading = false;		
				})
		},
		emitChange: function() {
			this.$emit("input", this.pickedValue  )
		},
		getDepartment: function(q) {
			axiosInst.get('/departments/'+q+'')
				.then( response => {
					this.items = [response.data]
				})
				.finally( () => {
					this.isLoading = false;		
				})
		},
	},
}
</script>