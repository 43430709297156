import { defineStore } from "pinia";
import GrantService from "../services/grant-service";

export const useGrantStore = defineStore("grantStore", {
    state: () => ({
        items: [],
        searchQuery: {
			q: null,
			name: null,
            year: 2022,
            publisherId: 0
		},
        grant: {}
    }),
    getters: {
        getItems() {
            return this.items
        }
    },
    actions: {
        clearList() {
            this.items = []
        },
        async fetch(params, callback) {
            let service = new GrantService();
            const results = await service.fetchAll(params, callback);
            this.items = results.data.data;
        },
        async get(id) {
            let service = new GrantService();
            const results = await service.get(id);
            this.grant = results.data;
        }
    }
})