<template>
	<span>
		<v-text-field
		v-model="labelValue"
		prepend-icon="mdi-database-search"
		append-outer-icon="mdi-magnify"
		:label="$t('publications.authors.department')"
		:error-messages="errorMessages"
		readonly
		clearable
		@click:clear="clearValue()"
		@click="fieldClicked"
		>
			<template v-slot:append-outer>
				<v-btn depressed @click="modalOpen">{{ $t('btn.set') }}<v-icon right>mdi-magnify</v-icon></v-btn>
			</template>
		</v-text-field>
		<department-search-modal ref="search" @close-dialog="modalClosed"></department-search-modal>
	</span>
</template>

<script>
//:rules="rules.select"
import DepartmentSearchModal from '../dialogs/DepartmentSearchModal.vue';

export default {
    name: "DepartmentPicker",
    props: {
        errorMessages: Array,
		valueText: String,
        value: Number,
    },
    data: function () {
        return {
			labelValue: "",
            timeoutId: null,
            search: "",
            isLoading: false,
            hasValue: false,
			foundItem: {},
			rules: {
				select: [
					v => !!v || 'Zadejte hledanou hodnotu',
				]
			}
        };
    },
	watch: {
		valueText: function(nV, oV) {
			console.log("watcher - value updated", nV, oV)
			this.labelValue = nV;
		}
	},
    methods: {
        clear: function () {
            this.hasValue = false;
        },
		clearValue: function() {
			console.log("unit cleared")
			this.foundItem = null;
			this.hasValue = false;
			this.updateValue();
		},
		modalOpen: function() {
			this.$refs.search.open()
		},
		modalClosed: function(response) {
			this.foundItem = response;
			this.updateValue()
		},
        updateValue: function () {
            this.$emit("input", this.foundItem);
        },
		fieldClicked: function() {
			console.log(this.valueText)
			if(!this.valueText || this.valueText=="" ) {
				this.modalOpen()
			}
		}
    },
    components: { DepartmentSearchModal }
}
</script>