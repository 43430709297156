<template>
	<div>
		<v-row>
			<v-col cols="5">
				<v-text-field v-model="publicationSubtype.patternType" readonly label="Poddruh výsledku"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.number" readonly label="Číselná identifikace"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="5">
				<v-text-field v-model="publicationSubtype.ownerName" readonly label="Název vlastníka"></v-text-field>
			</v-col>
			<v-col cols="5">
				<v-text-field v-model="publicationSubtype.patternPublisher" readonly label="Vydavatel"></v-text-field>
			</v-col>
			<v-col cols="2">
				<v-text-field v-model="publicationSubtype.dateOfAcceptance" readonly label="Datum přijetí"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.licenseObligation" readonly label="Povinnost získání licence"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.licenceFee" readonly label="Povinnost odvést licenční poplatek"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.typeOfUse" readonly label="Druh možnosti využití výsledku"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';

export default {
	name: 'PatternDetail',
	props: {
		publication: Object,
	},
    components: {
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		publicationSubtype: {},
		levelOfSecrecy: {},
		researchReport: {},
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.publicationSubtype = this.publication.pattern

		}
	},
}

</script>;