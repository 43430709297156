<template>
	<v-card>
	  <v-card-title class="bg-indigo text-white text-h5">
		User Directory
	  </v-card-title>
	  <v-row
		class="pa-4"
		justify="space-between"
	  >
		<v-col cols="5">
		  <v-treeview
			v-model:active="active"
			v-model:open="open"
			:items="items"
			:load-children="fetchUsers"
			activatable
			color="warning"
			open-on-click
			transition
			dense
			return-object
			selection-type="leaf"
		  >
			<template v-slot:prepend="{ item }">
			  <v-icon v-if="!item.children">
				mdi-account
			  </v-icon>
			</template>
		  </v-treeview>
		</v-col>
  
		<v-divider vertical></v-divider>
  
		<v-col
		  class="d-flex text-center"
		>
		  <v-scroll-y-transition mode="out-in">
			<div
			  v-if="!selected"
			  class="text-h6 text-grey-lighten-1 font-weight-light"
			  style="align-self: center;"
			>
			  Select a User
			</div>
			<v-card
			  v-else
			  :key="selected.id"
			  class="pt-6 mx-auto"
			  flat
			  max-width="400"
			>
			  <v-card-text>
				<v-avatar
				  v-if="avatar"
				  size="88"
				>
				  <v-img
					:src="`https://avataaars.io/${avatar}`"
					class="mb-6"
				  ></v-img>
				</v-avatar>
				<h3 class="text-h5 mb-2">
				  {{ selected.name }}
				</h3>
				<div class="text-blue mb-2">
				  {{ selected.email }}
				</div>
				<div class="text-blue subheading font-weight-bold">
				  {{ selected.username }}
				</div>
			  </v-card-text>
			  <v-divider></v-divider>
			  <v-row
				class="text-left"
				tag="v-card-text"
			  >
				<v-col
				  class="text-right me-4 mb-2"
				  tag="strong"
				  cols="5"
				>
				  Company:
				</v-col>
				<v-col>{{ selected.company.name }}</v-col>
				<v-col
				  class="text-right me-4 mb-2"
				  tag="strong"
				  cols="5"
				>
				  Website:
				</v-col>
				<v-col>
				  <a
					:href="`//${selected.website}`"
					target="_blank"
				  >{{ selected.website }}</a>
				</v-col>
				<v-col
				  class="text-right me-4 mb-2"
				  tag="strong"
				  cols="5"
				>
				  Phone:
				</v-col>
				<v-col>{{ selected.phone }}</v-col>
			  </v-row>
			</v-card>
		  </v-scroll-y-transition>
		</v-col>
	  </v-row>
	</v-card>
  </template>
  
  <script>
	const avatars = [
	  '?accessoriesType=Blank&avatarStyle=Circle&clotheColor=PastelGreen&clotheType=ShirtScoopNeck&eyeType=Wink&eyebrowType=UnibrowNatural&facialHairColor=Black&facialHairType=MoustacheMagnum&hairColor=Platinum&mouthType=Concerned&skinColor=Tanned&topType=Turban',
	  '?accessoriesType=Sunglasses&avatarStyle=Circle&clotheColor=Gray02&clotheType=ShirtScoopNeck&eyeType=EyeRoll&eyebrowType=RaisedExcited&facialHairColor=Red&facialHairType=BeardMagestic&hairColor=Red&hatColor=White&mouthType=Twinkle&skinColor=DarkBrown&topType=LongHairBun',
	  '?accessoriesType=Prescription02&avatarStyle=Circle&clotheColor=Black&clotheType=ShirtVNeck&eyeType=Surprised&eyebrowType=Angry&facialHairColor=Blonde&facialHairType=Blank&hairColor=Blonde&hatColor=PastelOrange&mouthType=Smile&skinColor=Black&topType=LongHairNotTooLong',
	  '?accessoriesType=Round&avatarStyle=Circle&clotheColor=PastelOrange&clotheType=Overall&eyeType=Close&eyebrowType=AngryNatural&facialHairColor=Blonde&facialHairType=Blank&graphicType=Pizza&hairColor=Black&hatColor=PastelBlue&mouthType=Serious&skinColor=Light&topType=LongHairBigHair',
	  '?accessoriesType=Kurt&avatarStyle=Circle&clotheColor=Gray01&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=Default&facialHairColor=Red&facialHairType=Blank&graphicType=Selena&hairColor=Red&hatColor=Blue02&mouthType=Twinkle&skinColor=Pale&topType=LongHairCurly',
	]
  
	const pause = ms => new Promise(resolve => setTimeout(resolve, ms))
  
	export default {
	  data: () => ({
		active: [],
		avatar: null,
		open: [],
		users: [],
		items: [{id: 22, name: "users", children: []}]
	  }),
  
	  computed: {
		/*
		items () {
		  return [
			{
			  name: 'Users',
			  children: this.users,
			},
		  ]
		},
		*/
		/*
		selected () {
		  if (!this.active.length) return undefined
  
		  const id = this.active[0]
  
		  return this.users.find(user => user.id === id)
		},
		*/
	  },
  
	  watch: {
		selected: 'randomAvatar',
	  },
  
	  methods: {
		async fetchUsers (item) {
		  // Remove in 6 months and say
		  // you've made optimizations! :)
		  //await pause(1500)
  
		return new Promise( (resolve, reject) => {
			fetch('https://jsonplaceholder.typicode.com/users')
			.then(res => { 
				let json = [{id: 1, name: "qq"},{id: 2, name: "pupu"}]
				item.children.push(...json)
				 resolve(true)
			})
			.catch(err => console.warn(err))
		})
		},
		randomAvatar () {
		  this.avatar = avatars[Math.floor(Math.random() * avatars.length)]
		},
	  },
	}
  </script>