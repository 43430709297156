<template>
	<div>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="resultSubtypeId" v-slot="{errors}" rules="required">
					<v-select v-model="publication.resultSubtypeId"
					:items="list.getOptions(1)"
					:label="$t('publications.general.sub-type-result')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="territorialValidityId" v-slot="{errors}" rules="required">
					<v-select v-model="publication.territorialValidityId"
					:items="list.getOptions(2)"
					:label="$t('publications.law.teritorial-validity')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-text-field v-model="publication.internalId"
					:label="$t('publications.law.internal-id')"
					hint=""
					></v-text-field>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="competentAuthority" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.competentAuthority"
					:label="$t('publications.law.competent-authority')"
					:error-messages="errors"
					hint=""
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'LawResult',
	props: {
		defaults: {type: Object, default: () => {return {q: '1'}}},
		errors: Array,
	},
	data: () => ({
		formTypes: [],
		publication: {},
		list: listsStore(),
		menu: false
	}),
	mounted: async function() {
		await this.loadData();
		//this.publication = this.defaults
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				this.publication = data
			}
		},
		getData: function() {
			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;