<template>
    <v-combobox :label="title" append-icon="" chips multiple v-model="authors" readonly>
        <template v-slot:selection="data">
            <v-chip class="ml-2 mb-2" small
                :key="data.item.id"
                :color="!data.item.external ? 'primary' : ''"
                >
                <v-icon small class="mr-1">mdi-account</v-icon>
                {{ data.item.displayName }}
                ({{ !data.item.external ? data.item.baseUnitAbbr : "ext" }})
                <v-icon small class="ml-1" v-if="data.correspondenceAuthor">mdi-mail</v-icon>
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon v-on="on" small class="ml-1" v-if="data.item.external">mdi-bank-off</v-icon>
                    </template>
                    <span>external author</span>
                </v-tooltip>
            </v-chip>
        </template>
    </v-combobox>
</template>

<script>

export default {
    name: "PublicationAuthorsChips",
    props: {
        authors: Array,
        title: {
            type: String,
            required: false,
            default: "Autoři publikace"
        }
    },
    data: () => ({
        kwords: []
    }),
	mounted: function() {
	},
    methods: {
    },
}
</script>