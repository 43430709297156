import { axiosInst } from "../main";

export default class PublishFormsService {
    
    async fetchAll( params )  {
        const response = await axiosInst.get('/lists/publish-forms', {
            params: params
        })
        return response
    }
}