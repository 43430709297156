var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"publicationSubtypeId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.getOptions(6),"label":_vm.$t('publications.general.sub-type-result'),"error-messages":errors,"required":""},model:{value:(_vm.publication.publicationSubtypeId),callback:function ($$v) {_vm.$set(_vm.publication, "publicationSubtypeId", $$v)},expression:"publication.publicationSubtypeId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"internalId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.internal-code-of-product'),"error-messages":errors,"hint":""},model:{value:(_vm.publication.internalId),callback:function ($$v) {_vm.$set(_vm.publication, "internalId", $$v)},expression:"publication.internalId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"resultLocalisation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.identification-number'),"error-messages":errors,"hint":""},model:{value:(_vm.publication.resultLocalisation),callback:function ($$v) {_vm.$set(_vm.publication, "resultLocalisation", $$v)},expression:"publication.resultLocalisation"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"ownerName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.owner-name'),"hint":"","error-messages":errors},model:{value:(_vm.publication.ownerName),callback:function ($$v) {_vm.$set(_vm.publication, "ownerName", $$v)},expression:"publication.ownerName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"vid":"ownerId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.owner-id'),"hint":"","error-messages":errors},model:{value:(_vm.publication.ownerId),callback:function ($$v) {_vm.$set(_vm.publication, "ownerId", $$v)},expression:"publication.ownerId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"countryId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.country,"label":_vm.$t('publications.general.owner-state'),"error-messages":errors,"required":""},model:{value:(_vm.publication.countryId),callback:function ($$v) {_vm.$set(_vm.publication, "countryId", $$v)},expression:"publication.countryId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"technicalParams","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('publications.general.technical-params'),"hint":"","counter":"3000","error-messages":errors},model:{value:(_vm.publication.technicalParams),callback:function ($$v) {_vm.$set(_vm.publication, "technicalParams", $$v)},expression:"publication.technicalParams"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"economicalParams","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('publications.general.economical-params'),"hint":"","counter":"1000","error-messages":errors},model:{value:(_vm.publication.economicalParams),callback:function ($$v) {_vm.$set(_vm.publication, "economicalParams", $$v)},expression:"publication.economicalParams"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"licenseObligationId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.licenceObligation,"label":_vm.$t('publications.general.license-obligation'),"error-messages":errors,"required":""},model:{value:(_vm.publication.licenseObligationId),callback:function ($$v) {_vm.$set(_vm.publication, "licenseObligationId", $$v)},expression:"publication.licenseObligationId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"licenceFeeId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.licenseFee,"label":_vm.$t('publications.general.license-fee-obligation'),"error-messages":errors,"required":""},model:{value:(_vm.publication.licenceFeeId),callback:function ($$v) {_vm.$set(_vm.publication, "licenceFeeId", $$v)},expression:"publication.licenceFeeId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"resultCategoryId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.resultCategory,"label":_vm.$t('publications.general.result-category'),"error-messages":errors,"required":""},model:{value:(_vm.publication.resultCategoryId),callback:function ($$v) {_vm.$set(_vm.publication, "resultCategoryId", $$v)},expression:"publication.resultCategoryId"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }