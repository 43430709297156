<template>
    <v-dialog
        :value="dialogOpen"
        scrollable
		:loading="isLoading"
        max-width="500px"
        >
        <v-card tile>
            <v-card-title>
                {{panelTitle}}
            </v-card-title>
            <v-card-text>
                <v-radio-group v-model="selectedDepartment" column>
                    <v-radio v-for="item in departments" :key="item.id" :label="item.name" :value="item.id"></v-radio>
                    <v-radio label="Jiný" :value="altValue">
                        <template v-slot:label>
                            <DepartmentSearch :disabled="selectedDepartment != -1"  v-model="altUnit"></DepartmentSearch>
                        </template>
                    </v-radio>
                </v-radio-group>
            </v-card-text>
            <v-card-actions class="justify-space-between">
                <v-btn color="error" @click="close">Zavřít</v-btn>
                <v-btn color="success" @click="save" :disabled="!saveEnabled">{{ $t('btn.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { useDepartmentStore } from '../../stores/department-store';
import { usePersonStore } from '../../stores/person-store';
import DepartmentSearch from '../parts/DepartmentSearch.vue';


export default {
    name: 'PersonDepartmentModal',
    props: {
        panelTitle: {
            type: String,
            default: "Přehled útvarů",
        },
        uic: Number,
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        personStore: usePersonStore(),
        departmentStore: useDepartmentStore(),
        selectedDepartment: 0,
        isLoading: false,
        departments: [],
        saveEnabled: false,
        altUnit: null,
        altValue: -1
    }),
    mounted: function () {
    },
    watch: {
        departments(newValue, oldValue) {
            console.log("watcher", newValue);
            if (newValue && newValue.length > 1) {
                this.saveEnabled = true;
            }
            else {
                this.saveEnabled = false;
            }
        },
        altUnit( nVal, oVal) {
            if(this.selectedDepartment == -1) {
                this.saveEnabled = (this.altUnit != null);
            }
        }
    },
    methods: {
        open: async function (props) {
            console.log("dialog-open");
            this.dialogOpen = true;
            this.isLoading = false;
            if (!props) {
                return;
            }
            if (props.baseUnit && props.baseUnit.id) {
                this.selectedDepartment = props.baseUnit.id;
            }
            this.isLoading = true;
            let loadedDeps = await this.departmentStore.fetchForAuthor(this.uic, {}, () => {
                this.departments = this.departmentStore.getDepartments(this.uic);
                this.isLoading = false;
            });
            this.departments = this.departmentStore.getDepartments(this.uic);
            this.isLoading = false;

            let existsInLoaded = this.departments.find( f => f.id == props.baseUnit.id)
            if(!existsInLoaded) {
                let tmpDep = await this.departmentStore.get(props.baseUnit.id, {});
                this.altUnit = tmpDep;
                this.$nextTick(() => { this.selectedDepartment = -1; });
            }

            if (props.autoSearch) {
                this.$nextTick(() => { this.search(); });
            }
        },
        close: function (data) {
            this.dialogOpen = false;
            this.$emit("close-dialog", data);
        },
        save: function (person) {
            console.log("picker person", person);
            let unit = null;
            if(this.selectedDepartment == -1) {
                unit = this.altUnit;
            } else {
                unit = this.departments.find(f => this.selectedDepartment == f.id);
            }
            this.close(unit);
        },
        clear: function () {
            this.name = "";
        }
    },
    components: { DepartmentSearch }
}
</script>
