import { defineStore } from "pinia";
import DepartmentService from "../services/department-service";

export const useDepartmentStore = defineStore("departmentStore", {
    state: () => ({
        departments: [],
        searchQuery: {
			q: null,
		},
        authorDepartments: {},
        facultyDepartments: {},
    }),
    getters: {
        getItems() {
            return this.items
        },
        getDepartments: (state) => {
            return (uic) => state.authorDepartments[uic]
        },
        getFacultyDepartments: (state) => {
            return (id) => state.facultyDepartments[id]
        }
    },
    actions: {
        clearList() {
            this.departments = [];
        },
        async get(id, params, loadingCallBack) {
            let service = new DepartmentService();
            const result = await service.get(id, params, loadingCallBack)

            return result.data;
        },
        async fetch(params, loadingCallBack ) {
            let service = new DepartmentService();
            const results = await service.fetchAll(params, loadingCallBack);
            this.departments = results.data.data;
        },
        async fetchPubStats(id, params, loadingCallBack ) {
            let service = new DepartmentService();
            const results = await service.fetchPublicationStatistic(id, params, loadingCallBack);
            return results.data.data;
        },
        async fetchAuthorStats(id, params, loadingCallBack ) {
            let service = new DepartmentService();
            const results = await service.fetchAuthorStatistic(id, params, loadingCallBack);
            return results.data.data;
        },
        async fetchForAuthor(uic, params, loadingCallBack) {
            
            if(uic in this.authorDepartments) {
                console.log("deps", this.authorDepartments)
                return this.authorDepartments[uic];
            }

            let service = new DepartmentService();
            const results = await service.fetchForAuthor(uic, params, loadingCallBack);
            this.authorDepartments[uic] = results.data.data;
            return results.data.data;
        },
        async fetchForFaculty(id, params, loadingCallBack) {
            if(id in this.facultyDepartments) {
                return this.facultyDepartments[id];
            }

            let service = new DepartmentService();
            const results = await service.fetchForFaculty(id, params, loadingCallBack);
            this.facultyDepartments[id] = results.data.data;
            return results.data.data;
        },
        async downloadAuthorStats(id, params, loadingCallBack ) {
            let service = new DepartmentService();
            const results = await service.downloadAuthorStats(id, params, loadingCallBack);
        },
    }
})