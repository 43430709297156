<template>
	<span>
        <v-toolbar flat>
            <v-toolbar-title>{{ $t('views.prirazene-granty-k-publikaci') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="editable" color="primary" @click="modalOpen()">
               {{ $t('btn.pridat-grant') }} <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
		<v-simple-table v-if="grants.length > 0">
			<template v-slot:default>
				<thead>
                    <tr>
                        <th>{{$t("grants.name")}}</th>
                        <th>{{$t("grants.numberInternal")}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(grant, index) in grants">
                        <td>{{grant.name}}</td>
                        <td>{{grant.internalNumber}}</td>
                        <td>
                            <v-btn text icon @click="remove(grant)" v-if="editable">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
			</template>
		</v-simple-table>
        <v-alert v-if="grants.length == 0" type="info" text>{{ $t('messages.no-grants-assigned') }}</v-alert>
        <GrantSearchModal ref="grantModal" @close-dialog="modalClosed"></GrantSearchModal>
	</span>
</template>

<script>
import { useNotificationStore } from '../../stores/notification-store';
import GrantSearchModal from '../dialogs/GrantSearchModal.vue';

export default {
    name: "PublicationGrantManager",
    props: {
        errorMessages: Array,
		valueText: String,
        value: Number,
		grants: Array,
        editable: Boolean,
    },
    data: function () {
        return {
            timeoutId: null,
            search: "",
            isLoading: false,
            hasValue: false,
			foundItem: {},
            notifyService: useNotificationStore(),
			rules: {
				select: [
					v => !!v || 'Zadejte hledanou hodnotu',
				]
			}
        };
    },
    methods: {
        clear: function () {
            this.hasValue = false;
        },
		modalOpen: function() {
			this.$refs.grantModal.open()
		},
        updateValue: function () {
            this.$emit("input", this.foundItem);
        },
        remove: function(grant) {
            let grantIndex = this.grants.findIndex( i => i.id == grant.id )
            if(grantIndex >= 0) {
                this.grants.splice(grantIndex, 1)
            }
        },
		modalClosed: function(response) {
			console.log(response)

            let grant = this.grants.findIndex(g => g.id == response.id)
            if(grant < 0) {
                this.grants.push(response)
            } else {
                this.notifyService.addError({message: this.$t('messages.same-grant-already-assigned')})
            }
		},
    },
    components: { GrantSearchModal }
}
</script>