import { defineStore } from "pinia";
import GrantBudgetService from "../services/grant-budget-service";

export const grantBudgetStore = defineStore("grantBudgetStore", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
		},
    }),
    getters: {
        getItems() {
            return this.items
        }
    },
    actions: {
        async fetch(id, params) {
            let service = new GrantBudgetService();
            const results = await service.fetchAll(id, params);
            this.items = results.data.data;
        }
    }
})