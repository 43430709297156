<template>
    <v-dialog 
        :value="dialogOpen"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
        <v-card tile>
            <v-toolbar dark flat color="primary">
                <v-toolbar-title>Upravit publikaci</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'PublicationEditModal',
    props: {
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        publicationId: 0,
        isDraft: false,
        draftEntry: {},
    }),
    mounted: function() {
    },
    methods: {
        open: function(props) {
            console.log("pub form opened")
            this.dialogOpen = true

            if(!props) {return;}
        }, 
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
        pick: function(item) {
            this.close(item)
        },
        clear: function() {
            this.name = "";
            this.departmentStore.clearList()
        },
        publicationSaved: function() {
            this.close()
        }
    }

}
</script>
