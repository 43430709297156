var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"eventSubTypeId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.eventSubtype,"label":_vm.$t('publications.general.event-type'),"error-messages":errors,"required":""},model:{value:(_vm.publication.eventSubTypeId),callback:function ($$v) {_vm.$set(_vm.publication, "eventSubTypeId", $$v)},expression:"publication.eventSubTypeId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"venue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.venue'),"error-messages":errors},model:{value:(_vm.publication.venue),callback:function ($$v) {_vm.$set(_vm.publication, "venue", $$v)},expression:"publication.venue"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"countryId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.country,"label":_vm.$t('publications.general.country'),"error-messages":errors,"required":""},model:{value:(_vm.publication.countryId),callback:function ($$v) {_vm.$set(_vm.publication, "countryId", $$v)},expression:"publication.countryId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dateFrom,"offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateFrom=$event},"update:return-value":function($event){_vm.dateFrom=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('publications.event.date-from'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(_vm._s(_vm.$t('btn.close')))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dateFrom)}}},[_vm._v(_vm._s(_vm.$t('btn.insert')))])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuTo",attrs:{"close-on-content-click":false,"return-value":_vm.dateTo,"offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateTo=$event},"update:return-value":function($event){_vm.dateTo=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('publications.event.date-to'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuTo = false}}},[_vm._v(_vm._s(_vm.$t('btn.close')))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuTo.save(_vm.dateTo)}}},[_vm._v(_vm._s(_vm.$t('btn.insert')))])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"noOfParticipants","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.no-of-participants'),"error-messages":errors},model:{value:(_vm.publication.noOfParticipants),callback:function ($$v) {_vm.$set(_vm.publication, "noOfParticipants", $$v)},expression:"publication.noOfParticipants"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"noOfForeignParticipants","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.no-of-foreign-participants'),"error-messages":errors},model:{value:(_vm.publication.noOfForeignParticipants),callback:function ($$v) {_vm.$set(_vm.publication, "noOfForeignParticipants", $$v)},expression:"publication.noOfForeignParticipants"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"eventTypeId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.eventType,"label":_vm.$t('publications.event.event-sub-type'),"error-messages":errors,"required":""},model:{value:(_vm.publication.eventTypeId),callback:function ($$v) {_vm.$set(_vm.publication, "eventTypeId", $$v)},expression:"publication.eventTypeId"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }