<template>
    <v-container fluid>
        <v-timeline align-top dense>
            <v-timeline-item v-for="item in logItems" :key="item.id" small :color="item.color" :icon="item.icon">
                <template v-slot:opposite>
                    {{ item.date }}
                </template>
                <v-row>
                    <v-col cols="5">{{ item.date }}</v-col>
                    <v-col>{{ item.label }}</v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
    </v-container>
</template>

<script>
import { authStore } from '@/stores/user'
import PublicationTagIcon from './PublicationTagIcon.vue';
import PublicationService from '../../services/publication-service';

export default {
    name: "PublicationLogTimeline",
    props: {
        publicationId: [Number,String],
    },
    data: function () {
        return {
            userStore: authStore(),
            publicationService: new PublicationService(),
            logItems: [],
        };
    },
    watch: {
        publicationId: function(nV,oV) {
            this.fetchLog();
        }
    },
    methods: {
        fetchLog: function() {
            console.log("fetch tags")

            if(this.publicationId <= 0) {
                return;
            }

            this.publicationService.fetchLogs(this.publicationId)
                .then(r => {
                    console.log("q", r)
                    this.logItems = r.data.data
                })
        },
        tagIcon: function (item) {
            switch (item.code) {
                case "checked":
                    return "mdi-tag-check";
            }
        },
        tagColor: function (item) {
            switch (item.code) {
                case "checked":
                    return "green";
            }
        }
    },
    components: { PublicationTagIcon, PublicationTagIcon }
}
</script>