<template>
	<div>
		<v-row>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.artCategory" readonly label="Kategorie"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.artObligation" readonly label="Závažnost a význam"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.artContext" readonly label="Institucionální kontext"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.artScope" readonly label="Rozsah"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';

export default {
	name: 'ArtDetail',
	props: {
		publication: Object,
	},
    components: {
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		publicationSubtype: {},
		levelOfSecrecy: {},
		researchReport: {},
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.publicationSubtype = this.publication.art

		}
	},
}

</script>;