import { axiosInst } from "../main";

export default class ListsService {
    
    async fetch( params )  {
        const response = await axiosInst.get('/lists/complete', {
            params: params
        })
        return response
    }
}