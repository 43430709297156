var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"accessTypeId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.accessType,"label":_vm.$t('publication.presentation.access-type'),"error-messages":errors,"required":""},model:{value:(_vm.publication.accessTypeId),callback:function ($$v) {_vm.$set(_vm.publication, "accessTypeId", $$v)},expression:"publication.accessTypeId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"ISBN"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.isbn'),"hint":"","error-messages":errors},model:{value:(_vm.publication.isbn),callback:function ($$v) {_vm.$set(_vm.publication, "isbn", $$v)},expression:"publication.isbn"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"vid":"dataMedium"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.presentation.data-medium'),"hint":"","error-messages":errors},model:{value:(_vm.publication.dataMedium),callback:function ($$v) {_vm.$set(_vm.publication, "dataMedium", $$v)},expression:"publication.dataMedium"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"placeOfIssue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.place-of-issue'),"hint":"","error-messages":errors},model:{value:(_vm.publication.placeOfIssue),callback:function ($$v) {_vm.$set(_vm.publication, "placeOfIssue", $$v)},expression:"publication.placeOfIssue"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"editionName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.presentation.result-version'),"hint":"","error-messages":errors},model:{value:(_vm.publication.editionName),callback:function ($$v) {_vm.$set(_vm.publication, "editionName", $$v)},expression:"publication.editionName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"publisherName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.presentation.customer-name'),"hint":"","error-messages":errors},model:{value:(_vm.publication.publisherName),callback:function ($$v) {_vm.$set(_vm.publication, "publisherName", $$v)},expression:"publication.publisherName"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }