import { defineStore } from "pinia";
import PublicationTypeService from "../services/publication-type-service";

export const publicationTypeStore = defineStore("publicationTypeStore", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
		},
    }),
    getters: {
        getItems() {
            return this.items
        }
    },
    actions: {
        async fetch(params) {
            let service = new PublicationTypeService();
            const results = await service.fetchAll(params);
            this.items = results.data.data;
        }
    }
})