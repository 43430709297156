<template>
    <v-container>
		<v-row>
			<v-col hover="true" cols="4">
				<v-card :to="{name: 'settings-journals'}" class="mx-auto" height="150px" :disabled="!authStore.hasRole(['ADMINISTRATOR'])">
					<v-card-title>
						Správa časopisů
					</v-card-title>
					<v-card-text>
						<div>Správa časopisů</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col hover="true" cols="4">
				<v-card :to="{name: 'settings-publishers'}" class="mx-auto" height="150px" :disabled="!authStore.hasRole(['ADMINISTRATOR'])">
					<v-card-title>
						Vydavatelé časopisů
					</v-card-title>
					<v-card-text>
						<div>Přehled vydavatelů časopisů</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col hover="true" cols="4">
				<v-card :to="{name: 'settings-ford'}" class="mx-auto" height="150px" :disabled="!authStore.hasRole(['ADMINISTRATOR'])">
					<v-card-title>Přehled Ford</v-card-title>
					<v-card-text>
						<div>Vědní oblasti dle Frascati Manual</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col hover="true" cols="4">
				<v-card :to="{name: 'settings-cep'}" class="mx-auto" height="150px" :disabled="!authStore.hasRole(['ADMINISTRATOR'])">
					<v-card-title>CEP</v-card-title>
					<v-card-text>
						<div>Seznam CEP položek</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col hover="true" cols="4">
				<v-card :to="{name: 'settings-grant-publishers'}" class="mx-auto" height="150px" :disabled="!authStore.hasRole(['ADMINISTRATOR'])">
					<v-card-title>Poskytovatelé grantů</v-card-title>
					<v-card-text>
						<div>Seznam poskytovatelů grantů</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col hover="true" cols="4">
				<v-card :to="{name: 'settings-grant'}" class="mx-auto" height="150px" :disabled="!authStore.hasRole(['ADMINISTRATOR'])">
					<v-card-title>Granty</v-card-title>
					<v-card-text>
						<div>Správa grantů</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col hover="true" cols="4"> 
				<v-card :to="{name: 'settings-patent-publishers'}" class="mx-auto" height="150px" :disabled="!authStore.hasRole(['ADMINISTRATOR'])">
					<v-card-title>Vydavatelé patentů</v-card-title>
					<v-card-text>
						<div>Seznam vydavatelů patentů</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col hover="true" cols="4"> 
				<v-card :to="{name: 'settings-lists'}" class="mx-auto" height="150px" :disabled="!authStore.hasRole(['ADMINISTRATOR'])">
					<v-card-title>Ostatní číselníky</v-card-title>
					<v-card-text>
						<div>Přehled systémových číselníků a seznamů</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col hover="true" cols="4"> 
				<v-card :to="{name: 'settings-lists'}" class="mx-auto" height="150px" :disabled="!authStore.hasRole(['ADMINISTRATOR'])">
					<v-card-title>Ostatní číselníky</v-card-title>
					<v-card-text>
						<div>Přehled systémových číselníků a seznamů</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col hover="true" cols="4" v-if="false"> 
				<v-card :to="{name: 'settings-rights'}" class="mx-auto" height="150px" :disabled="!authStore.hasRole(['ADMINISTRATOR'])">
					<v-card-title>Práva</v-card-title>
					<v-card-text>
						<div>Nastavení práv</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col hover="true" cols="4"> 
				<v-card :to="{name: 'user-manager'}" class="mx-auto" height="150px" :disabled="!authStore.hasRole(['ADMINISTRATOR', 'FACULTY_MANAGER', 'DEPARTMENT_MANAGER'])">
					<v-card-title>Správa uživatelů</v-card-title>
					<v-card-text>
						<div>Nastavení uživatelů</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { authStore } from '../stores/user';

 

export default {
	name: 'Settings',
	props: {
		panelTitle: String,
	},
	data: () => ({
		authStore: authStore(),
	}),
}

</script>