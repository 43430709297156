<template>
	<div>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="accessTypeId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.accessTypeId"
					:items="list.accessType"
					:label="$t('publication.presentation.access-type')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
				</v-col>
				<v-col cols="3">
					<validation-provider vid="ISBN" v-slot="{errors}">
					<v-text-field v-model="publication.isbn"
						:label="$t('publications.general.isbn')"
						hint=""
						:error-messages="errors"
						></v-text-field>
					</validation-provider>
				</v-col>
				<v-col cols="3">
					<validation-provider vid="dataMedium" v-slot="{errors}">
					<v-text-field v-model="publication.dataMedium"
						:label="$t('publications.presentation.data-medium')"
						hint=""
						:error-messages="errors"
						></v-text-field>
					</validation-provider>
				</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<validation-provider vid="placeOfIssue" v-slot="{errors}">
				<v-text-field v-model="publication.placeOfIssue"
					:label="$t('publications.general.place-of-issue')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="editionName" v-slot="{errors}">
				<v-text-field v-model="publication.editionName"
					:label="$t('publications.presentation.result-version')"
					hint=""
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="4">
				<validation-provider vid="publisherName" v-slot="{errors}">
				<v-text-field v-model="publication.publisherName"
					:label="$t('publications.presentation.customer-name')"
					hint=""
					:error-messages="errors" 
					></v-text-field>
 				</validation-provider>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'PresentationForm',
	props: {
		defaults: {type: Object, default: () => {return {q: '1'}}},
		errors: Array,
	},
	data: () => ({
		formTypes: [],
		publication: {},
		list: listsStore(),
		menu: false
	}),
	mounted: async function() {
		await this.loadData();
		//this.publication = this.defaults
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				this.publication = data
			}
		},
		getData: function() {
			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;