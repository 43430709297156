<template>
	<div>
		<v-chip class="mr-1" v-for="unit in author.baseUnits" :key="unit.id" small>
			{{unit.abbr}}
		</v-chip>
	</div>
</template>

<script>
import { useDepartmentStore } from '../../stores/department-store';

export default {
    name: "AuthorDepartmentsChips",
    props: {
        author: {},
    },
    data: function () {
        return {
        };
    },
	mounted: async function() {
	},
    methods: {
    },
    components: { }
}
</script>