<template>
	<v-card class="pa-4 ma-4">
		<v-toolbar class="elevation-0">
			<v-btn icon :to="{name: 'settings-grant'}"><v-icon>mdi-arrow-left</v-icon></v-btn>
			<v-toolbar-title>Detail grantu</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn class="mx-2" disabled><v-icon>mdi-lock</v-icon> Upravit</v-btn>
			<v-btn class="mx-2" disabled>Smazat</v-btn>
			<reload-button-component class="mx-2" v-on:reload="load()" />
		</v-toolbar>
		<v-card-text>
			<v-alert class="mt-2" type="info" text border="left" >
				Importovaný grant z EP, editace je zakázana.
			</v-alert>

			<v-card-subtitle>Informace o gantu</v-card-subtitle>
			<v-row>
				<v-col cols="8">
					<v-text-field readonly label="Název grantu"  v-model="grantStore.grant.name"></v-text-field>
					<v-text-field readonly label="Název grantu (en)" v-model="grantStore.grant.nameEn"></v-text-field>
					<v-textarea rows="2" readonly label="Popis" v-model="grantStore.grant.description"></v-textarea>
					<v-textarea rows="2" readonly label="Popis (en)" v-model="grantStore.grant.descriptionEn"></v-textarea>
				</v-col>
				<v-col>
					<v-text-field readonly label="Od" v-model="grantStore.grant.yearFrom"></v-text-field>
					<v-text-field readonly label="Do" v-model="grantStore.grant.yearTo"></v-text-field>
					<v-text-field readonly label="Interní číslo grantu" v-model="grantStore.grant.internalNumber"></v-text-field>
					<v-text-field readonly label="Číslo grantu" v-model="grantStore.grant.number"></v-text-field>
					<v-text-field readonly label="Zdroj financování" v-model="grantStore.grant.fundingSourceName"></v-text-field>
					<v-text-field readonly label="Rozpočet" v-model="grantStore.grant.budget" suffix="Kč"></v-text-field>

					<v-card-subtitle>Metadata</v-card-subtitle>
					<v-switch label="Importovaný grant" input-value="true" readonly></v-switch>

				</v-col>
			</v-row>
			<v-card-subtitle>Rozpočet</v-card-subtitle>
			<v-alert v-if="grantBudgetStore.items.length == 0" class="mt-2" type="info" border="top" text>
				Rozpočet pro daný grant nenalezen
			</v-alert>

			<v-simple-table v-if="grantBudgetStore.items.length > 0">
				<template v-slot:default>
					<thead>
						<tr>
							<th>Rok</th>
							<th>Finance (Kč)</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="budget in grantBudgetStore.items">
							<td>{{ budget.year }}</td>
							<td>{{ budget.budget }} Kč</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
	</v-card>
</template>

<script> 
import { axiosInst } from '../main'
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { useGrantStore } from '../stores/grant-store'
import { grantBudgetStore } from '../stores/grant-budget-store'

export default {
	components: { ReloadButtonComponent },
	name: 'GrantDetail',
	props: {
		panelTitle: {
			type: String,
			default: "Detail grantu"
		},
		grantId: {
			type: [String, Number], //@fixme - fix type to single type
			required: true
		}
	},
	data: () => ({
		search: '',
		grantStore: useGrantStore(),
		grantBudgetStore: grantBudgetStore(),
		grant: {},
	}),
	mounted: function() {
		this.load()
		this.loadBudget()
	},
	methods: {
		load: function() {
			this.grantStore.get(this.grantId)
			this.grant = this.grantStore.grant;
		},
		loadBudget: function() {
			this.grantBudgetStore.fetch(this.grantId, {})
		}
	}
}

</script>