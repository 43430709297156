<template>
    <v-dialog
        :value="dialogOpen"
        persistent
        scrollable
        max-width="900px"
        >
        <v-card tile>
            <v-toolbar flat color="primary" dark>
                <v-text-field v-model="name" label="Zadejte název publikace nebo id publikace" 
                        autofocus
                        hide-details
                        single-line
			            clearable 
                        :disabled="isLoading"
                        :loading="isLoading"
                        @keyup.enter="search"
                        required></v-text-field>
                <template v-slot:append>
                    <v-btn icon @click="search()"><v-icon>mdi-magnify</v-icon></v-btn>
                </template>
            </v-toolbar>
            <v-expand-transition>
                <v-card-text v-if="hasResults">
                    <v-simple-table height="500">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Název</th>
                                    <th>Rok</th>
                                    <th>Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="publication in foundPublications" :key="publication.id"  style="cursor: pointer;">
                                    <td>{{publication.id}}</td>
                                    <td>{{publication.name}}</td>
                                    <td>{{publication.yearOfPublication}}</td>
                                    <td>{{publication.publicationTypeLabelShort}}</td>
                                    <td>
                                        <v-btn @click="pick(publication)" small icon><v-icon>mdi-arrow-right</v-icon></v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-expand-transition>
            <v-card-actions>
                <v-btn color="error" @click="close">{{ $t('btn.close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import emitter from '../../plugins/mitt';
import PublicationService from '../../services/publication-service';
import { usePersonStore } from '../../stores/person-store';
import { publicationStore } from '../../stores/publication-store';
import AuthorDepartmentsChips from '../parts/AuthorDepartmentsChips.vue';


export default {
    components: { AuthorDepartmentsChips },
    name: 'GlobalSearchModal',
    props: {
        enabled: Boolean,
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        publicationStore: publicationStore(),
        publicationService: new PublicationService(),
        personStore: usePersonStore(),
        isLoading: false,
        hasResults: false,
        foundPublications: [],
    }),
    mounted: function() {
        window.addEventListener('keyup', this.keyboardHandler)
        emitter.on("application.globalSearch.open", (f) => {
            this.open();
        })
    },
    methods: {
        keyboardHandler: function(ev) {
            console.log("key clicked")
            if(this.enabled && ev.code == "Slash") {
                this.open();
            }
        },
        search: function() {
            let search = this.name

            if(search.length >= 2) {
                this.isLoading = true

                let pubQuery = {q: this.name}
                this.publicationService.query(pubQuery)
                    .then( (r) => {
                        console.log("global search result", r.data)
                        this.foundPublications = r.data.data;
                        this.hasResults = true;
                    } ) 
                    .finally( () => {
                        this.isLoading = false;
                    })
            }
        },
        open: function() {
            console.log("dialog-open");
            //this.$refs.searchInput.focus()
            this.dialogOpen = true
        }, 
        close: function( data ) {
            this.dialogOpen = false
        },
        clear: function() {
            this.name = "";
        },
        pick: function(publication) {
            this.$router.push( {name: 'publication-detail', params: {id: publication.id}} )
            this.close();
        }
    }

}
</script>
