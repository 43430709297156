import { axiosInst } from "../main";
import BaseService from "./base-service";

export default class FacultyService extends BaseService {
    
    async get( id, params, loadingCallback )  {
        const response = await axiosInst.get(`/faculties/${id}`, {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchPublicationStatistic( id, params, loadingCallback )  {
        const response = await axiosInst.get(`/faculties/${id}/stats/publications`, {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchAuthorStatistic( id, params, loadingCallback )  {
        const response = await axiosInst.get(`/faculties/${id}/stats/authors`, {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchDepartmentStatistic( id, params, loadingCallback )  {
        const response = await axiosInst.get(`/faculties/${id}/stats/departments`, {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchAll( params, loadingCallback )  {
        const response = await axiosInst.get('/faculties', {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async downloadAuthorStats( id, params, loadingCallback )  {
        const response = await axiosInst.get(`/reports/faculties/${id}`, {
            responseType: 'blob',
            params: params
        }).then( (r) => {
            const href = URL.createObjectURL(r.data);
            const link = document.createElement('a');
            link.href= href;
            link.setAttribute('download', `cv-file-faculty-${id}.${params.format}`)
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href)
        } )
        
        this.handleCallback(loadingCallback)
        return response
    }
}