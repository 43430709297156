<template>
	<div>
		<v-row>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.accessType" readonly label="Druh přístupu"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.placeOfIssue" readonly label="Místo vydání"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.publisherName" readonly label="Název objednatele"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.isbn" readonly label="ISBN"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.dataMedium" readonly label="Identifikační číslo nosiče"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.editionName" readonly label="Verze výsledku"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';

export default {
	name: 'PresentationDetail',
	props: {
		publication: Object,
	},
    components: {
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		publicationSubtype: {},
		levelOfSecrecy: {},
		researchReport: {},
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.publicationSubtype = this.publication.presentation

		}
	},
}

</script>;