<template>
	<v-container fluid>
		<v-row>
			<v-col cols="3">
				<v-card flat tile>
					<v-card-text>
						<v-row>
							<v-col>
								<v-btn @click="openFilters" text> {{ $t('btn.filter') }} <v-icon>mdi-filter</v-icon></v-btn>
							</v-col>
							<v-col>
							</v-col>
							<v-spacer></v-spacer>
							<v-col>
								<v-btn @click="search()" class="mx-2">Vyhledat</v-btn>
							</v-col>
						</v-row>
					</v-card-text>	
					<v-card-text>
						<v-row align-center >
							<v-col>
								<v-select  class="mx-2" v-model="query.facultyId"
									:items="listsStore.faculties"
									item-text="abbr"
									item-value="id"
									label="Fakulta"
									:loading="departmentLoading"
									required
									@change="facultyPicked()"
									></v-select>
							</v-col>
							<v-col cols="2">
								<v-btn icon @click="toggleMultiselection()">
									<v-icon v-if="!viewPreferences.selectMultipleItems">mdi-checkbox-multiple-blank-outline</v-icon>
									<v-icon v-if="viewPreferences.selectMultipleItems">mdi-checkbox-multiple-marked-outline</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-text  style="height: calc(100vh - 500px); overflow-y: auto;">
						<v-treeview 
							return-object
							open-on-click
							transition
							activatable
							dense
							:selectable="viewPreferences.selectMultipleItems"
							:multiple-active="viewPreferences.selectMultipleItems"
							selection-type="leaf"
							:items="treeItems"
							:load-children="fetchUsers"
							:active.sync="treePickedItems"
							:open.sync="treeOpenItems"
							v-model="treeModel"
						>
						</v-treeview>
					</v-card-text>
				</v-card>
			</v-col>
			<v-divider vertical></v-divider>
			<v-col>
				<v-row>
					<v-col>
						Nastavení filtru: publikace ve stavu <v-chip small v-for="s in filterStates" :key="s">{{ s }}</v-chip> 
						z roku <v-chip small v-for="s in filterYears" :key="s">{{ s }}</v-chip>, 
						typu <v-chip small v-for="s in filterTypes" :key="s">{{ s }}</v-chip>,
						fakulty/organizační jednotky <v-chip small v-for="s in filterUnits" :key="s">{{ s }}</v-chip>.
					</v-col>
				</v-row>
				<v-row>
					<v-spacer></v-spacer>
					<v-col>
						<v-menu offset-y class="mr-2">
							<template v-slot:activator="{on, attrs}">
								<v-btn v-bind="attrs" v-on="on">
									Hromadné operace
								</v-btn>
							</template>			
							<v-list>
								<v-list-item link :disabled="!(selectedPublications.length > 0)" @click="approveSelectedAs(2)">
									Hromadně schválit vybrané jako vedoucí katedry
								</v-list-item>
								<v-list-item link :disabled="!(selectedPublications.length > 0)" @click="approveSelectedAs(3)" v-if="userStore.hasRole(['ADMINISTRATOR'])">
									Hromadně schválit vybrané jako proděkan
								</v-list-item>
							</v-list>					
						</v-menu>
						<v-menu offset-y class="ml-2">
							<template v-slot:activator="{on, attrs}">
								<v-btn v-bind="attrs" v-on="on">
									Exporty a sestavy
								</v-btn>
							</template>
							<v-list>
								<v-list-item link @click="exportCitations">
									Exportovat citace
								</v-list-item>
								<v-list-item link @click="exportRecords">
									Exportovat záznamy (XLSX)
								</v-list-item>
								<v-list-item link @click="exportRecordsByAuthors">
									Export dle autorů (XLSX)
								</v-list-item>
								<v-list-item link @click="exportRecordsByProjects">
									Export dle projektů (XLSX)
								</v-list-item>
								<v-list-item link @click="exportRecordsForAop">
									Export APC (XLSX)
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>
				<v-data-table class="mt-3" :headers="headers" :items="approvalStore.items" 
					:items-per-page="25" show-select v-model="selectedPublications"
					:expanded.sync="expanded" show-expand>
					<template v-slot:item.type="{item}">
						<v-chip small>{{ item.publicationTypeLabelShort }}</v-chip>
					</template>
					<template v-slot:item.state="{item}">
						<publication-state :stateId="item.publicationStateId" :label="item.publicationState"></publication-state>
					</template>
					<template v-slot:item.tag="{item}">
						<publication-tag-icons :tags="item.tags" :publication-id="item.id"></publication-tag-icons>
					</template>
					<template v-slot:expanded-item="{headers, item}">
						<td :colspan="headers.length">
							<publication-view :publication="item" @changeState="detailChangeState" ></publication-view>
						</td>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<publication-filters-modal ref="filters"></publication-filters-modal>
	</v-container>
</template>

<script> 
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { authStore } from '../stores/user'
import DepartmentPicker from './parts/DepartmentPicker.vue'
import { listsStore } from '../stores/lists-store'
import { useApprovalStore } from '../stores/approval-store'
import { useNotificationStore } from '../stores/notification-store'
import { useDepartmentStore } from '../stores/department-store'
import PublicationView from './PublicationView.vue'
import PublicationDetailShort from './parts/PublicationDetailShort.vue'
import DepartmentService from '@/services/department-service.js'
import PublicationService from '@/services/publication-service.js'
import PublicationFiltersModal from './dialogs/PublicationFiltersModal.vue'
import PublicationState from './parts/PublicationState.vue'
import PublicationTagIcons from './parts/PublicationTagIcons.vue'

export default {
	components: { ReloadButtonComponent, DepartmentPicker, PublicationView, PublicationDetailShort, PublicationFiltersModal, PublicationState, PublicationTagIcons },
	name: 'ApprovalManagerSuper',
	props: {
		
	},
	data: () => ({
		uic: 0,
		headers: [
			{text: "#", value: 'id'},
			{text: "Název", value: 'name'},
			{text: "Typ", value: 'type', sortable: false},
			{text: "Tag", value: 'tag', sortable: false},
			{text: "Stav", value: 'state', sortable: false},
			{text: "Rok", value: 'yearOfPublication'},
		],
		expanded: [],
		selectedPublications: [],
		publications: [],
		approvalStore: useApprovalStore(),
		query:  useApprovalStore().searchQuery,
		listsStore: listsStore(),
		userStore: authStore(),
		notificationStore: useNotificationStore(),
		departmentStore: useDepartmentStore(),
		departmentService: new DepartmentService(),
		publicationService: new PublicationService(),
		baseUnit: {},
		faculty: 0,
		treeItems: [],
		treeModel: [],

		children: [],
		workingDepartments: [],
		selectedDepartments: [],
		departmentLoading: false,
		treePickedItems: [],
		treeOpenItems: [],
		treeSelection: [],
		viewPreferences: useApprovalStore().preferences,
		treeKey: 0,
		queryDesc: {
			states: [],
			years: [],
			types: [],
		},

		tags: [
			{value: "vavapproved", text: "Zkontrolováno"},
			{value: "vavrejected", text: "Chybný záznam"},
			{value: "imported", text: "Importovaný záznam (WOS/Scopus)"},
		],

		outputGrouping: [
			{value: 0, text: "Nesekupovat"},
			{value: 1, text: "Seskupit po útvarech"},
			{value: 2, text: "Seskupit po lidech"}
		]
	}),
	mounted: async function() {
		await this.listsStore.fetch();

		if( this.query.facultyId > 0 ) {
			this.facultyPicked();
		}
		console.log("mounted manager")
	},
	watch: {
		treeSelection: {
			handler(n) {
				this.treeKey++;
			}
		},
		treePickedItems: function( n, o) {
			this.search()
		}
	},
	computed: {
		/*
		treeItems: function() {
			
		}
		*/
		filterStates: function() {
			let out = []

			if(this.query.publicationStateId <= 0) {
				out.push("Nezáleží - všechny stavy");
			} else {
				out = this.listsStore.publicationStateList.filter( f => f.value == this.query.publicationStateId ).map( x => x.text )
				console.log(out)
			}

			return out;
		},
		filterYears: function() {
			let out = []

			if(this.query.years.length == 0) {
				out.push("Nezáleží");
			} else {
				out = this.query.years;
			}

			return out;
		},
		filterTypes: function() {
			let out = []

			if(this.query.publicationTypeId <= 0) {
				out.push("Nezáleží - všechny typy");
			} else {
				out = this.listsStore.publicationTypeList.filter( f => f.id == this.query.publicationTypeId ).map( x => x.name )
			}

			return out;
		},
		filterUnits: function() {
			let out = []

			if(this.query.facultyId <= 0) {
				out.push("Nezáleží");
			} else {
				out = this.listsStore.faculties.filter( f => f.id == this.query.facultyId ).map( x => x.abbr )
			}

			return out;
		}
	},
	methods: {
		load: function() {
			//this.publicationStore.fetch( {uic: this.userStore.uic} )
		},
		toggleMultiselection: function() {
			this.viewPreferences.selectMultipleItems = !this.viewPreferences.selectMultipleItems;

			if(this.viewPreferences.selectMultipleItems) {
				this.treeOpenItems = [];
				this.treePickedItems = [];
			} else {
				this.treeModel = [];
			}
		},
		search: async function() {
			this.approvalStore.clear();
			
			/*
			if(this.faculty > 0) {
				this.workingDepartments = await this.departmentStore.fetchForFaculty(this.faculty)
			}
			*/
			//console.log(this.query)
			
			if(this.viewPreferences.selectMultipleItems) {
				let units = this.treeModel.filter( f => f.id < 1000).map( f => f.id )
				let uics = this.treeModel.filter( f =>  f.id > 40000).map( f => f.id )
				//this.query.baseUnitIds = units;
				this.query.uics = uics;
			} else {
				let units = this.treeOpenItems.filter( f => f.id < 40000).map( f => f.id )
				let uics = this.treePickedItems.filter( f =>  f.id > 40000).map( f => f.id )
				this.query.baseUnitIds = units;
				this.query.uics = uics;
			}

			if(this.treeSelection.length > 0) {
				console.log(this.query)
			}

			if(this.query.baseUnitIds.length > 0 || this.query.uics.length > 0 || this.query.facultyId > 0 ) {
				this.approvalStore.query( this.approvalStore.searchQuery, {} );
			} else {
				this.notificationStore.addError({message: "Není vybrán žádný útvar ani autor pro vyhledání záznamů."})
			}
		},
		personPicked: function(data) {
			if(data) {
				this.pickedPerson = data;
				this.query.pickedPerson = data;
				this.query.uic = data.uic;
			}
		},
		facultyPicked: async function() {

			if(this.query.facultyId > 0) {
				this.departmentLoading = true
				this.workingDepartments = await this.departmentStore.fetchForFaculty(this.query.facultyId)			
				let tmpDeps = this.workingDepartments.map( (v) => {
					this.$set(v, "children", [])
					return v;
				} )
				this.$set(this, "treeItems", [])
				this.treeItems.push(...tmpDeps)
				this.departmentLoading = false
			}
		},
		unitPicked: function(data) {
			if(data) {
				this.baseUnit = data;
				this.query.baseUnitId = data.id;

				this.approvalStore.pickedBaseUnit = this.baseUnit
			}
		},
		approveSelectedAs: async function(type) {
			console.log("approving", this.selectedPublications.length)

			if(this.selectedPublications.length > 0) {
				console.log("approving")
				let totalToApprove = this.selectedPublications.length;
				let approved = 0;
				for (const [i,p] of this.selectedPublications.entries()) {
					await this.approvalStore.changeState( p.id, type, "", {}, () => {
						this.notificationStore.addSuccess({message: "Publikace schválena"})
						approved++;
					})
				}
				/*
				this.notificationStore.addSuccess({message: `Schváleno ${approved} publikací z celkem ${totalToApprove}`})
				*/
				this.selectedPublications = [];
			}
		},
		fetchUsers: async function(item) {
			return new Promise( (resolve, reject) => {
				this.departmentService.fetchAuthors( item.id )
					.then( r => {
						if( r.data.count > 0 ) {
							let tmpChild = r.data.data.map( m => {
								m.name = m.surname + " " + m.name
								m.id = m.uic
								return m
							} )
							item.children.push(...tmpChild)
						}
					resolve(true)
				}).catch( err => {
					reject(err)
				} )
			} )
		},
		openFilters: function() {
			this.$refs.filters.open();
		},
		detailChangeState: function(ev) {
			console.log(ev)
			let [data, type] = ev
			console.log(ev, data, type)
			if(data) {
				this.approvalStore.changeState(data.id, type, "", {}, () => {
					this.notificationStore.addSuccess({message: "Publikace schválena"})
					
					let index = this.expanded.filter( t => t.id = data.id )
					this.expanded.splice(index, 1)
					
					//let pubIndex = this.approvalStore.items.filter( t => t.id = data.id )
					//this.approvalStore.items.splice(pubIndex, 1)
					this.search()
				} )
			}
		},
		exportCitations: function() {
			this.publicationService.downloadPublicationQueryCitations(this.query, {format: "docx"})
		},
		exportRecords: function() {
			this.publicationService.downloadPublicationExport(this.query, {format: "xlsx"})
		},
		exportRecordsByAuthors: function() {
			this.publicationService.downloadPublicationByAuthorsExport(this.query, {format: "xlsx"})
		},
		exportRecordsByProjects: function() {
			this.publicationService.downloadPublicationByProjectsExport(this.query, {format: "xlsx"})
		},
		exportRecordsForAop: function() {
			this.publicationService.downloadPublicationExport(this.query, {format: "xlsx", template: "apc"})
		},
		pickDepartment: function(item) {
			console.log(item)
		}
	},
}

</script>;