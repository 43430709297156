import { axiosInst } from "../main";

export default class PatentPublisherService {
    
    async fetchAll( params )  {
        const response = await axiosInst.get('/lists/patent-publishers', {
            params: params
        })
        return response
    }
}