<template>

    <v-dialog
        :value="dialogOpen"
        scrollable
        persistent
        max-width="800px"
        >
        <v-card tile>
            <v-toolbar flat dark color="primary">
                <v-toolbar-title>{{ $t('dialogs.author-contribution') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="pt-4">
                <v-form ref="contributionForm">
                <v-row wrap justify="center" class="mt-2" v-for="author in localAuthors" :key="getAuthorId(author)">
                    <v-col>
                        {{ author.displayName }}
                    </v-col>
                    <v-col>
                        <v-btn icon @click="toggleLock(author)"><v-icon>{{ getLockedIcon(author) }}</v-icon></v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-slider @change="sliderChanged(author)" v-model="author.contribution" inverse-label 
                            :rules="rules" step="1" min="0" max="100">
                            <template v-slot:append>
                                <v-text-field readonly class="mt-0 pt-0" style="width: 70px;" v-model="author.contribution" hide-details single-line type="number"></v-text-field>
                            </template>
                        </v-slider>
                    </v-col>
                    
                </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="close">{{ $t('btn.close') }}</v-btn> 
                <v-spacer></v-spacer>
                <v-btn color="success" :disabled="!submitEnabled" @click="saveContribution">{{ $t('btn.set') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "AuthorContributionModal",
    props: {
        authors: Array,
    },
    data: function () {
        return {
            localAuthors: [],
            dialogOpen: false,
            rules: [this.validation],
            submitEnabled: false,
            lockedAuthors: [],
            maxTotalContribution: 100,
            
        };
    },
    computed: {
        
    },
    mounted: function() {
        console.log("mounted author modal")
    },
    methods: {
        sliderChanged: function(author) {
            this.computeContribution(author);
            this.$refs.contributionForm.validate();
            this.submitEnabled = this.validation();
        },
        computeContribution: function(changedOnAuthor) {
            let lockedAuthors = [...this.lockedAuthors, this.getAuthorId(changedOnAuthor)]

            console.log(lockedAuthors);

            let sumOfLockedAuthors = this.localAuthors.filter( v => lockedAuthors.includes( this.getAuthorId(v) ) ).reduce( ( acc, val ) => acc + val.contribution, 0 )
            let contributionLeft =this.maxTotalContribution - sumOfLockedAuthors;

            let nonLockedAuthors = this.localAuthors.filter( v => !lockedAuthors.includes( this.getAuthorId(v) ) );
            
            if(nonLockedAuthors.length > 0) {
                let nonLockedAuthorContribution =  Math.floor(contributionLeft / nonLockedAuthors.length);
                nonLockedAuthors.forEach( a => a.contribution = nonLockedAuthorContribution )
            }
        },
        getAuthorId: function(author) {
            if(author.id >0 ) {
                return author.id;
            }

            return author.uic;
        },
        getLockedIcon: function(author) {
            if(this.lockedAuthors.includes(this.getAuthorId(author))) {
                return "mdi-lock"
            } else {
                return "mdi-lock-open-variant"
            }
        },
        toggleLock: function(author) {
            if( !this.lockedAuthors.includes( this.getAuthorId(author) ) ) {
                this.lockedAuthors.push( this.getAuthorId(author) );
            } else {
                let index = this.lockedAuthors.indexOf( this.getAuthorId(author) )
                this.lockedAuthors.splice(index, 1)
            }
        },
        validation: function(v) {
            let sum = 0;
            this.localAuthors.forEach( f => {
                sum += f.contribution;
            });
            
            console.log(sum);
            return sum == 100;
        },
        open: function() {
            console.log("dialog-open");
            //this.$refs.searchInput.focus()
            this.localAuthors = [];
            this.authors.forEach( q => {
                let tmpA = { uic: q.uic, id: q.id, contribution: q.contribution * 100, displayName: q.displayName }
                this.localAuthors.push(tmpA);
            } ) 
            this.dialogOpen = true
        }, 
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("input", this.authors)
        },
        saveContribution: function() {
            this.localAuthors.forEach( f => {
                let author = this.authors.find( a => this.getAuthorId(f) == this.getAuthorId(a) )
                author.contribution = f.contribution/100;
            } )
            this.close()
        }
    },
    components: {  }
}
</script>