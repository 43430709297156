<template>
	<v-card class="ma-4">
		<v-toolbar flat color="primary" dark>
			<v-toolbar-title>Správa uživatelů</v-toolbar-title>
		</v-toolbar>
		<v-card-text>

		</v-card-text>
		<v-card-text>
			<v-row no-gutters>
				<v-col cols="3">
					<author-picker v-model="pickerData"></author-picker>
				</v-col>
				<v-divider vertical></v-divider>
				<v-col>
					<v-data-table :loading="usersLoading" :headers="headers" :items="authorList" :items-per-page="25">
						<template v-slot:item.actions="{item}">
							<v-btn icon @click="editAuthor(item)"><v-icon>mdi-pen</v-icon></v-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card-text>
		<AuthorEditModal ref="authorModal" @close-dialog="authorUpdated"></AuthorEditModal>
	</v-card>
</template>

<script> 
import DepartmentService from '../services/department-service';
import { listsStore } from '../stores/lists-store'
import AuthorPicker from './parts/AuthorPicker.vue';
import AuthorEditModal from '@/components/dialogs/AuthorEditModal.vue'

export default {
	components: { AuthorPicker, AuthorEditModal },
	name: 'UserManager',
	props: {
		value: Object,
	},
	data: () => ({
		usersLoading: false,

		listsStore: listsStore(),
		pickerData: {},
		authorList: [],
		departmentService: new DepartmentService(),
		authorInEdit: {},

		headers: [
			{text: "#", value: 'uic'},
			{text: "Příjmení", value: 'surname'},
			{text: "Jméno", value: 'name'},
			{text: "Tituly", value: 'title'},
			{text: "Orcid", value: 'orcId'},
			{text: "Scopus", value: 'scopusId'},
			{text: "WOS", value: 'researcherId'},

			{text: "Akce", value: 'actions', sortable: false},
		],
	}),
	mounted: async function() {
		//await this.listsStore.fetch();

		
	},
	watch: {
		pickerData: function(n,o) {
			console.log("picker picked")
			this.loadUsers( n.baseUnit.id );
		}
	},
	computed: {
		/*
		treeItems: function() {
			
		}
		*/
	},
	methods: {
		loadUsers: async function(id) {
			if(this.pickerData.state >= 2) {
				this.authorList = [];
				this.usersLoading = true;
				this.departmentService.fetchAuthors(id, {expanded: 1})
                .then( r => {
					this.authorList = r.data.data;
					this.usersLoading = false;
                } )
			}
		},
		editAuthor: function(author) {
			this.authorInEdit = author;
			this.$refs.authorModal.open({uic: author.uic});
		},
		authorUpdated: function(data) {
			if(data) {
				console.log(data)
				this.authorInEdit.scopusId = data.scopusId
				this.authorInEdit.researcherId = data.researcherId
				this.authorInEdit.orcId = data.orcId
			}
		}
	},
}

</script>;