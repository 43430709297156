<template>
    <v-dialog
        :value="dialogOpen"
        persistent
        max-width="500px"
        >

        <v-card-title>
          <span class="text-h5">{{panelTitle}}</span>
        </v-card-title>
        <v-card-text>
            <v-form
                lazy-validation
            >
                <v-text-field v-model="name" label="Name" required></v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="close">{{ $t('btn.close') }}</v-btn>
        </v-card-actions>
    </v-dialog>
</template>

<script>

export default {
    name: 'GrantPublisherModalForm',
    props: {
        panelTitle: String,
        grantPublisherId: Number,
        dialogOpen: Boolean
    },
    data: () => ({
        name: null
    }),
    mounted: function() {
    },
    methods: {
        close: function() {
            this.$emit("close-dialog")
        }
    }

}
</script>
