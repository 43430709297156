<template>
    <div>
        <v-snackbar :style="{'margin-bottom':calcMargin(i)}" 
            v-for="(alert, i) in alertStore.items.filter(q => q.show == true)" :key="i" :color="alert.type" 
            :timeout="alert.timeout" v-model="alert.show">
            {{alert.message}}
        </v-snackbar>
    </div>
</template>

<script>
import { useNotificationStore } from '../stores/notification-store';

export default {
    name: 'AlertManager',
    props: {

    },
    data: () => ({
        panelTitle: "",
        alertStore: useNotificationStore(),
        show: true
    }),
    mounted: function() {
        
    },
    methods: {
        calcMargin(i) {
            return (i*60) + 'px'
        },
        processAlert: alert => {
            console.log(alert)
        }
    }

}
</script>
