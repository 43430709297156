<template>
	<div>
		<v-row>
			<v-col cols="6">
				<v-text-field v-model="publicationSubtype.eventType" readonly label="Druh výstavy"></v-text-field>
			</v-col>
			<v-col cols="6">
				<v-text-field v-model="publicationSubtype.country" readonly label="Stát konání výstavy"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.venue" readonly label="Místo konání výstavy"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.dateStart" readonly label="Datum zahájení"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.dateEnd" readonly label="Datum ukončení"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.noOfExhibitors" readonly label="Počet vystavovatelů"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.noOfForeignExhibitors" readonly label="Počet zahraničních vystavovatelů"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.exhibitonType" readonly label="Typ akce dle státní příslušnosti účastníků"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'ExhibitionDetail',
	props: {
		publication: Object,
	},
    components: {
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		publicationSubtype: {},
		levelOfSecrecy: {},
		researchReport: {},
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.publicationSubtype = this.publication.exhibition

		}
	},
}

</script>;