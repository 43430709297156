import { axiosInst } from "../main";

export default class WormEntryService {
    
    async fetch( params )  {
        const response = await axiosInst.get('/worm/entries', {
            params: params
        })
        return response
    }

    async fetchForAuthor( id, params )  {
        let path = (id) => `/worm/entries/authors/${id}`
        const response = await axiosInst.get( path(id), {
            params: params
        })
        return response
    }

    async fetchForDepartment( id, params )  {
        let path = (id) => `/worm/entries/departments/${id}`
        const response = await axiosInst.get( path(id), {
            params: params
        })
        return response
    }

    async get( id, params )  {
        let path = (id) => `/worm/entries/${id}`
        const response = await axiosInst.get( path(id), {
            params: params
        })
        return response
    }

    async create( params, data )  {
        let path = `/publications`
        try {
            const response = await axiosInst.post( path, data, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async put( id, params, data )  {
        let path = (id) => `/publications/${id}`
        try {
            const response = await axiosInst.put( path(id), data, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }
}