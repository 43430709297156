<template>
	<div>
		<v-row>
			<v-col cols="6">
				<v-text-field v-model="publicationSubtype.proceedingsName" readonly label="Název sborníku"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.formOfPublication" readonly label="Forma vydání"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.eventType" readonly label="Typ akce"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="2">
				<v-text-field v-model="publicationSubtype.noOfCopies" readonly label="Počet výtisků"></v-text-field>
			</v-col>
			<v-col cols="2"> 
				<v-text-field v-model="publicationSubtype.pageFrom" readonly label="Strana od"></v-text-field>
			</v-col>
			<v-col cols="2"> 
				<v-text-field v-model="publicationSubtype.pageTo" readonly label="Strana do"></v-text-field>
			</v-col>
			<v-col cols="2">
				<v-text-field v-model="publicationSubtype.noOfPages" readonly label="Počet stran"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.wos" readonly label="WOS"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.eid" readonly label="EID"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.eissn" readonly label="eISSN"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.startDate" readonly label="Datum zahájení"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3"> 
				<v-text-field v-model="publicationSubtype.venue" readonly label="Místo konání"></v-text-field>
			</v-col>
			<v-col cols="3"> 
				<v-text-field v-model="publicationSubtype.placeOfIssue" readonly label="Místo vydání"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.publisher" readonly label="Název nakladatele"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';

export default {
	name: 'ProceedingsDetail',
	props: {
		publication: Object,
	},
    components: {
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		publicationSubtype: {},

		levelOfSecrecy: {},
		researchReport: {},
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.publicationSubtype = this.publication.proceeding


			//this.levelOfSecrecyInfo = this.list.getListValue("levelOfSecrecy", this.subtype.levelOfSecrecyId)
			//this.researchReport = this.list.getListValue("researchReport", this.subtype.researchReportId)
		}
	},
}

</script>;