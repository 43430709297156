<template>
    <v-btn normal v-on:click="reload()" :loading="loading" :disabled="!enabled"><v-icon>mdi-reload</v-icon></v-btn>
</template>

<script>

export default {
    name: 'ReloadButtonComponent',
    props: {
        text: String,
        icon: String,
    },
    data: () => ({
        enabled: true,
        loading: false
    }),
    
    mounted: function() {
        this.$root.$on("applicationLoading", this.applicationLoading )
    },

    methods: {
        reload: function() {
            console.log("reload hit")
            this.loading = true;
            this.enabled = false;
            this.$emit("reload")
        },
        applicationLoading: function(state) {
            if(state) {
                this.loading = true;
                this.enabled = false;
            } else {
                this.loading = false;
                this.enabled = true;
            }
        },
    }

}
</script>
