<template>
	<v-chip
		small label
        :icon="icon"
        :color="color"
		>
        {{ label }}
	</v-chip>
</template>

<script>
import { useDepartmentStore } from '../../stores/department-store';

export default {
    name: "PublicationState",
    props: {
        stateId: Number,
        label: String,
    },
    data: function () {
        return {
            color: "",
            icon: "",
        };
    },
	mounted: function() {
        //this.label = label;
        this.$nextTick( () => {

            switch(this.stateId*1) {
            case 1:
                this.color = "default"
                this.icon = ""
                break;
            case 2:
                this.color = "orange"
                break;
            case 3:
                this.color = "green"
                break;
            case 4:
                this.color = "blue"
                break;
            case 5:
                this.color = "red"
                break;
            }
        });
	},
}
</script>