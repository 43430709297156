import { defineStore } from "pinia";

export const useApplicationStore = defineStore("applicationStore", {
    state: () => ({
        initialized: false,
        loading: false,
        overlayLoading: {
            active: false,
            hasMessage: true,
            message: "Overlay loading",
            progressValue: 30,
        },
    }),
    getters: {
        isInitialized(state) {
            return state.initialized
        },
        isOverlayActive(state) {
            console.log(state.overlayLoading.active)
            return state.overlayLoading.active
        }
    },
    actions: {
        initialize() {

        },
        overlayLoading(message, progress) {
            console.log("overlay loading started")
            this.overlayLoading.active = true;
            this.overlayLoading.hasMessage = false

            if(!message && message!="" ) {
                this.overlayLoading.hasMessage = true
                this.overlayLoading.message = message;
            }

            if(!progress) {
                this.overlayLoadingprogressValue = progress;
            }
        },

        hideOverlayLoading() {
            this.overlayLoading.active = false;
        }
    }
})