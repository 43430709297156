import { axiosInst } from "../main";

export default class OpenAccessService {
    
    async fetchAll( params )  {
        const response = await axiosInst.get('/lists/open-access', {
            params: params
        })
        return response
    }
}