<template>
    <v-container fluid>
        <div class="h3" v-if="title != ''">{{ title }}</div>
        <v-list three-line>
            <v-list-item  
            v-for="grant in grants" :key="grant.id"
            >
                <v-list-item-avatar>
                    <v-icon>mdi-atom</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ grant.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <span class="text--primary"><v-icon small>mdi-identifier</v-icon> {{ grant.internalNumber }}</span> - <v-icon small>mdi-clock</v-icon> {{ grant.yearFrom }} - {{ grant.yearTo }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
            <v-alert v-if="grants == null || grants.length == 0" type="info" outlined dense>
            Publikace nemá evidovány žádné granty
        </v-alert>
    </v-container>
</template>

<script>

export default {
    name: "PublicationGrantList",
    props: {
        grants: Array,
        title: {
            type: String,
            default: "Přiřazené granty",
            required: false,
        }
    },
    data: () => ({
        kwords: []
    }),
	mounted: function() {
	},
    methods: {
    },
}
</script>