
export default class BaseService {
    handleCallback( callback ) {
        if(callback) {
            setTimeout(() => {
                callback()
            }, 1000);
        }
    }

}