<template>
	<v-card class="ma-4">
		<v-toolbar flat color="primary" dark>
			<v-toolbar-title>Správa publikací</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-row>
				<v-col cols="4">
					<v-select  class="mx-2" v-model="query.facultyId"
							:items="listsStore.faculties"
							item-text="name"
							item-value="id"
							label="Fakulta"
							:loading="departmentLoading"
							required
							@change="facultyPicked()"
							></v-select>
				</v-col>
				<v-col cols="3">
					<v-select  class="mx-2" v-model="query.baseUnitId"
						:items="workingDepartments"
						clearable
						:loading="departmentLoading"
						item-text="name"
						item-value="id"
						label="Útvar"
						></v-select>
				</v-col>
				<v-col cols="3">
					<v-select class="mx-2" v-model="query.publicationStateId"
							:items="listsStore.publicationStateList"
							label="Stav publikace"
							required
							></v-select>
				</v-col>
				<v-col cols="2">
					<v-select  class="mx-2" v-model="query.year"
							:items="listsStore.yearList"
							label="Rok publikace"
							required
							></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="3">
					<v-select  class="mx-2" v-model="query.includeTags"
						:items="tags"
						clearable
						label="Zahnout štítky/tagy"
						></v-select>
				</v-col>
				<v-col cols="3">
					<v-select  class="mx-2" v-model="query.excludeTags"
						:items="tags"
						clearable
						label="Vyloučit štítky/tagy"
						></v-select>
				</v-col>
				<v-col cols="3">
					<v-select  class="mx-2" v-model="query.groupBy"
						:items="outputGrouping"
						clearable
						label="Seskupení"
						></v-select>
				</v-col>
				<v-col cols="3">
					<v-select  class="mx-2" v-model="query.publicationTypeId"
						:items="listsStore.publicationTypeList"
						item-text="name"
						item-value="id"
						clearable
						label="Typ záznamu"
						></v-select>
				</v-col>
			</v-row>
			<v-btn @click="search()" class="mx-2">Zobrazit</v-btn>
		</v-card-text>
		<v-card-text>
			<v-row>
				<v-col cols="2"  v-if="false">
					<v-card flat>
						<v-toolbar dense flat>
							<v-toolbar-title>Útvary</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-btn icon>
								<v-icon>mdi-check</v-icon>
							</v-btn>
							<v-menu bottom left>
								<template v-slot:activator="{on, attrs}">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon>mdi-dots-vertical</v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item link>
										Vybrat vše
									</v-list-item>
									<v-list-item link>
										<v-list-item-title>Pouze se změnami</v-list-item-title>
									</v-list-item>
									<v-list-item link>
										<v-list-item-title>Zrušit výběr</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-toolbar>
						<v-card-text>
							<v-list class="overflow-y-auto" style="max-height: 400px;">
								<v-list-item-group v-model="selectedDepartments" multiple>
									<v-list-item v-for="item in workingDepartments" :key="item.id">
										{{ item.abbr }}
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="2" v-if="false">
					<v-card flat>
						<v-toolbar dense flat>
							<v-toolbar-title>Autoři</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-btn icon>
								<v-icon>mdi-check</v-icon>
							</v-btn>
							<v-menu bottom left>
								<template v-slot:activator="{on, attrs}">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon>mdi-dots-vertical</v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item link>
										Vybrat vše
									</v-list-item>
									<v-list-item link>
										<v-list-item-title>Pouze se změnami</v-list-item-title>
									</v-list-item>
									<v-list-item link>
										<v-list-item-title>Zrušit výběr</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-toolbar>
						<v-card-text>
							<v-list class="overflow-y-auto" style="max-height: 400px;">
								<v-list-item-group v-model="selectedDepartments" multiple>
									<v-list-item v-for="item in workingDepartments" :key="item.id">
										{{ item.abbr }}
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col>
					<v-data-table class="mt-3" :headers="headers" :items="approvalStore.items" 
						:items-per-page="15" show-select v-model="selectedPublications"
						:expanded.sync="expanded" show-expand>
						<template v-slot:top>
							<v-btn class="mr-2" :disabled="!(selectedPublications.length > 0)" @click="approveSelectedAs(2)">Hromadně schválit vybrané jako vedoucí katedry</v-btn>
							<v-btn class="mr-2" :disabled="!(selectedPublications.length > 0)" @click="approveSelectedAs(3)">Hromadně schválit vybrané jako proděkan</v-btn>
							<v-menu >
								<template v-slot:activator="{on, attrs}">
									<v-btn v-bind="attrs" v-on="on" disabled>
										Exporty a sestavy
									</v-btn>
								</template>
								<v-list>
									<v-list-item link>
										Vybrat vše
									</v-list-item>
									<v-list-item link>
										<v-list-item-title>Pouze se změnami</v-list-item-title>
									</v-list-item>
									<v-list-item link>
										<v-list-item-title>Zrušit výběr</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>
						<template v-slot:expanded-item="{headers, item}">
							<td :colspan="headers.length">
								<PublicationView :publication="item" @changeState="detailChangeState" ></PublicationView>
							</td>
						</template>
					</v-data-table>
				</v-col>
			</v-row>


		</v-card-text>
	</v-card>
</template>

<script> 
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { authStore } from '../stores/user'
import DepartmentPicker from './parts/DepartmentPicker.vue'
import { listsStore } from '../stores/lists-store'
import { useApprovalStore } from '../stores/approval-store'
import { useNotificationStore } from '../stores/notification-store'
import { useDepartmentStore } from '../stores/department-store'
import PublicationView from './PublicationView.vue'
import PublicationDetailShort from './parts/PublicationDetailShort.vue'

export default {
	components: { ReloadButtonComponent, DepartmentPicker, PublicationView, PublicationDetailShort },
	name: 'ApprovalManager',
	props: {
		
	},
	data: () => ({
		uic: 0,
		headers: [
			{text: "#", value: 'id'},
			{text: "Název", value: 'name'},
			{text: "Typ", value: 'publicationType'},
			{text: "Stav", value: 'publicationState'},
			{text: "Rok", value: 'yearOfPublication'},
		],
		expanded: [],
		selectedPublications: [],
		publications: [],
		approvalStore: useApprovalStore(),
		query:  useApprovalStore().searchQuery,
		listsStore: listsStore(),
		userStore: authStore(),
		notificationStore: useNotificationStore(),
		departmentStore: useDepartmentStore(),
		baseUnit: {},
		faculty: 0,

		workingDepartments: [],
		selectedDepartments: [],
		departmentLoading: false,

		tags: [
			{value: "vavapproved", text: "Zkontrolováno"},
			{value: "vavrejected", text: "Chybný záznam"},
			{value: "imported", text: "Importovaný záznam (WOS/Scopus)"},
		],

		outputGrouping: [
			{value: 0, text: "Nesekupovat"},
			{value: 1, text: "Seskupit po útvarech"},
			{value: 2, text: "Seskupit po lidech"}
		]
	}),
	mounted: async function() {
		await this.listsStore.fetch();

		if( this.query.facultyId > 0 ) {
			this.facultyPicked();
		}
		console.log("mounted manager")
	},
	methods: {
		load: function() {
			//this.publicationStore.fetch( {uic: this.userStore.uic} )
		},
		search: async function() {
			this.approvalStore.clear();
			
			/*
			if(this.faculty > 0) {
				this.workingDepartments = await this.departmentStore.fetchForFaculty(this.faculty)
			}
			*/
			//console.log(this.query)

			if(!this.query.baseUnitId) {
				let units = this.workingDepartments.map( f => f.id )
				this.query.baseUnitIds = units;
			}

			this.approvalStore.query( this.approvalStore.searchQuery, {} );
		},
		personPicked: function(data) {
			if(data) {
				this.pickedPerson = data;
				this.query.pickedPerson = data;
				this.query.uic = data.uic;
			}
		},
		facultyPicked: async function() {
			this.workingDepartments = [];

			if(this.query.facultyId > 0) {
				this.departmentLoading = true
				this.workingDepartments = await this.departmentStore.fetchForFaculty(this.query.facultyId)			
				this.departmentLoading = false
			}
		},
		unitPicked: function(data) {
			if(data) {
				this.baseUnit = data;
				this.query.baseUnitId = data.id;

				this.approvalStore.pickedBaseUnit = this.baseUnit
			}
		},
		approveSelectedAs: async function(type) {
			console.log("approving", this.selectedPublications.length)

			if(this.selectedPublications.length > 0) {
				console.log("approving")
				let totalToApprove = this.selectedPublications.length;
				let approved = 0;
				for (const [i,p] of this.selectedPublications.entries()) {
					await this.approvalStore.changeState( p.id, type, "", {}, () => {
						this.notificationStore.addSuccess({message: "Publikace schválena"})
						approved++;
					})
				}
				/*
				this.notificationStore.addSuccess({message: `Schváleno ${approved} publikací z celkem ${totalToApprove}`})
				*/
				this.selectedPublications = [];
			}
		},
		detailChangeState: function(ev) {
			console.log(ev)
			let [data, type] = ev
			console.log(ev, data, type)
			if(data) {
				this.approvalStore.changeState(data.id, type, "", {}, () => {
					this.notificationStore.addSuccess({message: "Publikace schválena"})
					
					let index = this.expanded.filter( t => t.id = data.id )
					this.expanded.splice(index, 1)
					
					//let pubIndex = this.approvalStore.items.filter( t => t.id = data.id )
					//this.approvalStore.items.splice(pubIndex, 1)
					this.search()
				} )
			}
		}
	},
}

</script>;