<template>
    <v-dialog
        :value="dialogOpen"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
        <v-card tile>
            <v-toolbar dark flat color="primary">
                <v-toolbar-title>{{panelTitle}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form @submit.prevent="search()">
                <v-row class="mt-2">
                    <v-col cols="8">
                        <v-text-field v-model="name" label="Název útvaru/Kód/Zkratka" 
                        @click:clear="clear()"
                        ref="searchInput"
                        autofocus
			            clearable 
                        :disabled="isLoading"
                        :loading="isLoading"
                        required></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn icon @click="search()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </v-col>
                    <v-col>
                        <v-switch v-model="autoApprove" label="Automaticky potvrdit jedinný záznam"></v-switch>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
            <v-expand-transition>
                <v-card-text v-if="departmentStore.departments.length > 0">
                    <v-simple-table height="500">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>{{ $t('departments.name') }}</th>
                                    <th>{{ $t('departments.code') }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in departmentStore.departments" @click="pick(item)" style="cursor: pointer;">
                                    <td>{{item.name}}</td>
                                    <td>...</td>
                                    <td>
                                        <v-btn @click="pick(item)" small icon><v-icon>mdi-arrow-right</v-icon></v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-expand-transition>
            <v-card-actions>
                <v-btn color="error" @click="close">{{ $t('btn.close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { useDepartmentStore } from '../../stores/department-store'


export default {
    name: 'DepartmentSearchModal',
    props: {
        panelTitle: {
            type: String,
            default: "Vyhledat útvar",
        },
        preselectedUIC: Number,
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        departmentStore: useDepartmentStore(),
        isLoading: false,
        autoApprove: false,
    }),
    mounted: function() {
    },
    methods: {
        search: async function() {
            let search = this.name

            if(search.length >= 3) {
                this.isLoading = true
                await this.departmentStore.fetch({q: search}, () => {this.isLoading=false})
                if(this.autoApprove && this.departmentStore.departments.length == 1) {
                    let item = this.departmentStore.departments[0]
                    this.pick(item)
                }
            }
        },
        open: function(props) {
            this.dialogOpen = true

            if(!props) {return;}

            if(props.search != "") {
                this.name = props.search
            }

            if(props.autoSearch) {
                this.$nextTick( () => {this.search()})
            }
        }, 
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
        pick: function(item) {
            this.close(item)
        },
        clear: function() {
            this.name = "";
            this.departmentStore.clearList()
        }
    }

}
</script>
