<template>
	<span>
		<v-text-field
		v-model="valueText"
		prepend-icon="mdi-database-search"
		append-outer-icon="mdi-magnify"
		label="Časopis"
		:error-messages="errorMessages"
		readonly
		clearable
		required
		:rules="rules.select"
		@click="fieldClicked"
		>
			<template v-slot:append-outer>
				<v-btn :disabled="readonly" depressed @click="modalOpen">{{ $t('btn.find-journal') }}<v-icon right>mdi-magnify</v-icon></v-btn>
			</template>
		</v-text-field>
		<JournalSearchModal ref="search" @close-dialog="modalClosed"></JournalSearchModal>
	</span>
</template>

<script>
import { useJournalStore } from '../../stores/journal-store';
import JournalSearchModal from '../dialogs/JournalSearchModal.vue';

export default {
    name: "JournalPicker",
    props: {
        errorMessages: Array,
		valueText: String,
        value: Number,
		readonly: Boolean,
    },
    data: function () {
        return {
            timeoutId: null,
            search: "",
            isLoading: false,
            hasValue: false,
			foundItem: {},
			journalStore: useJournalStore(),
			rules: {
				select: [
					v => !!v || 'Zadejte hledanou hodnotu',
				]
			}
        };
    },
	watch: {
		value: function(n, o) {
			if(n  > 0) {
				this.getJournal(n);
			}
		}
	},
    methods: {
        clear: function () {
            this.hasValue = false;
        },
		getJournal: function(id) {
			let journalInfo = this.journalStore.get(id)
			journalInfo.then(r => {
				this.foundItem = r.data
				this.updateValue()
				console.log("found journal", r)
			})
		},
		modalOpen: function() {
			this.$refs.search.open()
		},
		modalClosed: function(response) {
			this.foundItem = response;
			this.updateValue()
		},
        updateValue: function () {
            this.$emit("input", this.foundItem);
        },
		fieldClicked: function() {
			console.log(this.valueText)
			if(!this.valueText || this.valueText=="" ) {
				this.modalOpen()
			}
		}
    },
    components: { JournalSearchModal }
}
</script>