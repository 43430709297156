<template>
    <v-dialog
        :value="dialogOpen"
        scrollable
        persistent
        max-width="800px"
        >
        <v-card tile>
            <v-toolbar flat dark color="primary">
                <v-toolbar-title>{{ $t('dialogs.author-edit') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form>
                <v-row class="mt-2">
                    <v-col cols="8">
                        <v-text-field disabled label="Jméno a příjmení" v-model="userdata.displayName"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-2">
                    <v-col cols="8">
                        <v-text-field label="ORCID" v-model="userdata.orcId"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-2">
                    <v-col cols="8">
                        <v-text-field label="Researcher ID" v-model="userdata.researcherId"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-2">
                    <v-col cols="8">
                        <v-text-field label="Scopus" v-model="userdata.scopusId"></v-text-field>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="close">{{ $t('btn.close') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="saveAuthor">{{ $t('btn.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import PersonService from '../../services/person-service';
import { listsStore } from '../../stores/lists-store';
import { useNotificationStore } from '../../stores/notification-store';
import { authStore } from '../../stores/user';

export default {
    name: 'AuthorEditModal',
    props: {
        panelTitle: {
            type: String,
            default: "Změnit typ publikace",
        },
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        isLoading: false,
        listStore: listsStore(),
        userStore: authStore(),
        notificationService: useNotificationStore(),
        personService: new PersonService(),
        userdata: {},
        currentUser: -1,
    }),
    mounted: function() {
        this.dialogOpen = false
    },
    methods: {
        open: function(props) {
            console.log("dialog-open");
            //this.$refs.searchInput.focus()
            this.dialogOpen = true
            
            if(!props) {return;}

            this.currentUser = props.uic
            this.loadAuthor()
        }, 
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
        loadAuthor: function() {
            if(this.currentUser > 0) {
                let service = new PersonService()
                service.get(this.currentUser, {})
                .then( r => {
                    this.userdata = r.data;
                } )
            }
        },
        saveAuthor: function() {
            
            this.personService.update(this.currentUser, {}, this.userdata)
            .then(r => {
                this.notificationService.addSuccess({message: this.$t('messages.author-saved')});
                if(this.userStore.uic == this.currentUser) {
                    this.userStore.verifyRefresh();
                    this.close();
                } else {
                    this.close(this.userdata);
                }
            })
        },
        clear: function() {
        }
    }

}
</script>
