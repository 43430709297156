<template>
	<div>
		<v-row>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.patentNo" readonly label="Číslo patentu"></v-text-field>
			</v-col>
			<v-col cols="8">
				<v-text-field v-model="publicationSubtype.patentPublisher" readonly label="Vydavatel patentu"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-text-field v-model="publicationSubtype.ownerName" readonly label="Název vlastníka"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.dateOfAcceptance" readonly label="Datum udělení patentu"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.realized" readonly label="Patent je realizován"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.licenseObligation" readonly label="Povinnost získání licence"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.licenceFee" readonly label="Licenční poplatek"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.typeOfUse" readonly label="Způsob užití patentu"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.useByOthers" readonly label="Druh možnosti využití patentu jiným subjektem"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';

export default {
	name: 'PatentDetail',
	props: {
		publication: Object,
	},
    components: {
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		publicationSubtype: {},
		levelOfSecrecy: {},
		researchReport: {},
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.publicationSubtype = this.publication.patent

		}
	},
}

</script>;