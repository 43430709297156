<template>
		<v-autocomplete 
			v-model="value"
			label="Člověk"
			hide-no-data
			hide-selected
			required
			prompt="Zadejte příjmení, orcid, uič ..."
			:items="items"
			:loading="isLoading"
			:search-input.sync="search"
			v-on:input="updateValue()"
			item-value="uic"
			item-text="displayName"
			:readonly="hasValue"
			:rules="rules.select"
			:error-messages="errorMessages"
			@click:clear="clear()"
			clearable
			prepend-icon="mdi-database-search"
			></v-autocomplete>    
</template>

<script>
import {axiosInst} from '@/main'

export default {
    name: 'PersonSearch',
	props: {
		errorMessages: Array,
		value: Number,
	},
	data: function() {
		return {
			timeoutId: null,
			items: [],
			search: "",
			isLoading: false,
			hasValue: false,
			rules: {
				select: [
					v => !!v || 'Zadejte hledanou hodnotu',
                    //v => v > 0  || console.log(v) || 'oke',
					v => v > 0 || 'Nebyl vybrán člověk.'
				]
			}
		};
	},
	mounted: function() {
		if(this.value > 0) {
			this.hasValue = true
			this.getItem(this.value)
		}
	},
	watch: {
		search: function(val) {
			if (this.hasValue) return;
			if (val == null || val == "") return;
			if (val.length <= 3) return;
			//if (this.isLoading) return;
			
			this.isLoading = true;
			
			if(this.timeoutId != null) clearTimeout(this.timeoutId);			
			
			this.timeoutId = setTimeout( () => {this.searchJournal(val)}, 1000)
		},
		value: function(val) {
			if(val > 0) {
				this.hasValue = true
				this.getItem(val)
			}
		}
	},
	computed: {
	},
	methods: {
		clear: function() {
			this.hasValue = false
		},
		searchJournal: function(q) {
			axiosInst.get('/authors/?q='+q+'')
				.then( response => {
					this.items = response.data.data 
				})
				.finally( () => {
					this.isLoading = false;		
				})
		},
		getItem: function(q) {
			axiosInst.get('/authors/'+q+'')
				.then( response => {
					this.items = [response.data]
				})
				.finally( () => {
					this.isLoading = false;		
				})
		},
		updateValue: function() {
			this.$emit('input', this.value);
		}
	},
}
</script>