import { axiosInst } from "../main";

export default class JournalImpactService {
    
    async fetchForJournal( id, params )  {
        let path = (id) => `/journals/${id}/impacts`
        const response = await axiosInst.get(path(id), {
            params: params
        })
        return response
    }

    async get( journalId, impactId, params )  {
        let path = (id) => `/journals/${journalId}/impacts/${impactId}`
        const response = await axiosInst.get( path(id), {
            params: params
        })
        return response
    }
}