<template>
	<v-row>
		<v-col col="4">
			<v-card class="pa-4 ma-4">
				<v-card-title>
					OpenAccess
				</v-card-title>
				<v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th>Název</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in openAccessStore.items">
									<td>{{item.text}}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>		
		<v-col col="4">
			<v-card class="pa-4 ma-4">
				<v-card-title>
					Způsoby zveřejnění
				</v-card-title>
				<v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th>Název</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in publishFormsStore.items">
									<td>{{item.text}}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>		
		<v-col col="4">
			<v-card class="pa-4 ma-4">
				<v-card-title>
					Podtypy článků
				</v-card-title>
				<v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th>Název</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in articleSubtypeStore.items">
									<td>{{item.text}}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>		
	</v-row>
</template>

<script> 
import { axiosInst } from '../main'
import {storeToRefs} from 'pinia'
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import {openAccessStore} from '../stores/open-access-store'
import {publishFormsStore} from '../stores/publish-forms-store'
import {articleSubtypeStore} from '../stores/article-subtypes-store'

export default {
	components: {ReloadButtonComponent},
	name: 'SystemListSettings',
	props: {
		panelTitle: String,
	},
	data: () => ({
		search: '',
		openAccessStore: openAccessStore(),
		publishFormsStore: publishFormsStore(),
		articleSubtypeStore: articleSubtypeStore()
	}),
	mounted: function() {
		this.panelTitle = "Ford"
		this.load()
	},
	methods: {
		load: async function() {
			this.openAccessStore.fetch({})
			this.publishFormsStore.fetch({})
			this.articleSubtypeStore.fetch({})
		}
	}
}

</script>