<template>
	<div>
		<v-row>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.editionName" readonly label="Název edice"></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="publicationSubtype.publisher" readonly label="Název nakladatele"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.formOfPublication" readonly label="Forma vydání"></v-text-field>
			</v-col>
			<v-col cols="2">
				<v-text-field v-model="publicationSubtype.placeOfIssue" readonly label="Místo vydání"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="2">
				<v-text-field v-model="publicationSubtype.pageFrom" readonly label="Strana od"></v-text-field>
			</v-col>
			<v-col cols="2">
				<v-text-field v-model="publicationSubtype.pageTo" readonly label="Strana do"></v-text-field>
			</v-col>
			<v-col cols="2">
				<v-text-field v-model="publicationSubtype.noOfPagesInBook" readonly label="Počet stran"></v-text-field>
			</v-col>
			<v-col cols="2">
				<v-text-field v-model="publicationSubtype.noOfCopies" readonly label="Počet výtisků"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.isbn" readonly label="ISBN"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.wos" readonly label="Kód WOS"></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-text-field v-model="publicationSubtype.eid" readonly label="EID Vysledku"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store';

export default {
	name: 'BookChapterDetail',
	props: {
		publication: Object,
	},
    components: {
	},
	data: () => ({
		journal: {},
		hasJournal: false,
		list: listsStore(),
		editDisabled: false,
		publicationSubtype: {},
		levelOfSecrecy: {},
		researchReport: {},
	}),
	mounted: function() {
		if(this.publication != null ) {
			this.setup();
		}
	},
	watch: {
		publication: function(n, o) {
			this.setup();
		}
	},
	methods: {
		setup: function() {
			this.publicationSubtype = this.publication.bookChapter

		}
	},
}

</script>;