import { defineStore } from "pinia";

export const useNotificationStore = defineStore("notificationStore", {
    state: () => ({
        loading: Boolean,
        items: []
    }),
    actions: {
        addNotification(notification) {
            console.log("adding notification")
            this.items.push(notification)
        },
        addError(props) {
            let tpl = {type: "error", timeout: 3000, show: true};
            let message = {
                ...tpl, 
                ...props
            }
            this.items.push(message)
        },
        addSuccess(props) {
            let tpl = {type: "success", timeout: 3000, show: true};
            let message = {
                ...tpl, 
                ...props
            }
            this.items.push(message)
        }
    }
})