<template>
	<div>
		<v-row>
			<v-col cols="6">
				<v-select v-model="publication.artCategoryId"
					:items="list.artCategory"
					:label="$t('publications.general.category')"
					required
					></v-select>
			</v-col>
			<v-col cols="6">
				<v-select v-model="publication.artObligationId"
					:items="list.artObligation"
					:label="$t('publications.art.obligation')"
					required
					></v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-select v-model="publication.artContextId"
					:items="list.artContext"
					:label="$t('publications.art.context')"
					required
					></v-select>
			</v-col>
			<v-col cols="6">
				<v-select v-model="publication.artScopeId"
					:items="list.artScope"
					:label="$t('publications.art.scope')"
					required
					></v-select>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import { listsStore } from '../../stores/lists-store'

export default {
	name: 'ArtForm',
	props: {
		defaults: {type: Object, default: () => {return {q: '1'}}},
		errors: Array,
	},
	data: () => ({
		formTypes: [],
		publication: {},
		list: listsStore(),
		menu: false
	}),
	mounted: async function() {
		await this.loadData();
		//this.publication = this.defaults
	},
	methods: {
		loadData: async function() {
			await this.list.fetch({});
		},
		setData: function(data) {
			if(data) {
				this.publication = data
			}
		},
		getData: function() {
			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;