<template>
    <v-overlay :value="loading">
        <v-row class="fill-height"
            align-content="center"
            justify="center">
            <v-col class="text-subtitle-1 text-center"
                    cols="12" v-if="applicationStore.overlayLoading.hasMessage">
                {{ applicationStore.overlayLoading.message }}
            </v-col>
            <v-col>
                <v-progress-circular rounded indeterminate
                
                ></v-progress-circular>
            </v-col>
        </v-row>
    </v-overlay>
</template>

<script>
import { useApplicationStore } from '@/stores/application'

export default {
    data: () => ({
        applicationStore: useApplicationStore(),
        loading: false
    }),
    mounted: function() {
        //this.loading = this.applicationStore.overlayLoading.active;
    }
}
</script>