import { axiosInst } from "../main";

export default class FordService {
    
    async fetchAll( params )  {
        const response = await axiosInst.get('/lists/fords', {
            params: params
        })
        return response
    }
}