import { defineStore } from "pinia";
import GrantPublisherService from "../services/grant-publisher-service";

export const grantPublisherStore = defineStore("grantPublisherStore", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
		},
    }),
    getters: {
        getItems() {
            return this.items
        },
        getForSelect() {
            if(this.items.length == 0) {
                this.fetch({})
            }
            return this.items.map( item => {
                return {value: item.id, text: item.name}
            } )
        }
    },
    actions: {
        async fetch(params) {
            let service = new GrantPublisherService();
            const results = await service.fetchAll(params);
            this.items = results.data.data;
        }
    }
})