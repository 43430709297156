<template>
    <v-dialog
        :value="dialogOpen"
        scrollable
        persistent
        max-width="800px"
        >
        <v-card tile>
            <v-toolbar flat dark color="primary">
                <v-toolbar-title>{{ $t('view.publication-filter') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-select  class="mx-2" v-model="query.facultyId"
							:items="listsStore.faculties"
							item-text="name"
							item-value="id"
							label="Fakulta"
							:loading="departmentLoading"
							required
							@change="facultyPicked()"
							></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select  class="mx-2" v-model="query.baseUnitId"
						:items="workingDepartments"
						clearable
						:loading="departmentLoading"
						item-text="name"
						item-value="id"
                        disabled
						label="Útvar"
						></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-select class="mx-2" v-model="query.publicationStateId"
							:items="listsStore.publicationStateList"
							label="Stav publikace"
                            clearable
							required
							></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select  class="mx-2" v-model="query.years"
							:items="listsStore.yearList"
							label="Rok publikace"
                            multiple
							required
							></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-select  class="mx-2" v-model="query.includeTags"
						:items="tags"
						clearable
                        multiple
						label="Zahnout štítky/tagy"
						></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select  class="mx-2" v-model="query.excludeTags"
						:items="tags"
						clearable
						label="Vyloučit štítky/tagy"
						></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-select  class="mx-2" v-model="query.groupBy"
						:items="outputGrouping"
						clearable
						label="Seskupení"
						></v-select>                        
                    </v-col>
                    <v-col cols="6">
                        <v-select  class="mx-2" v-model="query.publicationTypeIds"
						:items="listsStore.publicationTypeList"
						item-text="name"
						item-value="id"
						clearable
						label="Typ záznamu"
                        multiple
						></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-dialog
                            ref="dialog"
                            v-model="dateModal"
                            :return-value.sync="dateModalData"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateModalDataLabel"
                                label="Schváleno od - do"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="dateModalData"
                            scrollable
                            range
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="dateModal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(dateModalData)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>         
                    </v-col>
                    <v-col cols="6">
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="close">{{ $t('btn.close') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="applyFilters">{{ $t('btn.set') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment';
import { useApprovalStore } from '../../stores/approval-store';
import { useDepartmentStore } from '../../stores/department-store';
import { listsStore } from '../../stores/lists-store';
import { useNotificationStore } from '../../stores/notification-store';
import { authStore } from '../../stores/user';

export default {
    name: 'PublicationFiltersModal',
    props: {
        panelTitle: {
            type: String,
            default: "Změnit typ publikace",
        },
    },
    data: () => ({
        dateModal: false,
        dateModalData: [],
        name: null,
        dialogOpen: false,
        isLoading: false,
        listsStore: listsStore(),
        userStore: authStore(),
        departmentStore: useDepartmentStore(),
        notificationService: useNotificationStore(),
        yearSlider: [],

        workingDepartments: [],
		selectedDepartments: [],
		departmentLoading: false,

        query:  useApprovalStore().searchQuery,

        tags: [
			{value: "vavapproved", text: "Zkontrolováno"},
			{value: "vavrejected", text: "Chybný záznam"},
			{value: "imported", text: "Importovaný záznam (WOS/Scopus)"},
			{value: "aop", text: "APC"},
		],
        outputGrouping: [
			{value: 0, text: "Nesekupovat"},
			{value: 1, text: "Seskupit po útvarech"},
			{value: 2, text: "Seskupit po lidech"}
		]
    }),
    mounted: async function() {
        this.dialogOpen = false
        await this.listsStore.fetch();

        if( this.query.facultyId > 0 ) {
			this.facultyPicked();
		}
    },
    computed: {
        dateModalDataLabel: function() {

            let localDates = [];

            this.dateModalData.forEach( q => {
                localDates.push( moment(q).format("D. M. YYYY") );
            } )

            return localDates.join(' ~ ')
        }
    },
    methods: {
        open: function(props) {
            console.log("dialog-open");
            //this.$refs.searchInput.focus()
            this.dialogOpen = true

            if(!props) {return;}
        }, 
        facultyPicked: async function() {
			this.workingDepartments = [];

			if(this.query.facultyId > 0) {
				this.departmentLoading = true
				this.workingDepartments = await this.departmentStore.fetchForFaculty(this.query.facultyId)			
				this.departmentLoading = false
			}
		},
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
        applyFilters: function() {
            this.close()
        },
        saveAuthor: function() {
            this.notificationService.addSuccess({message: "Nastavení filtru uloženo."});
            this.close();
        },
        clear: function() {
        }
    }

}
</script>
