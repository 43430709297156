<template>
    <v-dialog
        :value="dialogOpen"
        :persistent="persistent"
        max-width="800px"
        keydown.esc="decline"
        scrollable
        >
        <v-card tile>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select label="Poskytovatel" v-model="frmProvider" :items="listProviders" item-text="abbr" item-value="id"></v-select>
                    </v-col>
                    <v-col>
                        <v-select label="Fakulta" v-model="frmFaculty" :items="listFaculties" item-text="abbr" item-value="id"></v-select>
                    </v-col>
                    <v-col>
                        <v-select label="Mod exportu" v-model="frmMode" :items="exportMode"></v-select>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" @click="loadXml">Zobrazit</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" @click="exportXML">{{ $t('btn.export') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text style="height: 400px;">
                <pre class="text-sm-body-2">{{xmlContent}}</pre>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="close">{{ $t('btn.close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import PublicationService from '../../services/publication-service'
import { listsStore } from '../../stores/lists-store';

export default {
    name: 'RivXMLViewModal',
    props: {
    },
    data: () => ({
        dialogOpen: false,
        title: "RIV XML Viewer",
        persistent: true,
        xmlContent: "",
        publicationService: new PublicationService(),
        publicationId: 0,
        listsStore: listsStore(),
        exportMode: [
            {value: 1, text: "Vložení"},
            {value: 2, text: "Aktualizace"},
            {value: 3, text: "Smazání "},
        ],
        frmMode: 1,
        frmProvider: 0,
        frmFaculty: 0,

        listFaculties: [],
        listProviders: [],
    }),
    mounted: function() {
        this.dialogOpen = false
    },
    methods: {
        open: function(id) {
            //this.$refs.searchInput.focus()
            this.publicationId = id;
            this.dialogOpen = true;
            //this.loadXml()
            this.loadMetadata();
        },
        loadMetadata: function() {
            this.publicationService.fetchMetadata(this.publicationId)
            .then( (r) => {
                console.log("metadata", r.data)

                this.listFaculties = [];

                if(r.data.data.length > 0) {
                    r.data.data.forEach( i => {

                        if(i.metadataCode == "faculty") {
                            let faculty = this.listsStore.faculties.find( f => f.id == i.value );
                            this.listFaculties.push(faculty);
                        }

                        if(i.metadataCode == "rivProvider") {
                            let provider = this.listsStore.grantProvider.find( f => f.id == i.value );
                            this.listProviders.push(provider);
                        }

                    } )
                }
                
                if( this.listProviders.length > 0 ) {
                    this.frmProvider = this.listProviders[0].id;
                }

                if( this.listProviders.length > 0 ) {
                    this.frmFaculty = this.listFaculties[0].id;
                }

            } )
        },
        loadXml: function() {
            console.log("loading XML", this.publicationId)

            let query = {grantProviderId: this.frmProvider, facultyId: this.frmFaculty, outputMode: this.frmMode }

            this.publicationService.fetchXMLForPublication(this.publicationId, query)
            .then((r) => {
                console.log("xml", r.data)
                r.data.text().then( r => {
                    this.xmlContent = r
                })
            })
        },
        close: function() {
            this.dialogOpen = false
        },
        exportXML: function() {
            let query = {grantProviderId: this.frmProvider, facultyId: this.frmFaculty, outputMode: this.frmMode }
            this.publicationService.downloadXMLForPublication(this.publicationId, query)
        },
        clear: function() {
            this.dialogOpen = false;
        }
    }
}
</script>
