var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"levelOfSecrecyId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.levelOfSecrecy,"label":_vm.$t('publications.general.level-of-secrecy'),"error-messages":errors,"required":""},model:{value:(_vm.publication.levelOfSecrecyId),callback:function ($$v) {_vm.$set(_vm.publication, "levelOfSecrecyId", $$v)},expression:"publication.levelOfSecrecyId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"researchReportTypeId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.list.researchReport,"label":_vm.$t('publications.research.type'),"error-messages":errors,"required":""},model:{value:(_vm.publication.researchReportTypeId),callback:function ($$v) {_vm.$set(_vm.publication, "researchReportTypeId", $$v)},expression:"publication.researchReportTypeId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('publications.research.isbn-or-ismn'),"hint":""},model:{value:(_vm.publication.isbn),callback:function ($$v) {_vm.$set(_vm.publication, "isbn", $$v)},expression:"publication.isbn"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"vid":"noOfPages","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.no-of-pages'),"hint":"","error-messages":errors},model:{value:(_vm.publication.noOfPages),callback:function ($$v) {_vm.$set(_vm.publication, "noOfPages", $$v)},expression:"publication.noOfPages"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"placeOfIssue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.general.place-of-issue'),"hint":"","error-messages":errors},model:{value:(_vm.publication.placeOfIssue),callback:function ($$v) {_vm.$set(_vm.publication, "placeOfIssue", $$v)},expression:"publication.placeOfIssue"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('publications.research.version'),"hint":""},model:{value:(_vm.publication.editionName),callback:function ($$v) {_vm.$set(_vm.publication, "editionName", $$v)},expression:"publication.editionName"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"vid":"publisherName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('publications.research.customer'),"hint":"","error-messages":errors},model:{value:(_vm.publication.publisherName),callback:function ($$v) {_vm.$set(_vm.publication, "publisherName", $$v)},expression:"publication.publisherName"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }