<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="3">
				<author-picker v-model="pickerData"></author-picker>
			</v-col>
			<v-divider vertical></v-divider>
			<v-col>
				<v-data-table :headers="headers" 
					:items="publicationStore.publicationDrafts" :items-per-page="15"
					show-expand single-expand
					@item-expanded="itemExpanded"> 
					<template v-slot:item.state="{item}">
						<v-chip small>Draft</v-chip>
					</template>
					<template v-slot:item.type="{item}">
						<v-chip small>{{ item.type }}</v-chip>
					</template>
					<template v-slot:expanded-item="{headers, item}">
						<td :colspan="headers.length">
							<publication-view :approval-enabled="viewApprovalEnabled" :publication="publicationDraftView" ></publication-view>
						</td>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script> 
import DepartmentService from '../services/department-service';
import { listsStore } from '../stores/lists-store'
import AuthorPicker from './parts/AuthorPicker.vue';
import PublicationView from './PublicationView.vue'
import { publicationStore } from '../stores/publication-store';
import { authStore } from '../stores/user';

export default {
	components: { AuthorPicker, PublicationView },
	name: 'DraftManager',
	props: {
		value: Object,
	},
	data: () => ({
		usersLoading: false,
		expanded: [],
		viewApprovalEnabled: false,

		listsStore: listsStore(),
		userStore: authStore(),
		publicationStore: publicationStore(),

		pickerData: {},
		authorList: [],
		departmentService: new DepartmentService(),
		authorInEdit: {},

		publicationDraftView: {},

		headers: [
			{text: "#", value: 'id'},
			{text: "Název", value: 'title'},
			{text: "Stav", value: 'state'},
			{text: "Typ", value: 'type'},
		],
	}),
	mounted: async function() {
		await this.listsStore.fetch();
	},
	watch: {
		pickerData: function(n,o) {
			console.log("picker picked")
			this.loadUsers();
		}
	},
	computed: {
		/*
		treeItems: function() {
			
		}
		*/
	},
	methods: {
		loadUsers: function() {
			if(this.pickerData.state == 2 && this.pickerData?.baseUnit?.id ) {
				this.publicationStore.fetchDepartmentDrafts( this.pickerData.baseUnit.id )
			}
		},
		itemExpanded: async function( item ) {
			let pub = await this.publicationStore.restoreDraft(item.item.token, {})
				.then(r => {
					item.item = r.data;
					this.publicationDraftView = { ...{}, ...r.data}
			})
		}
	},
}

</script>;