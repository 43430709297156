<template>
    <v-dialog
        :value="dialogOpen"
        scrollable
        persistent
        max-width="800px"
        >
        <v-card tile>
            <v-toolbar flat dark color="primary">
                <v-toolbar-title>{{ $t('dialogs.publication-change-type') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form>
                    <v-alert class="mt-4" dense outlined type="warning">{{ $t('messages.publication-change-type-warning') }}</v-alert>
                    <v-row class="mt-2">
                        <v-col cols="8">
                            <v-select v-model="publication.publicationTypeId"
                                :items="listStore.publicationTypeList"
                                item-text="name"
                                item-value="id"
                                :label="$t('labels.current-publication-type')"
                                required
                                readonly
                                ></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col cols="8">
                            <v-select v-model="newPublicationType"
                                :items="listStore.publicationTypeList"
                                item-text="name"
                                item-value="id"
                                :label="$t('labels.new-publication-type')"
                                required
                                ></v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="close">{{ $t('btn.close') }}</v-btn>
                <v-btn color="success" @click="saveNewType">{{ $t('btn.change-publication-type') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { listsStore } from '../../stores/lists-store';
import { useNotificationStore } from '../../stores/notification-store';
import PublicationService from '../../services/publication-service';

export default {
    name: 'PublicationTypeChangeModal',
    props: {
        panelTitle: {
            type: String,
            default: "Změnit typ publikace",
        },
        publication: {}
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        isLoading: false,
        listStore: listsStore(),
        newPublicationType: 0,
        notificationService: useNotificationStore(),
    }),
    mounted: function() {
        this.dialogOpen = false
    },
    methods: {
        open: function(props) {
            console.log("dialog-open");
            //this.$refs.searchInput.focus()
            this.dialogOpen = true
            this.newPublicationType = 0;

            if(!props) {return;}
        }, 
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
        saveNewType: async function() {

            if( this.newPublicationType == 0) {
                this.notificationService.addError({message: this.$t('messages.errors.pick-publication-type')})
                return;
            }

            if( this.publication.publicationTypeId == this.newPublicationType ) {
                this.notificationService.addError({message: this.$t('messages.errors.same-publication-type-picked')})
                return;
            }

            this.isLoading = true;
            
            if(this.publication.id > 0) {
                let request = {id: this.publication.id, targetType: this.newPublicationType};

                let pubService = new PublicationService()
                let result = await pubService.changeType(this.publication.id, {}, request)

                console.log(result)
                if(result.status == 204) {
                    this.publication.publicationTypeId = this.newPublicationType
                    this.isLoading = false;
                    this.notificationService.addSuccess({message: this.$t('messages.publication-type-changed')})
                    this.close(true)
                } else {
                    this.notificationService.addError({message: this.$t('messages.errors.error-during-publication-change')})
                }
            } else {
                this.notificationService.addSuccess({message: this.$t('messages.publication-type-changed')});
                this.publication.publicationTypeId = this.newPublicationType
                this.isLoading = false;
                this.close(true)
            }

        },
        clear: function() {
        }
    }

}
</script>
