<template>
    <v-dialog
        :value="dialogOpen"
        persistent
        scrollable
        max-width="800px"
        >
        <v-card tile>
            <v-toolbar flat color="primary">
                <v-toolbar-title>{{panelTitle}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form @submit.prevent="search()">
                <v-row class="mt-2">
                    <v-col cols="8">
                        <v-text-field v-model="name" label="Jméno hledané osoby" 
                        @click:clear="clear()"
                        ref="searchInput"
                        autofocus
			            clearable 
                        :disabled="isLoading"
                        :loading="isLoading"
                        required></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn icon @click="search()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
            <v-expand-transition>
                <v-card-text v-if="personStore.persons.length > 0">
                    <v-simple-table height="500">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{ $t('authors.name-and-surname') }}</th>
                                    <th>{{ $t('authors.department') }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="person in personStore.persons" @click="pick(person)" style="cursor: pointer;">
                                    <td>{{person.uic}}</td>
                                    <td>{{person.displayName}}</td>
                                    <td>
                                        <author-departments-chips :author="person"></author-departments-chips>
                                    </td> 
                                    <td>
                                        <v-btn @click="pick(person)" small icon><v-icon>mdi-arrow-right</v-icon></v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-expand-transition>
            <v-card-actions>
                <v-btn color="error" @click="close">{{ $t('btn.close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { usePersonStore } from '../../stores/person-store';
import AuthorDepartmentsChips from '../parts/AuthorDepartmentsChips.vue';


export default {
    components: { AuthorDepartmentsChips },
    name: 'PersonSearchModal',
    props: {
        panelTitle: {
            type: String,
            default: "Vyhledat osobu",
        },
        preselectedUIC: Number,
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        personStore: usePersonStore(),
        isLoading: false
    }),
    mounted: function() {
    },
    methods: {
        search: function() {
            let search = this.name

            if(search.length >= 2) {
                this.isLoading = true
                this.personStore.fetch({q: search}, () => {this.isLoading=false})
            }
        },
        open: function(props) {
            console.log("dialog-open");
            //this.$refs.searchInput.focus()
            this.dialogOpen = true

            if(!props) {return;}

            if(props.search != "") {
                this.name = props.search
            }

            if(props.autoSearch) {
                this.$nextTick( () => {this.search()})
            }
        }, 
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
        pick: function(person) {
            console.log("picker person", person)
            this.close(person)
        },
        clear: function() {
            this.name = "";
            this.personStore.clearList()
        }
    }

}
</script>
