<template>
	<div>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="formOfPublicationId" v-slot="{errors}" rules="required">
				<v-select v-model="publication.formOfPublicationId"
					:items="formTypes.data"
					:label="$t('publications.general.form-of-publication')"
					:error-messages="errors"
					required
					></v-select>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="ISBN" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.isbn"
					:label="$t('publications.general.isbn')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="bookName" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.bookName"
					:label="$t('publications.book.name')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="editionName" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.editionName"
					:label="$t('publications.general.edition-name')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3">
				<validation-provider vid="pageFrom" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.pageFrom"
					:label="$t('publications.general.page-from')"
					:error-messages="errors"
					hint="Pokud jde o článek v nestránkovaném periodiku, uveďte údaj 0."
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="3">
				<validation-provider vid="pageTo" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.pageTo"
					:label="$t('publications.general.page-to')"
					:error-messages="errors"
					hint="Pokud jde o článek v nestránkovaném periodiku, uveďte údaj 0"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="3">
				<validation-provider vid="noOfPagesInBook" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.noOfPagesInBook"
					:label="$t('publications.general.no-of-pages')"
					:error-messages="errors"
					:hint="$t('help.hints.no-of-pages')"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="3">
				<validation-provider vid="noOfCopies" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.noOfCopies"
					:label="$t('publications.genera.no-of-copies')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<validation-provider vid="placeOfIssue" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.placeOfIssue"
					label="$t('publications.general.place-of-issue')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
			<v-col cols="6">
				<validation-provider vid="publisher" v-slot="{errors}" rules="required">
				<v-text-field v-model="publication.publisher"
					:label="$t('publications.general.publisher')"
					:error-messages="errors"
					></v-text-field>
				</validation-provider>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-text-field v-model="publication.eid"
					:label="$t('publications.general.eid')"
					></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script> 
import {axiosInst} from '../../main'

export default {
	name: 'BookChapterForm',
	props: {
		defaults: {type: Object, default: () => {return {q: '1'}}},
		errors: Array,
	},
	data: () => ({
		formTypes: [],
		publication: {},
	}),
	mounted: function() {
		this.loadData()
		this.publication = this.defaults
	},
	methods: {
		loadData: function() {
			axiosInst.get('/lists/publish-forms')
			.then( response => {
				this.formTypes = response.data;
			})
		},
		setData: function(data) {
			if(data) {
				this.publication = data
			}
		},
		getData: function() {
			return this.publication;
		},
		isValid: function() {
			return false;
		},
	},
}

</script>;