<template>
	<v-card>
		<v-toolbar class="elevation-0" color="primary" dark>
			<v-btn icon :to="{name: 'settings-journals'}"><v-icon>mdi-arrow-left</v-icon></v-btn>
			<v-toolbar-title>Detail časopisu</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn class="mx-2" disabled><v-icon>mdi-lock</v-icon> Upravit</v-btn>
			<v-btn class="mx-2" disabled>Smazat</v-btn>
			<reload-button-component class="mx-2" v-on:reload="load()" />
		</v-toolbar>
		<v-card-text>
			<v-card-subtitle>Informace o časopisu</v-card-subtitle>
			<v-row>
				<v-col cols="8">
					<v-text-field readonly label="Název časopisu"  v-model="journalStore.journal.title"></v-text-field>
					<v-text-field readonly label="Vydavatel"  v-model="journalStore.journal.publisherName"></v-text-field>
				</v-col>
				<v-col>
					<v-text-field readonly label="ISSN" class="text-right" v-model="journalStore.journal.issn" append-icon="mdi-content-copy" @click:append="copy"></v-text-field>
					<v-text-field readonly label="ISBN"  v-model="journalStore.journal.isbn" append-icon="mdi-content-copy" @click:append="copy"></v-text-field>
					<v-text-field readonly label="Oficiální"  v-model="journalStore.journal.oficial"></v-text-field>
				</v-col>
			</v-row>
			<v-card-subtitle v-if="journalStore.journal.wosSource">WOS Data</v-card-subtitle>
			<v-row v-if="journalStore.journal.wosSource">
				<v-col cols="6">
					<v-text-field readonly label="WOS Name"  v-model="journalStore.journalWorm.name"></v-text-field>
				</v-col>
				<v-col cols="3">
					<v-text-field readonly label="WOS Code"  v-model="journalStore.journalWorm.code"></v-text-field>
				</v-col>
				<v-col cols="3">
					<v-text-field readonly label="WOS Language"  v-model="journalStore.journalWorm.language"></v-text-field>
				</v-col>
			</v-row>
			<v-card-subtitle v-if="journalStore.journal.wosSource">WOS Kategorie</v-card-subtitle>
			<v-row v-if="journalStore.journal.wosSource">
				<v-col>
					<v-chip v-for="cat in journalStore.journalWorm.categories" :key="cat.id" small>
						{{ cat.category }} ({{ cat.edition }})
					</v-chip>
				</v-col>
			</v-row>
			<v-divider class="mt-2"></v-divider>
			<v-toolbar dense flat class="mt-2">
				<v-toolbar-title>
					Hodnocení časopisu
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn disabled><v-icon>mdi-plus-thick</v-icon>Vložit hodnocení</v-btn>
			</v-toolbar>
			<v-simple-table class="mt-2" height="300" fixed-header v-if="journalStore.journalImpacts.length > 0">
				<template v-slot:default>
					<thead>
						<tr>
							<th>Rok</th>
							<th>Impact factor</th>
							<th>Impact median</th>
							<th>Normalizované pořadí</th>
							<th>Vědecký</th>
							<th>Pozitivní</th>
							<th>Prestižní</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in journalStore.journalImpacts">
							<td class="text-center">{{ item.year }}</td>
							<td class="text-right">{{ item.impactFactor }}</td>
							<td class="text-right">{{ item.impactMedian }}</td>
							<td class="text-right">{{ item.normalizedOrder }}</td>
							<td>
								<v-icon v-if="item.scientific">mdi-check</v-icon>
								<v-icon v-if="!item.scientific">mdi-close</v-icon>
							</td>
							<td>
								<v-icon v-if="item.positiveJournal">mdi-check</v-icon>
								<v-icon v-if="!item.positiveJournal">mdi-close</v-icon>
							</td>
							<td>
								<v-icon v-if="item.prestigious">mdi-check</v-icon>
								<v-icon v-if="!item.prestigious">mdi-close</v-icon>
							</td>
							<td>
								<v-btn icon disabled><v-icon>mdi-pencil</v-icon></v-btn>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>

			<h3>WOS Metrics</h3>
			<v-data-table :headers="metricsHeaders" :items="metrics" :items-per-page="15">
			</v-data-table>

			<h3>WOS Category Metrics</h3>
			<v-select
				v-model="selectedCategory"
				:items="journalStore.journalWorm.categories"
				item-text="category"
				item-value="id"
				label="WOS Category"
				@change="categoryPicked()"
			></v-select>
			<v-data-table :headers="categoryHeaders" :items="categoryRanks" :items-per-page="15">
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script> 
import { axiosInst } from '../main'
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { useJournalStore } from '../stores/journal-store'

export default {
	components: { ReloadButtonComponent },
	name: 'JournalDetail',
	props: {
		panelTitle: {
			type: String,
			default: "Detail časopisu"
		},
		journalId: {
			type: [String, Number], //@fixme - fix type to single type
			required: true
		},
	},
	data: () => ({
		search: '',
		journalStore: useJournalStore(),
		metrics: [],
		categoryRanks: [],
		metricsHeaders: [
			{ text: "Year", value: 'year'},
			{ text: 'ImpactTotalCites', value: 'impactTotalCites'},
			{ text: 'ImpactJIF', value: 'impactJIF'},
			{ text: 'ImpactJIFWithnoutSelfCitations', value: 'impactJIFWithnoutSelfCitations'},
			{ text: 'ImpactJIF5Years', value: 'impactJIF5Years'},
			{ text: 'ImpactImmediacyIndex', value: 'impactImmediacyIndex'},
			{ text: 'ImpactJCI', value: 'impactJCI'},
			{ text: 'InfluenceEigenScore', value: 'influenceEigenScore'},
			{ text: 'InfluenceEigenNormalized', value: 'influenceEigenNormalized'},
			{ text: 'InfluenceArticle', value: 'influenceArticle'},
			{ text: 'SourceCitableItemsTotal', value: 'sourceCitableItemsTotal'},
			{ text: 'SourceCitableJIFPercentile', value: 'sourceCitableJIFPercentile'},
			{ text: 'SourceHalfLifeCited', value: 'sourceHalfLifeCited'},
			{ text: 'SourceHalfLifeCiting', value: 'sourceHalfLifeCiting'},
			{ text: 'CitationArticleMedian', value: 'citationArticleMedian'},
			{ text: 'CitationReviewMedian', value: 'citationReviewMedian'},
			{ text: 'CitationUnlinked', value: 'citationUnlinked'},
			{ text: 'CitationTimesCited', value: 'citationTimesCited'},
			{ text: 'CitationArticles', value: 'citationArticles'},
			{ text: 'CitationReviews', value: 'citationReviews'},
			{ text: 'CitationOther', value: 'citationOther'},
		],
		selectedCategory: 0,
		categoryHeaders: [
			{ text: "Year", value: 'year'},
			{ text: 'JifRank', value: 'jifRank'},
			{ text: 'JifQuartile', value: 'jifQuartile'},
			{ text: 'JifPercentile', value: 'jifPercentile'},
			{ text: 'ArticleInfluenceRank', value: 'articleInfluenceRank'},
			{ text: 'ArticleInfluenceQuartile', value: 'articleInfluenceQuartile'},
			{ text: 'EigenRank', value: 'eigenRank'},
			{ text: 'EigenQuartile', value: 'eigenQuartile'},
			{ text: 'ImmediacyRank', value: 'immediacyRank'},
			{ text: 'ImmediacyQuartile', value: 'immediacyQuartile'},
			{ text: 'JciRank', value: 'jciRank'},
			{ text: 'JciQuartile', value: 'jciQuartile'},
			{ text: 'JciPercentile', value: 'jciPercentile'},
		]
	}),
	mounted: function() {
		this.load()
	},
	methods: {
		load: async function() {
			this.journalStore.get(this.journalId, {})
			let rs = await this.journalStore.getWorm(this.journalId, {})
			this.journalStore.fetchImpacts(this.journalId, {})

			this.metrics = this.journalStore.journalWorm.metrics;
			console.log(metrics);
		},
		copy: function(elm) {
			console.log(elm, elm.modelValue)
		},
		categoryPicked: function() {
			let category = this.selectedCategory
			this.categoryRanks = this.journalStore.journalWorm.categoryRanks.filter( f => f.categoryId == category )
			console.log(this.categoryRanks)
		}
	}
}

</script>